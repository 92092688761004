import React from "react";
import { useParams } from "react-router-dom";
import DefaultLayout from "app/layouts/DefaultLayout";
import Contractor from "app/components/Contractor";

function ContractorCreatePage(props) {
  const { contractorType, stepId } = useParams();

  var pageTitle = "";
  if (contractorType == "agency") pageTitle = "代理店情報入力";
  if (contractorType == "outsourcing") pageTitle = "委託先情報入力";
  if (contractorType != "agency" && contractorType != "outsourcing") return;

  return (
    <DefaultLayout
      headerProps={{
        headerClassName: "md:!block",
        pageTitle: pageTitle,
      }}
      showNotice={false}
    >
      <Contractor.Form stepId={+stepId} contractorType={contractorType} />
    </DefaultLayout>
  );
}

export default ContractorCreatePage;
