import React from "react";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DefaultLayout from "app/layouts/DefaultLayout";
import Path from "app/route/Path";
import Api from "app/services/api";
import Button from "app/components/common/Button";
import SearchBox from "app/components/common/SearchBox";
import Contract from "app/components/Contract";
import { useParams } from "react-router-dom";
import AnalyseStatusSelection from "../../components/AnalyseStatusSelection";
import {  CONTRACT_STATUS_VAL,CONTRACT_STATUS_LABEL } from "../../components/Contract/ContractTableItem";
import { synchronizeClientParams } from "app/helper";
import { useIsMounted, useQueryConfig } from "../../hooks";
import { useSearchParams } from "react-router-dom";
import { Pagination, Stack } from "@mui/material";
import { DEFAULT_TOTAL_PER_PAGE } from "../../constants";
import BackBtn from "../../components/BackButton";

const SORT_KEYS = {
  ASC: "1",
  DES: "-1",
};

function ContractListPage() {
  const {} = useParams();
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  // **
  const registerButtonClicked = () => {
    navigate(Path.contract.create(0));
  };
  const articleregisterButtonClicked = () => {
    navigate(Path.itemImport);
  };

  const [pagin, setPagin] = useState({ total: 0, page: searchParams.get("page") || 1 });
  const [searchText, setSearchText] = useState(searchParams.get("searchText") || "");
  const [searchContracts, setSearchContracts] = useState([]);
  const [searchContractsLoaded, setSearchContractsLoaded] = useState(false);
  const [params, setParams] = useState({
    sortDate: searchParams.get("sortDate") || null,
    contractStatus: searchParams.get("contractStatus") || null,
  });

  async function getAllContracts(params) {
    const response = await Api.contracts.list({ ...params, searchText, page: pagin?.page, perPage: DEFAULT_TOTAL_PER_PAGE }).then((res) => {
      return res;
    });
    const items = response?.data;
    setPagin((prev) => ({ ...prev, total: response?.total }));
    setSearchContracts(items);
    setSearchContractsLoaded(true);
  }

  useEffect(() => {
    getAllContracts({ ...params, searchText });
  }, [params, pagin?.page]);

  // Wrong contract type from url , return anyway

  var pageTitle = "契約書状況一覧";

  const syncUrl = (overrideObj) => {
    synchronizeClientParams(pathname, {
      searchText,
      sortDate: params?.sortDate,
      contractStatus: params?.contractStatus,
      page: pagin?.page,
      ...overrideObj,
    });
  };

  const searchClicked = async () => {
    setSearchContractsLoaded(false);
    getAllContracts({ salonName: searchText });
    syncUrl();
  };

  const handleSetParams = (obj) => {
    setParams((prev) => {
      syncUrl(obj);
      return { ...prev, ...obj };
    });
  };

  const onDetailClicked = (contractItem) => {
    navigate(Path.contract.detail(contractItem.id));
  };

  const handleChangePage = (e, page) => {
    setPagin((prev) => {
      syncUrl({ page });
      return { ...prev, page };
    });
  };

  return (
    <DefaultLayout
      headerProps={{
        headerClassName: "md:!block",
        pageTitle: pageTitle,
      }}
      showNotice={false}>
      <SearchBox placeholder="美容室を探す" value={searchText} setValue={setSearchText} onSearch={searchClicked} />
      <Contract.Table.Table items={searchContracts} loading={!searchContractsLoaded}>
        <Contract.Table.Loading>
          <tbody>
            <tr>
              <td colSpan="100">ローディング中</td>
            </tr>
          </tbody>
        </Contract.Table.Loading>
        <Contract.Table.Empty>契約書状況がないです。</Contract.Table.Empty>
        <Contract.Table.Header>
          <tr>
            <th scope="col" className="px-6 py-4">
              契約先店舗名
            </th>
            <th scope="col" className="px-6 py-4">
              作成日
            </th>
            <th scope="col" className="px-6 py-4">
              送信日
              <div className="mt-1 ml-1">
                <button
                  className="border border-green-800 rounded-md"
                  onClick={() => {
                    handleSetParams({ sortDate: SORT_KEYS.ASC });
                  }}>
                  <ExpandLessIcon sx={{ margin: "auto" }} />
                </button>
                <button
                  className="border border-green-800 rounded-md"
                  onClick={() => {
                    handleSetParams({ sortDate: SORT_KEYS.DES });
                  }}>
                  <ExpandMoreIcon sx={{ margin: "auto" }} />
                </button>
              </div>
            </th>
            <th scope="col" className="px-6 py-4">
              返信有効期限
            </th>
            <th scope="col" className="px-6 py-4">
              締結日
            </th>
            <th scope="col" className="px-6 py-4">
              ステータス
              <AnalyseStatusSelection
                title={"状態"}
                arrayData={[null, ...Object.values(CONTRACT_STATUS_VAL)]?.map((value) => {
                  return { value, title: CONTRACT_STATUS_LABEL[value] };
                })}
                onchange={(value) => {
                  handleSetParams({ contractStatus: value });
                }}
              />
            </th>
          </tr>
        </Contract.Table.Header>
        <Contract.Table.Items>
          {searchContracts.map((item) => (
            <Contract.Table.Item key={item.id} onDetailClick={onDetailClicked}>
              {item}
            </Contract.Table.Item>
          ))}
        </Contract.Table.Items>
      </Contract.Table.Table>
      <Stack spacing={2}>
        <Pagination sx={{ display: "flex", justifyContent: "center", paddingTop: "2%", paddingBottom: "2%" }} count={Math.ceil((pagin?.total || 0) / DEFAULT_TOTAL_PER_PAGE)} page={pagin?.page} onChange={handleChangePage} />
      </Stack>
      <BackBtn url={Path.contract.home} />
    </DefaultLayout>
  );
}

export default ContractListPage;
