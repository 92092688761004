import React, {useEffect} from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import dayjs from "dayjs";
import Path from "app/route/Path";
import StepsBar from "app/components/common/StepsBar";
import Input from "app/components/common/Input";
import Button from "app/components/common/Button";
import Label from "app/components/common/Label";
import Panel from "app/components/common/Panel";
import Form from "app/components/common/Form";
import Api from "app/services/api";
import BackBtn from "../../BackButton";

function DMCustomForm(props) {
  const { isEdit, stepId, customerOrSalon, children } = props;
  const DM = children;

  const category = customerOrSalon ? "salon" : "customer";

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    async function fetchOptions() {
      const response = await (customerOrSalon ? Api.salons.all() : Api.customers.all());
      const items = response.data;
      setOptions(items);
    }
    fetchOptions();
  }, [customerOrSalon]);

  const defaultTarget = { name: "", id: "" };

  const steps = [
    { name: "ダイレクトメールを作成する" },
    { name: "入力確認" },
    { name: "入力完了" },
  ];

  const navigate = useNavigate();

  const {
    handleSubmit,
    // handleChange,
    // errors,
    values,
    setFieldValue,
    // resetForm,
    // touched,
    // handleBlur,
  } = useFormik({
    initialValues: {
      id: isEdit ? DM.id : "",
      category: isEdit ? DM.category : category,
      targetName: isEdit ? DM.targetName : "",
      deliveryDate: isEdit ? dayjs(DM.deliveryDate, "YYYY-MM-DD HH:mm") : dayjs(),
      title: isEdit ? DM.title : "",
      content: isEdit ? DM.content : "",
      selectedOption: isEdit ? DM : defaultTarget,
    },
    onSubmit: async (values) => {
      setIsSubmitting(true);
      const response = await Api.DMs.create(values, customerOrSalon);
      setIsSubmitting(false);
      gotoNextStep();
      // alert(JSON.stringify(values, null, 2));
      // setTimeout(() => {
      //   gotoNextStep();
      //   setIsSubmitting(false);
      // }, 1000);
    },
  });

  const gotoPrevStep = () => {
    if (values.category == "customer")
      navigate(Path.dm.customer.create(stepId - 1));
    if (values.category == "salon")
      navigate(Path.dm.salon.create(stepId - 1, "salon"));
  };

  const gotoNextStep = () => {
    if (values.category == "customer")
      navigate(Path.dm.customer.create(stepId + 1));
    if (values.category == "salon")
      navigate(Path.dm.salon.create(stepId + 1, "salon"));
  };

  const step1IsValid = () => {
    if (!values.targetName) {
      return false;
    }
    if (!values.deliveryDate) {
      return false;
    }
    if (!values.title) {
      return false;
    }
    if (!values.content) {
      return false;
    }

    return true;
  };

  const DMCreateFinished = () => {
    navigate(Path.dm.home);
  };

  const renderByStepIndex = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <>
            <Form.Row>
              <Form.Col>配信対象</Form.Col>
              <Form.ColGrow>
                <Input.AutocompleteInput
                  name="targetName"
                  className="w-full"
                  value={values.selectedOption}
                  onChange={(_, newValue) => {
                    setFieldValue("selectedOption", newValue);
                    setFieldValue("targetName", newValue?.name || "");
                  }}
                  options={[defaultTarget, ...(options || [])]}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                ></Input.AutocompleteInput>
              </Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>配信日時</Form.Col>
              <Form.ColGrow>
                <Input.DateTimePicker
                  disablePast
                  name="deliveryDate"
                  className="w-full"
                  value={values.deliveryDate}
                  setValue={(newValue) => {
                    setFieldValue("deliveryDate", newValue);
                  }}
                ></Input.DateTimePicker>
              </Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>タイトル</Form.Col>
              <Form.ColGrow>
                <Input.Field
                  name="title"
                  type="text"
                  className="w-full"
                  value={values.title}
                  setValue={(newValue) => {
                    setFieldValue("title", newValue);
                  }}
                ></Input.Field>
              </Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>本文</Form.Col>
              <Form.ColGrow>
                <Input.MultilineField
                  name="content"
                  type="text"
                  className="w-full"
                  value={values.content}
                  setValue={(newValue) => {
                    setFieldValue("content", newValue);
                  }}
                ></Input.MultilineField>
              </Form.ColGrow>
            </Form.Row>

            <Panel.Justify className="!sticky !bottom-0 w-full bg-white p-2">
              <BackBtn url={Path.dm.home}/>
              <Button.Primary onClick={gotoNextStep} disabled={!step1IsValid()}>
                次へ
              </Button.Primary>
            </Panel.Justify>
          </>
        );
      case 1:
        return (
          <div className="mx-2 space-y-4">
            <Form.Row>
              <Form.Col>部類</Form.Col>
              <Form.ColGrow>{values.category}</Form.ColGrow>
            </Form.Row>
            <Form.Row></Form.Row>
            <Form.Row>
              <Form.Col>配信対象</Form.Col>
              <Form.ColGrow>{values.targetName}</Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>配信日時</Form.Col>
              <Form.ColGrow>
                <Label.DateTime dateFormat="YYYY-MM-DD HH:mm">
                  {values.deliveryDate}
                </Label.DateTime>
              </Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>タイトル</Form.Col>
              <Form.ColGrow>{values.title}</Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>本文</Form.Col>
              <Form.ColGrow>{values.content}</Form.ColGrow>
            </Form.Row>
            <Panel.Justify className="!sticky !bottom-0 w-full bg-white p-2">
              <Button onClick={gotoPrevStep}>戻る</Button>
              <Button.Primary type="submit" loading={isSubmitting}>
                {isEdit
                  ? "おすすめ商品を編集する"
                  : "ダイレクトメールを作成する"}
              </Button.Primary>
            </Panel.Justify>
          </div>
        );

      case 2:
        return (
          <div className="text-center space-y-4 mx-2">
            <Form.Row>
              <Form.ColGrow>
                {isEdit
                  ? "おすすめ商品を編集完了"
                  : "ダイレクトメールを作成する"}
              </Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.ColGrow>
                <Button.Primary onClick={DMCreateFinished}>完了</Button.Primary>
              </Form.ColGrow>
            </Form.Row>
          </div>
        );

      default:
        return <></>;
    }
  };

  return (
    <>
      <StepsBar steps={steps} currentStepIndex={stepId} />
      <Form.Form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
        {renderByStepIndex(stepId)}
      </Form.Form>
    </>
  );
}

export default DMCustomForm;
