import React from "react";
import MuiButton from "@mui/material/Button";
import DangerButton from "./DangerButton";
import PrimaryButton from "./PrimaryButton";
import SecondaryButton from "./SecondaryButton";

function Button(props) {
  const { className, children, loading, onClick, ...otherProps } = props;

  const forceDisable = loading === true;

  const handleOnClicked = (evt) => {
    if (onClick) {
      onClick(evt);
    }
  };

  return (
    <MuiButton
      className={`w-full ${className ?? ""}`}
      onClick={handleOnClicked}
      disabled={forceDisable}
      {...otherProps}
    >
      {loading ? <>ローディング中</> : children}
    </MuiButton>
  );
}

export default Button;

Button.Primary = PrimaryButton;
Button.Secondary = SecondaryButton;
Button.Danger = DangerButton;
