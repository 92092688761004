import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Api from "app/services/api";
import DefaultLayout from "app/layouts/DefaultLayout";
import Salon from "app/components/Salon";

function DepositEditPage(props) {
  const { salonId, invoiceNumber, stepId } = useParams();

  const [depositItem, setDepositItem] = useState({});
  const [depositItemLoaded, setDepositItemLoaded] = useState(false);

  useEffect(() => {
    async function getDepositItem(invoiceNumber) {
      const response = await Api.deposits.get(invoiceNumber);
      const items = response.data;
      setDepositItem(items);
      setDepositItemLoaded(true);
    }

    getDepositItem( invoiceNumber);
  }, [salonId, invoiceNumber]);

  return (
    <DefaultLayout
      headerProps={{
        headerClassName: "md:!block",
        pageTitle: "入金修正",
      }}
      showNotice={false}
    >
      {!depositItemLoaded ? (
        <div>ローディング中</div>
      ) : (
        <Salon.Deposit.Form isEdit={true} stepId={+stepId} salonId={salonId}>
          {depositItem}
        </Salon.Deposit.Form>
      )}
    </DefaultLayout>
  );
}

export default DepositEditPage;
