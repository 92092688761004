import ContractorTableItem from "./ContractorTableItem";
import ContractorForm from "./ContractorForm";
import ContractorDetail from "./ContractorDetail";
import ContractorGridItem from "./ContractorGridItem";
import Grid from "app/components/common/Grid";
import MainMenuGrid from "app/components/MainMenuGrid";

import Table from "app/components/common/Table";

const exportObjs = {
  MenuGrid: MainMenuGrid,
  Grid: {
    ...Grid,
    Item: ContractorGridItem,
  },
  Table: {
    ...Table,
    Item: ContractorTableItem,
  },
  Form: ContractorForm,
  Detail: ContractorDetail,
};

export default exportObjs;
