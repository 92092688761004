import React from "react";
import {
  TEModal,
  TEModalDialog,
  TEModalContent,
  TEModalHeader,
  TEModalBody,
  TEModalFooter,
} from "tw-elements-react";

function FinishModal(props) {
  const {
    show,
    setShow,
    staticBackdrop,
    centered,
    headerContent,
    handleCloseMedal,
    children,
  } = props;

  return (
    <TEModal show={show} setShow={setShow} staticBackdrop={staticBackdrop}>
      <TEModalDialog centered={centered ?? true}>
        <TEModalContent>
          <TEModalHeader
            style={{
              position: "relative",
              border: "none",
            }}
          >
            {headerContent}
            <div className="absolute left-[50%] top-[-80%] translate-x-[-50%] w-[60px] h-[60px] bg-[white] rounded-[50%]"></div>
          </TEModalHeader>
          {/* <!--Modal body--> */}
          {children && (
            <TEModalBody style={{ border: "none" }}>{children}</TEModalBody>
          )}
          <TEModalFooter style={{ border: "none", display: "flex", justifyContent: "end"}}>
          <button onClick={handleCloseMedal} className="bg-gray-700 text-white px-[10%] max-w-[50%] text-lg py-2 rounded-md" >終了</button>
          </TEModalFooter>
        </TEModalContent>
      </TEModalDialog>
    </TEModal>
  );
}

export default FinishModal;
