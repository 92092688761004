import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DefaultLayout from "app/layouts/DefaultLayout";
import Path from "app/route/Path";
import Api from "app/services/api";
import Button from "app/components/common/Button";
import SearchBox from "app/components/common/SearchBox";
import SystemSetting from "app/components/SystemSetting";
import { useParams } from "react-router-dom";
import BackBtn from "../../components/BackButton";

function EmployeeListPage() {
  const { employeeType } = useParams();
  const navigate = useNavigate();

  // **
  const registerButtonClicked = () => {
    navigate(Path.systemSetting.employee.create(0));
  };
  const articleregisterButtonClicked = () => {
    navigate(Path.itemImport);
  };
  // **

  const [searchText, setSearchText] = useState("");
  const [searchEmployees, setSearchEmployees] = useState([]);
  const [searchEmployeesLoaded, setSearchEmployeesLoaded] = useState(false);

  useEffect(() => {
    async function getAllEmployees() {
      const response = await Api.employees.searchByEmployeeType("manager");
      const items = response.data;
      setSearchEmployees(items);
      setSearchEmployeesLoaded(true);
    }

    getAllEmployees();
  }, []);

  var pageTitle = "一覧";
  var employeeTypeText = "";
  if (employeeType == "manager") employeeTypeText = "管理者";
  if (employeeType == "staff") employeeTypeText = "スタッフ";

  pageTitle = employeeTypeText + pageTitle;

  const searchClicked = async (evt) => {
    if (searchText === "") {
      return;
    }

    setSearchEmployeesLoaded(false);
    const response = await Api.employees.search(searchText);
    const items = response.data;
    setSearchEmployees(items);
    setSearchEmployeesLoaded(true);
  };

  const onDetailClicked = (employeeItem) => {
    navigate(Path.systemSetting.employee.detail(employeeItem.id));
  };

  return (
    <DefaultLayout
      headerProps={{
        headerClassName: "md:!block",
        pageTitle: pageTitle,
      }}
      showNotice={false}
    >
      <SystemSetting.Table.Table
        items={searchEmployees}
        loading={!searchEmployeesLoaded}
      >
        <SystemSetting.Table.Loading>
          <tbody>
            <tr>
              <td colSpan="100">ローディング中</td>
            </tr>
          </tbody>
        </SystemSetting.Table.Loading>
        <SystemSetting.Table.Empty>
          {employeeTypeText}がないです。
        </SystemSetting.Table.Empty>
        <SystemSetting.Table.Header>
          <tr>
            <th scope="col" className="px-6 py-4">
              アクセス許可
            </th>
            <th scope="col" className="px-6 py-4">
              許可
            </th>
            <th scope="col" className="px-6 py-4">
              拒否
            </th>
          </tr>
        </SystemSetting.Table.Header>
        <SystemSetting.Table.Items>
          {searchEmployees.map((item) => (
            <SystemSetting.Table.SharedInfoLevelTableItem
              key={item.id}
              onDetailClick={onDetailClicked}
            >
              {item}
            </SystemSetting.Table.SharedInfoLevelTableItem>
          ))}
        </SystemSetting.Table.Items>
      </SystemSetting.Table.Table>
      <BackBtn url={Path.systemSetting.home}/>
    </DefaultLayout>
  );
}

export default EmployeeListPage;
