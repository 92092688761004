import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import Api from "app/services/api";
import DefaultLayout from "app/layouts/DefaultLayout";
import Button from "app/components/common/Button";
import Text from "app/components/common/Text";
import Label from "app/components/common/Label";
import MonthRange from "app/components/MonthRange";
import { useMemo } from "react";
import { CSVLink } from "react-csv";
import BackBtn from "../../../components/BackButton";
import Path from "app/route/Path";

const mockData = [
  {
      "_id": "65263dd7eb8531c053a9537e",
      "name": "product1",
      "price": 1234,
      "amount": 3,
      "productId": "655430d4453a6336e559bb6c",
      "__v": 0,
      "createdAt": "2023-09-01T17:00:00.000Z",
      "updatedAt": "2023-10-11T06:16:55.919Z",
      "product": {
          "_id": "655430d4453a6336e559bb6c",
          "productNumber": "9b3efcdd-6d3",
          "maker": "ece08566-5cf6-4bfc-9380-811c1ce02642",
          "series": "e425cc71-21bb-420e-9b8d-4719bb2cc4ff",
          "name": "tuấntm",
          "price": 12000,
          "description": "tuấntm",
          "imageUrl": "http://192.168.200.156:3600/1700016330724-698889150-meta-platform.png",
          "category": "rinse",
          "productScope": "monopoly",
          "status": 1,
          "productInventoryIds": [
              "6528e7c9ab1d6d7b6e8f23ea"
          ],
          "createdAt": "2023-11-15T02:45:40.015Z",
          "updatedAt": "2023-11-15T02:45:40.015Z",
          "__v": 0
      }
  },
  
  {
    "_id": "65263dd7eb8531c053a9537e",
    "name": "product1",
    "price": 5678,
    "amount": 6,
    "productId": "655430d4453a6336e559bb6c",
    "__v": 0,
    "createdAt": "2023-09-01T17:00:00.000Z",
    "updatedAt": "2023-10-11T06:16:55.919Z",
    "product": {
        "_id": "655430d4453a6336e559bb6c",
        "productNumber": "9b3efcdd-6d3",
        "maker": "ece08566-5cf6-4bfc-9380-811c1ce02642",
        "series": "e425cc71-21bb-420e-9b8d-4719bb2cc4ff",
        "name": "tuấntm",
        "price": 12000,
        "description": "tuấntm",
        "imageUrl": "http://192.168.200.156:3600/1700016330724-698889150-meta-platform.png",
        "category": "rinse",
        "productScope": "monopoly",
        "status": 1,
        "productInventoryIds": [
            "6528e7c9ab1d6d7b6e8f23ea"
        ],
        "createdAt": "2023-11-15T02:45:40.015Z",
        "updatedAt": "2023-11-15T02:45:40.015Z",
        "__v": 0
    }
}
]

function SalesPage(props) {
  const { salonId } = useParams();

  const [displayYear, setDisplayYear] = useState(dayjs().year());
  const [displayMonth, setDisplayMonth] = useState(dayjs().month() + 1);
  const [allData,setAllData]=useState([])
  const [salesAmount, setSalesAmount] = useState(0);
  const [salesAmountLoaded, setSalesAmountLoaded] = useState(false);

  useEffect(() => {

    async function getSalonSalesAmount() {
      const startDate = dayjs(new Date(displayYear, displayMonth - 1, 1));
      const endDate = startDate.add(1, "month").subtract(1, "day");
      const response = await Api.sales.search(startDate.toISOString(), endDate.toISOString(), salonId);
      setAllData(response?.data?.result);
      const salesAmount = response?.data?.result
        ?.map(({ amount }) => amount)
        ?.reduce((accumulator, currentValue) => {
          return accumulator + currentValue;
        }, 0); 
      
      const item = {
        salesAmount,
        memberFee:0,
        inspectionFee:0,
      };
      
      if (item) {
        setSalesAmount(item);
      } else {
        setSalesAmount({
          salesAmount: 0,
          memberFee: 0,
          inspectionFee: 0,
        });
      }
      setSalesAmountLoaded(true);
    }

    setSalesAmountLoaded(false);
    getSalonSalesAmount();
  }, [displayYear, displayMonth, salonId]);

  const formatedCsvList = useMemo(() => {
    const startDate = dayjs(new Date(displayYear, displayMonth - 1, 1));
    const endDate = startDate.add(1, "month").subtract(1, "day");
    const diff = endDate.diff(startDate, "day");

    const list = new Array(diff + 1)?.fill({})?.map((_, index) => {
      const salesAmount = allData[index]?.amount || 0;
      const memberFee = allData[index]?.memberFee || 0;
      const inspectionFee = allData[index]?.inspectionFee || 0;
      return [startDate.add(index, "day").format("YYYY年MM月DD日"), inspectionFee, memberFee, salesAmount];
    });
    return [["日付", "加盟料", "検査代", "総額"], ...(list || [])];
  }, [displayYear, displayMonth, salonId, allData]);

  return (
    <DefaultLayout
      headerProps={{
        headerClassName: "md:!block",
        pageTitle: "売上管理",
      }}
      showNotice={false}
    >
      <Button.Secondary >
        <CSVLink data={formatedCsvList} filename={"加盟店一覧"}>
            エクスポート
        </CSVLink>
      </Button.Secondary>
      <MonthRange
        year={displayYear}
        month={displayMonth}
        setYear={setDisplayYear}
        setMonth={setDisplayMonth}
      ></MonthRange>
      <div className="flex mx-auto w-96 bg-primary p-4 rounded-lg text-white">
        <div className="flex-none">総額</div>
        <div className="flex-1 text-center">
          {salesAmountLoaded ? (
            <Label.Price>{salesAmount?.salesAmount}</Label.Price>
          ) : (
            "ローディング中"
          )}
        </div>
      </div>
      <Text.Normal className="mx-auto">売上内容</Text.Normal>
      <div className="flex mx-auto w-96 p-8 border-2 border-solid border-black">
        <table className="table-auto w-full">
          <thead>
            <tr>
              <th>期間</th>
              <th>売上金額</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="text-center">加盟料</td>
              <td className="text-center">
                {salesAmountLoaded ? (
                  <Label.Price>{salesAmount?.memberFee}</Label.Price>
                ) : (
                  "ローディング中"
                )}
              </td>
            </tr>
            <tr>
              <td className="text-center">検査代</td>
              <td className="text-center">
                {salesAmountLoaded ? (
                  <Label.Price>{salesAmount?.inspectionFee}</Label.Price>
                ) : (
                  "ローディング中"
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <BackBtn url={Path.salon.deposit.home(salonId)}/>
    </DefaultLayout>
  );
}

export default SalesPage;
