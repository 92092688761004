import React, { useEffect, useMemo } from "react";
import Button from "app/components/common/Button";
import Label from "app/components/common/Label";
import {getNameFromFirstLast} from "app/utils";
import dayjs from "dayjs";
import { defaultDateTimeFormat } from "app/constants";

function DMCustTableItem(props) {
  const { children, onDetailClick, customerOrSalon } = props;
  const DMHistory = children;

  const onDetailClicked = () => {
    onDetailClick(DMHistory);
  };

  // get customer name from DMHistory.customers[0]
  const customerOrSalonName = useMemo(() => {
    if (customerOrSalon === "customer" && DMHistory?.customers[0]) {
      const customerName = getNameFromFirstLast(DMHistory?.customers[0]);
      return customerName;
    } else if(customerOrSalon === "salon" && DMHistory?.salon[0]?.name) {
      const salonName = DMHistory?.salon[0]?.name
      return salonName;
    }
    return ""
  }, [customerOrSalon, DMHistory]);

  const presetTime = useMemo(() => {
    const formatDate = dayjs(DMHistory.presetTime).format(defaultDateTimeFormat);
    return formatDate;
  }, [DMHistory]);

  return (
    <tr className="border-b dark:border-neutral-500">
      <td className="whitespace-nowrap px-6 py-4 font-medium">
        {presetTime}
      </td>
      <td className="whitespace-nowrap px-6 py-4">
        {customerOrSalonName}
      </td>
      <td className="whitespace-nowrap px-6 py-4">{DMHistory.title}</td>
      <td className="whitespace-nowrap px-6 py-4">{DMHistory.status}</td>
      <td className="whitespace-nowrap px-6 py-4">
        <Button.Primary onClick={onDetailClicked}>詳細</Button.Primary>
      </td>
    </tr>
  );
}

export default DMCustTableItem;
