import React from "react";
import { useParams } from "react-router-dom";
import DefaultLayout from "app/layouts/DefaultLayout";
import Kit from "app/components/Kit";

function KitDeliveryCreatePage() {
  const { stepId } = useParams();

  return (
    <DefaultLayout
      headerProps={{
        headerClassName: "md:!block",
        pageTitle: "発送登録",
      }}
      showNotice={false}
    >
      <Kit.Delivery.Form stepId={+stepId}> </Kit.Delivery.Form>
    </DefaultLayout>
  );
}

export default KitDeliveryCreatePage;
