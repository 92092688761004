import React from "react";
import { useNavigate } from "react-router-dom";
import MainMenuItem from "app/components/MainMenuItem";
import Path from "app/route/Path";

function ManagerCreateMenuItem(props) {
  const navigate = useNavigate();

  const onMenuItemClicked = () => {
    navigate(Path.systemSetting.employee.create(0, "manager"));
  };

  return (
    <MainMenuItem
      itemTitleString="管理者を登録する"
      onClick={onMenuItemClicked}
      size="100%"
    />
  );
}

export default ManagerCreateMenuItem;
