import { useEffect, useState } from "react";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';

export default function DateRangePicker({ valueFromUrl, onchange }) {
  const initialState = { fromDate: "", toDate: "" }
  // state manage the value of the component
  const [date, setDate] = useState(initialState);

  // push the local value outside the component through a method
  const handleSetDate = () => {
    onchange && onchange(date);
  };

  const handleClearDate = () => {
    setDate(initialState)
    onchange && onchange(initialState);
  }

  useEffect(() => {
    if(valueFromUrl) setDate(valueFromUrl);
  }, [valueFromUrl])

  return (
    <div className="flex flex-col gap-1 w-full mt-[2px]">
      <div className="flex">
        <p className="basis-[24%]">From: </p>
        <input
          type="date"
          className="rounded-md px-1 py-1 text-xs flex-1"
          value={date.fromDate}
          onChange={(e) =>
            setDate((previous) => {
              return { ...previous, fromDate: e.target.value };
            })
          }
        />
      </div>
      <div className="flex">
        <p className="basis-[24%]">To:</p>
        <input
          type="date"
          className="rounded-md px-1 py-1 text-xs flex-1"
          value={date.toDate}
          onChange={(e) =>
            setDate((previous) => {
              return { ...previous, toDate: e.target.value };
            })
          }
        />
      </div>
      <div className="flex gap-2 justify-center mt-1">
        <button onClick={handleSetDate} className="border border-black rounded-md"><FilterAltIcon /></button>
        <button onClick={handleClearDate} className="border border-black rounded-md"><FilterAltOffIcon /></button>
      </div>
    </div>
  );
}
