import React from "react";
import ExaminationListMenuItem from "./Examination/ExaminationListMenuItem";
import KitHomeMenuItem from "./Kit/KitHomeMenuItem";
import KitOrderCreateMenuItem from "./Kit/KitOrderCreateMenuItem";
import KitStockCreateMenuItem from "./Kit/KitStockCreateMenuItem";
import KitRequestListMenuItem from "./Kit/KitRequestListMenuItem";
import KitOrderHistoryMenuItem from "./Kit/KitOrderHistoryMenuItem";
import KitStockListMenuItem from "./Kit/KitStockListMenuItem";
import KitDeliveryListMenuItem from "./Kit/KitDeliveryListMenuItem";
import DepositListMenuItem from "./Salon/Deposit/DepositListMenuItem";
import DepositSalesMenuItem from "./Salon/Deposit/SalesMenuItem";
import DepositManagementMenuItem from "./Salon/Deposit/DepositManagementMenuItem";
import DepositInvoiceMenuItem from "./Salon/Deposit/InvoiceMenuItem";
import DepositContactMenuItem from "./Salon/Deposit/DepositContactMenuItem";
import SalonManagementMenuItem from "./Salon/Management/SalonManagementMenuItem";
import SalonManagementHomeMenuItem from "./Salon/Management/SalonManagementHomeMenuItem";
import SalonManagementCreateMenuItem from "./Salon/Management/SalonManagementCreateMenuItem";
import SalonManagementDetailMenuItem from "./Salon/Management/SalonManagementDetailMenuItem";
import SalonManagementContactMenuItem from "./Salon/Management/SalonManagementContactMenuItem";
import SalonManagementCreateNoticeMenuItem from "./Salon/Management/SalonManagementCreateNoticeMenuItem";

import ContractMenuItem from "./ContractMenuItem";
import SendMenuItem from "./Contract/SendMenuItem";
import StatusMenuItem from "./Contract/StatusMenuItem";
import ContactMenuItem from "./Contract/ContactMenuItem";
import RecommendMenuItem from "./RecommendMenuItem";
import DMMenuItem from "./DM/DMMenuItem";
import DMCustCreateMenuItem from "./DM/Customer/DMCustCreateMenuItem";
import DMCustHistoryMenuItem from "./DM/Customer/DMCustHistoryMenuItem";
import DMSalonCreateMenuItem from "./DM/Salon/DMSalonCreateMenuItem";
import DMSalonHistoryMenuItem from "./DM/Salon/DMSalonHistoryMenuItem";
import AgencyMenuItem from "./AgencyMenuItem";
import AgencyCreateMenuItem from "./Contractor/AgencyCreateMenuItem";
import AgencyListMenuItem from "./Contractor/AgencyListMenuItem";
import OutsourcingCreateMenuItem from "./Contractor/OutsourcingCreateMenuItem";
import OutsourcingListMenuItem from "./Contractor/OutsourcingListMenuItem";
import SystemSettingMenuItem from "./SystemSettingMenuItem";
import ManagerCreateMenuItem from "./SystemSetting/ManagerCreateMenuItem";
import ManagerListMenuItem from "./SystemSetting/ManagerListMenuItem";
import StaffCreateMenuItem from "./SystemSetting/StaffCreateMenuItem";
import StaffListMenuItem from "./SystemSetting/StaffListMenuItem";
import SharedInfoLevelMenuItem from "./SystemSetting/SharedInfoLevelMenuItem";
import SalesOrderManagementListMenuItem from "./Drc/SalesOrderManagementListMenuItem";
import ExaminationResultCreateMenuItem from "./Drc/ExaminationResultCreateMenuItem";
import ExaminationResultListMenuItem from "./Drc/ExaminationResultListMenuItem";
import LabContactCreateMenuItem from "./Drc/LabContactCreateMenuItem";

function MainMenuItem(props) {
  const {
    size,
    borderSize,
    itemTitle,
    itemTitleString,
    onClick,
    isActive,
    disabled,
  } = props;

  const itemTitleStringToUi = (titleString) => {
    const splittedTitle = titleString.split("\\n");

    return (
      <>
        {splittedTitle.map((title, idx) => (
          <p key={idx}>{title}</p>
        ))}
      </>
    );
  };

  return (
    <button
      type="button"
      className={`rounded-full overflow-hidden group relative ${
        disabled
          ? isActive
            ? "!border-main-hover"
            : "!border-main-default"
          : isActive
          ? "!border-main-hover"
          : "border-main-default"
      } hover:border-main-hover`}
      style={{
        width: typeof size === "number" ? `${size}px` : size,
        height: typeof size === "number" ? `${size}px` : size,
      }}
      onClick={() => {
        onClick && !disabled && onClick();
      }}
    >
      <div
        className={`w-full h-full rounded-full
        ${
          disabled
            ? isActive
              ? "!border-main-hover cursor-default"
              : "!border-main-default cursor-default"
            : isActive
            ? "!border-main-hover cursor-pointer"
            : "border-main-default cursor-pointer"
        } group-hover:border-main-hover`}
        style={{
          borderWidth:
            typeof borderSize === "number"
              ? `${borderSize}px`
              : typeof borderSize === "string"
              ? borderSize
              : "5px",
        }}
      ></div>
      <div className="absolute inset-0 rounded-full overflow-hidden text-white  ">
        <div className="w-full h-[36%] bg-transparent"></div>
        <div
          className={`w-full h-[64%] bg-main-default group-hover:bg-main-hover pb-[10%] flex flex-col items-center justify-center
        ${
          disabled
            ? isActive
              ? "!bg-main-hover"
              : "!bg-main-default"
            : isActive
            ? "!bg-main-hover"
            : "bg-main-default"
        } group-hover:bg-main-hover
        `}
        >
          {itemTitleString ? itemTitleStringToUi(itemTitleString) : itemTitle}
        </div>
      </div>
    </button>
  );
}

MainMenuItem.defaultProps = {
  size: 120,
};

export default MainMenuItem;

MainMenuItem.Examination = {
  List: ExaminationListMenuItem,
};
MainMenuItem.Kit = {
  Home: KitHomeMenuItem,
  Delivery: {
    List: KitDeliveryListMenuItem,
  },
  Request: {
    List: KitRequestListMenuItem,
  },
  Order: {
    Create: KitOrderCreateMenuItem,
    History: KitOrderHistoryMenuItem,
  },
  Stock: {
    Create: KitStockCreateMenuItem,
    List: KitStockListMenuItem,
  },
};

MainMenuItem.Salon = {
  Deposit: {
    List: DepositListMenuItem,
    Sales: DepositSalesMenuItem,
    Manage: DepositManagementMenuItem,
    Invoice: DepositInvoiceMenuItem,
    Contact: DepositContactMenuItem,
  },
  Manage: {
    Menu: SalonManagementMenuItem,
    Home: SalonManagementHomeMenuItem,
    Create: SalonManagementCreateMenuItem,
    Detail: SalonManagementDetailMenuItem,
    Contact: SalonManagementContactMenuItem,
    CreateNotice: SalonManagementCreateNoticeMenuItem,
  },
};

MainMenuItem.Contract = {
  Menu: ContractMenuItem,
  Send: SendMenuItem,
  Status: StatusMenuItem,
  Contact: ContactMenuItem,
};
MainMenuItem.Recommend = RecommendMenuItem;
MainMenuItem.DM = {
  Menu: DMMenuItem,
  Customer: {
    Create: DMCustCreateMenuItem,
    History: DMCustHistoryMenuItem,
  },
  Salon: {
    Create: DMSalonCreateMenuItem,
    History: DMSalonHistoryMenuItem,
  },
};
MainMenuItem.Contractor = {
  Menu: AgencyMenuItem,
  Agency: {
    Create: AgencyCreateMenuItem,
    List: AgencyListMenuItem,
  },
  Outsourcing: {
    Create: OutsourcingCreateMenuItem,
    List: OutsourcingListMenuItem,
  },
};
MainMenuItem.SystemSetting = {
  Menu: SystemSettingMenuItem,
  Manager: {
    Create: ManagerCreateMenuItem,
    List: ManagerListMenuItem,
  },
  Staff: {
    Create: StaffCreateMenuItem,
    List: StaffListMenuItem,
  },
  SharedInfoLevel: SharedInfoLevelMenuItem,
};
MainMenuItem.Drc = {
  SalesOrderManagement: {
    List: SalesOrderManagementListMenuItem,
  },
  ExaminationResult: {
    Create: ExaminationResultCreateMenuItem,
    List: ExaminationResultListMenuItem,
  },
  LabContact: {
    Create: LabContactCreateMenuItem,
  },
};
