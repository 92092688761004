import Modal from "app/components/common/Modal";
import Button from "app/components/common/Button";

export const DELETE_STEP = {
  STEP_1: 1,
  STEP_2: 2,
  STEP_3: 3,
  FAILED: "FAIL",
};

const DEFAULT_MODAL_TITLE = {
  [DELETE_STEP.STEP_1]: "Default Delete title 1",
  [DELETE_STEP.STEP_2]: "Default Delete title 2",
  [DELETE_STEP.STEP_3]: "Default Delete title 3",
  [DELETE_STEP.FAILED]: "Default Delete title failed",
};

export default function DoubleConfirmDeleteModal({ onDelete, onCancel, name, setShowDeleteModal, showDeleteModal, titleObj, loading }) {
  const MODAL_TITLE = titleObj || DEFAULT_MODAL_TITLE;

  return (
    <Modal
      show={!!showDeleteModal}
      setShow={setShowDeleteModal}
      staticBackdrop={true}
      title={MODAL_TITLE[showDeleteModal]}
      loading={loading}
      button1={
        showDeleteModal === DELETE_STEP.STEP_3 || showDeleteModal === DELETE_STEP.FAILED ? undefined : (
          <Button.Danger
            onClick={() => {
              setShowDeleteModal(DELETE_STEP.STEP_2);
              if (showDeleteModal === DELETE_STEP.STEP_2 && onDelete) {
                onDelete();
              }
            }}>
            削除
          </Button.Danger>
        )
      }
      button2={
        <Button
          onClick={() => {
            onCancel();
            setShowDeleteModal(false);
          }}>
          {showDeleteModal === DELETE_STEP.STEP_3 || showDeleteModal === DELETE_STEP.FAILED ? "終了" : "キャンセル"}
        </Button>
      }>
      {(showDeleteModal === DELETE_STEP.STEP_1 || showDeleteModal === DELETE_STEP.STEP_2) && name}
    </Modal>
  );
}
