import React from "react";
import { useParams } from "react-router-dom";
import DefaultLayout from "app/layouts/DefaultLayout";
import DM from "app/components/DM";

function DMCustCreatePage(props) {
  const { customerOrSalon, stepId } = useParams();

  return (
    <DefaultLayout
      headerProps={{
        headerClassName: "md:!block",
        pageTitle: "ダイレクトメールを作成する",
      }}
      showNotice={false}
    >
      <DM.Form stepId={+stepId} customerOrSalon={customerOrSalon} />
    </DefaultLayout>
  );
}

export default DMCustCreatePage;
