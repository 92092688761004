import React from "react";
import Button from "app/components/common/Button";
import Label from "app/components/common/Label";

function ExaminationResultTableItem(props) {
  const { children, onDetailClick, onAnalysisStartClick } = props;
  const examinationResult = children;

  const onDetailClicked = () => {
    onDetailClick(examinationResult);
  };

  const onAnalysisStartClicked = () => {
    onAnalysisStartClick(examinationResult);
  };

  return (
    <tr className="border-b dark:border-neutral-500">
      <td className="whitespace-nowrap px-6 py-4">
        <Button.Primary
          disabled={
            examinationResult.analyseStatus == "analyse_not_started"
              ? false
              : true
          }
          onClick={onAnalysisStartClicked}
        >
          {examinationResult.analyseStatus == "analyse_not_started"
            ? "分析開始"
            : examinationResult.analyseStatus == "analyse_in_progress"
            ? "分析中"
            : examinationResult.analyseStatus == "analyse_completed"
            ? "分析完了"
            : "エラー"}
        </Button.Primary>
      </td>
      {/* <td className="whitespace-nowrap px-6 py-4">
        {examinationResult.examinationResultId}
      </td> */}
      <td className="whitespace-nowrap px-6 py-4">{examinationResult.cusId}</td>
      <td className="whitespace-nowrap px-6 py-4">{examinationResult.kitId}</td>
      <td className="whitespace-nowrap px-6 py-4">
        {examinationResult.cluster}
      </td>
      <td className="whitespace-nowrap px-6 py-4">
        {examinationResult.cellArea}
      </td>
      <td className="whitespace-nowrap px-6 py-4">
        {examinationResult.peeling}
      </td>
      <td className="whitespace-nowrap px-6 py-4">{examinationResult.cp}</td>
      <td className="whitespace-nowrap px-6 py-4">
        {examinationResult.overall}
      </td>
      <td className="whitespace-nowrap px-6 py-4">
        {examinationResult.orderDate}
      </td>
      <td className="whitespace-nowrap px-6 py-4">
        <Button.Primary onClick={onDetailClicked}>詳細</Button.Primary>
      </td>
    </tr>
  );
}

export default ExaminationResultTableItem;
