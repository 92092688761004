import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {memo,useEffect} from "react";

function AnalyseStatusSelectionInner({ valueFromUrl, title, arrayData, onchange}) {
  const [value, setValue] = React.useState(valueFromUrl);

  useEffect(() => {
    if(valueFromUrl) setValue(valueFromUrl);
  }, [valueFromUrl])

  const handleChange = (event) => {
    const value = event.target.value;
    onchange && onchange(value)
  };

  return (
    <Box sx={{ minWidth: 120, marginTop: "5px" }}>
      <FormControl fullWidth sx={{ width: "130px" }}>
        <InputLabel id="demo-simple-select-label" sx={{ padding: "0" }}>{title}</InputLabel>
        <Select
          sx={{ fontSize: '12px', textTransform: "capitalize" }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
          label="Age"
          onChange={handleChange}
        >
          {arrayData?.map((item) => {
            const { title, value } = item;
            return <MenuItem value={value} sx={{fontSize: '12px'}}>{title}</MenuItem>;
          })}
        </Select>
      </FormControl>
    </Box>
  );
}
const AnalyseStatusSelection = memo(AnalyseStatusSelectionInner)
export default AnalyseStatusSelection
