import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Api from "app/services/api";
import DefaultLayout from "app/layouts/DefaultLayout";
import Salon from "app/components/Salon";

function DepositFulfillPage(props) {
  const { salonId, invoiceNumber, stepId } = useParams();

  const [depositItem, setDepositItem] = useState({});
  const [depositItemLoaded, setDepositItemLoaded] = useState(false);

  useEffect(() => {
    async function getDepositItem() {
      const response = await Api.deposits.get(invoiceNumber);
      const item = response.data;
      setDepositItem(item);
      setDepositItemLoaded(true);
    }

    getDepositItem();
  }, [salonId, invoiceNumber]);

  return (
    <DefaultLayout
      headerProps={{
        headerClassName: "md:!block",
        pageTitle: "入金登録",
      }}
      showNotice={false}
    >
      {!depositItemLoaded ? (
        <div>ローディング中</div>
      ) : (
        <Salon.Deposit.Form
          isPrefilled={true}
          stepId={+stepId}
          salonId={salonId}
        >
          {depositItem}
        </Salon.Deposit.Form>
      )}
    </DefaultLayout>
  );
}

export default DepositFulfillPage;
