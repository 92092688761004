import React from "react";
import { useState, useEffect } from "react";
import DefaultLayout from "app/layouts/DefaultLayout";
import { useNavigate } from "react-router-dom";
import Api from "app/services/api";
import Path from "app/route/Path";
import Button from "app/components/common/Button";
import Kit from "app/components/Kit";
import BackBtn from "../../../components/BackButton";

function KitOrderListPage() {
  const navigate = useNavigate();

  const [kitOrderHistories, setKitOrderHistories] = useState([]);
  const [kitOrderHistoriesLoaded, setKitOrderHistoriesLoaded] = useState(false);

  useEffect(() => {
    async function getAllKitOrderHistories() {
      const response = await Api.kit.order.histories.all();
      const items = response.data;
      setKitOrderHistories(items);
      setKitOrderHistoriesLoaded(true);
    }

    getAllKitOrderHistories();
  }, []);

  const kitOrderButtonClicked = () => {
    navigate(Path.kit.order.create(0));
  };

  return (
    <DefaultLayout
      headerProps={{
        headerClassName: "md:!block",
        pageTitle: "キットの発注履歴",
      }}
    >
      <Button.Primary onClick={kitOrderButtonClicked}>
        キットを発注
      </Button.Primary>
      <Kit.Order.Table.Table
        items={kitOrderHistories}
        loading={!kitOrderHistoriesLoaded}
      >
        <Kit.Order.Table.Loading>
          <tbody>
            <tr>
              <td colSpan="100">ローディング中</td>
            </tr>
          </tbody>
        </Kit.Order.Table.Loading>
        <Kit.Order.Table.Empty>キットの発注がないです。</Kit.Order.Table.Empty>
        <Kit.Order.Table.Header>
          <tr>
            <th scope="col" className="px-6 py-4">
              発注日
            </th>
            <th scope="col" className="px-6 py-4">
              数量
            </th>
            <th scope="col" className="px-6 py-4">
              備考
            </th>
          </tr>
        </Kit.Order.Table.Header>
        <Kit.Order.Table.Items>
          {kitOrderHistories.map((item) => (
            <Kit.Order.Table.Item key={item.id}>{item}</Kit.Order.Table.Item>
          ))}
        </Kit.Order.Table.Items>
      </Kit.Order.Table.Table>
      <BackBtn url={Path.kit.home}/>
    </DefaultLayout>
  );
}

export default KitOrderListPage;
