import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Api from "app/services/api";
import DefaultLayout from "app/layouts/DefaultLayout";
import DM from "app/components/DM";
import {DMHistories} from "app/services/api"
import BackBtn from "../../components/BackButton";
import Path from "app/route/Path";

function DMDetailPage(props) {
  const { customerOrSalon, DMHistoryId } = useParams();

  const [DMHistory, setDMHistory] = useState({});
  const [DMHistoryLoaded, setDMHistoryLoaded] = useState(false);

  useEffect(() => {
    if(customerOrSalon === "salon"){
      const handleGetDmSalonDetail = async (DMHistoryId) => {
        await DMHistories.getDMSalonHistoryDetail(DMHistoryId).then((data) => {
          const dMSalonHistoryDetail = data.data.result;
          console.log("dMHHistoryDetail salon: ", dMSalonHistoryDetail);
          setDMHistory(dMSalonHistoryDetail);
          setDMHistoryLoaded(true);
        });
      };
      handleGetDmSalonDetail(DMHistoryId);
    } else {
      const handleGetDmCustomerDetail = async (DMHistoryId) => {
        await DMHistories.getDMCustomerDetailApi(DMHistoryId).then((data) => {
          const dMCustomerDetail = data.data.result;
          console.log("dMHHistoryDetail customer: ", dMCustomerDetail);
          setDMHistory(dMCustomerDetail);
          setDMHistoryLoaded(true);
        });
      };
      handleGetDmCustomerDetail(DMHistoryId);
    }
  }, [DMHistoryId, customerOrSalon]);

  return (
    <DefaultLayout
      headerProps={{
        headerClassName: "md:!block",
        pageTitle: DMHistory.targetName,
      }}
      showNotice={false}
    >
      {!DMHistoryLoaded ? (
        <div>ローディング中</div>
      ) : (
        <div className="space-y-4">
          <DM.Detail customerOrSalon={customerOrSalon}>{DMHistory}</DM.Detail>
        </div>
      )}
      <BackBtn />
    </DefaultLayout>
  );
}

export default DMDetailPage;
