export const fake_examination_datas = [
  {
    id: "ExaminationDataId1",
    cusId: "CustomerId1",
    kitId: "kitId1",
    cluster: "cluster1",
    cellArea: "area1",
    peeling: "peeling1",
    cp: "cp1",
    overall: "overall1",
    registerDate: "2023-09-14",
    analyseStatus: "done",
  },
  {
    id: "ExaminationDataId2",
    cusId: "CustomerId2",
    kitId: "kitId2",
    cluster: "cluster2",
    cellArea: "area2",
    peeling: "peeling2",
    cp: "cp2",
    overall: "overall2",
    registerDate: "2023-09-24",
    analyseStatus: "inprogress",
  },
  {
    id: "ExaminationDataId3",
    cusId: "CustomerId3",
    kitId: "kitId3",
    cluster: "cluster3",
    cellArea: "area3",
    peeling: "peeling3",
    cp: "cp3",
    overall: "overall3",
    registerDate: "2023-09-24",
    analyseStatus: "inprogress",
  },
  {
    id: "ExaminationDataId4",
    cusId: "CustomerId4",
    kitId: "kitId4",
    cluster: "cluster4",
    cellArea: "area4",
    peeling: "peeling4",
    cp: "cp4",
    overall: "overall4",
    registerDate: "2023-09-24",
    analyseStatus: "inprogress",
  },
  {
    id: "ExaminationDataId5",
    cusId: "CustomerId5",
    kitId: "kitId5",
    cluster: "cluster5",
    cellArea: "area5",
    peeling: "peeling5",
    cp: "cp5",
    overall: "overall5",
    registerDate: "2023-09-24",
    analyseStatus: "inprogress",
  },
  {
    id: "ExaminationDataId6",
    cusId: "CustomerId6",
    kitId: "kitId6",
    cluster: "cluster6",
    cellArea: "area6",
    peeling: "peeling6",
    cp: "cp6",
    overall: "overall6",
    registerDate: "2023-09-24",
    analyseStatus: "inprogress",
  },
];

export const fake_order_histories = [
  {
    id: "OrderHistoryId1",
    orderDate: "2023-09-24",
    amount: 10,
    memo: "AAAA A A A AAAAA AAA AAAAAAA\nAAA AAAA AA AAAA AA",
  },
  {
    id: "OrderHistoryId2",
    orderDate: "2023-09-25",
    amount: 50,
    memo: "BBBB B B B BBBBB\nBBB BBBBBBB BBB BBBB\nBB BBBB BB",
  },
];

export const fake_order_requests = [
  {
    id: "OrderRequestId1",
    orderDate: "2023-09-24",
    salonId: "SalonId1",
    amount: 10,
    deliveryStatus: "notdeliver",
  },
  {
    id: "OrderRequestId2",
    orderDate: "2023-09-25",
    salonId: "SalonId2",
    amount: 50,
    deliveryStatus: "delivered",
  },
];

export const fake_delivery_histories = [
  {
    id: "DeliveryHistoryId1",
    deliveryDate: "2023-09-24",
    salonId: "SalonId1",
    amount: 10,
    deliveryStatus: "notdeliver",
    memo: "AAA\nAAAAAA\nAAAAAAAAAAA",
  },
  {
    id: "DeliveryHistoryId2",
    deliveryDate: "2023-09-25",
    salonId: "SalonId2",
    amount: 50,
    deliveryStatus: "delivered",
    memo: "BBB\nBBBBBB\nBBBBBBBBBBB",
  },
];

export const fake_stock_remaining = 50;

export const fake_stock_histories = [
  {
    id: "StockHistoryId1",
    inStockDate: "2023-09-25",
    amount: 11,
    memo: "AAAAA\nAAAAAAAA",
  },
  {
    id: "StockHistoryId2",
    inStockDate: "2023-09-25",
    amount: 22,
    memo: "BBB\nBBBB",
  },
  {
    id: "StockHistoryId3",
    inStockDate: "2023-09-25",
    amount: 33,
    memo: "CCCCC\nCCCCCCCC",
  },
];

export const fake_sales_amount = [
  {
    id: "SalesAmountId1",
    salonId: "SalonId1",
    salesAmount: 11000,
    memberFee: 5000,
    inspectionFee: 6000,
    date: "2023-08-25",
  },
  {
    id: "SalesAmountId2",
    salonId: "SalonId1",
    salesAmount: 13000,
    memberFee: 6000,
    inspectionFee: 7000,
    date: "2023-09-25",
  },
  {
    id: "SalesAmountId3",
    salonId: "SalonId2",
    salesAmount: 11000,
    memberFee: 5000,
    inspectionFee: 6000,
    date: "2023-09-25",
  },
  {
    id: "SalesAmountId4",
    salonId: "SalonId3",
    salesAmount: 13000,
    memberFee: 6000,
    inspectionFee: 7000,
    date: "2023-09-25",
  },
];

export const fake_salon_deposits = [
  {
    id: "SalonDepositId1",
    salonId: "SalonId1",
    expiryDate: "2023-09-25",
    invoiceNumber: "InvoiceNumber1",
    title: "Title1",
    pendingAmount: 10000,
    depositStatus: "done",
    depositDate: "2023-09-28",
    depositNumber: "DepositNumber1",
    depositAccount: "AccountNumber1",
    depositAmount: 10000,
    memo: "memo1",
  },
  {
    id: "SalonDepositId2",
    salonId: "SalonId1",
    expiryDate: "2023-09-25",
    invoiceNumber: "InvoiceNumber2",
    title: "Title2",
    pendingAmount: 20000,
    depositStatus: "pending",
    depositDate: "2023-09-28",
    depositNumber: "DepositNumber2",
    depositAccount: "AccountNumber2",
    depositAmount: 20000,
    memo: "memo2",
  },
  {
    id: "SalonDepositId3",
    salonId: "SalonId2",
    expiryDate: "2023-09-25",
    invoiceNumber: "InvoiceNumber3",
    title: "Title3",
    pendingAmount: 30000,
    depositStatus: "done",
    depositDate: "2023-09-28",
    depositNumber: "DepositNumber3",
    depositAccount: "AccountNumber3",
    depositAmount: 30000,
    memo: "memo3",
  },
  {
    id: "SalonDepositId4",
    salonId: "SalonId1",
    expiryDate: "2023-09-26",
    invoiceNumber: "InvoiceNumber4",
    title: "Title4",
    pendingAmount: 20000,
    depositStatus: "pending",
    depositDate: "2023-09-28",
    depositNumber: "DepositNumber4",
    depositAccount: "AccountNumber4",
    depositAmount: 20000,
    memo: "memo4",
  },
];

export const fake_salons = [
  {
    id: "SalonId1",
    imageUrl: "https://placehold.jp/1D7874/ffffff/150x150.png?text=美容室1",
    name: "Salon Name 1 AAAA",
    nameKana: "nameKana1",
    picLastName: "picLastName1",
    picFirstName: "picFirstName1",
    telephone: "telephone1",
    email: "email1",
    postalCode: "postalCode1",
    address: "address1",
    latitude: 100.0001,
    longitude: 100.0002,
    artists: [
      {
        id: "ArtistId1",
        lastName: "LastName1",
        firstName: "FirstName1",
        lastNameKana: "LastNameKana1",
        firstNameKana: "FirstNameKana1",
        imageUrl: "https://placehold.jp/f4c095/000000/150x150.png?text=担当者1",
        dob: "2000-09-1",
        telephone: "Telphone1",
        email: "Email1",
        postalCode: "PostalCode1",
        address: "Address1",
      },
      {
        id: "ArtistId2",
        lastName: "LastName2",
        firstName: "FirstName2",
        lastNameKana: "LastNameKana2",
        firstNameKana: "FirstNameKana2",
        imageUrl: "https://placehold.jp/f4c095/000000/150x150.png?text=担当者2",
        dob: "2000-09-2",
        telephone: "Telphone2",
        email: "Email2",
        postalCode: "PostalCode2",
        address: "Address2",
      },
      {
        id: "ArtistId3",
        lastName: "LastName3",
        firstName: "FirstName3",
        lastNameKana: "LastNameKana3",
        firstNameKana: "FirstNameKana3",
        imageUrl: "https://placehold.jp/f4c095/000000/150x150.png?text=担当者3",
        dob: "2000-09-3",
        telephone: "Telphone3",
        email: "Email3",
        postalCode: "PostalCode3",
        address: "Address3",
      },
      {
        id: "ArtistId4",
        lastName: "LastName4",
        firstName: "FirstName4",
        lastNameKana: "LastNameKana4",
        firstNameKana: "FirstNameKana4",
        imageUrl: "https://placehold.jp/f4c095/000000/150x150.png?text=担当者4",
        dob: "2000-09-4",
        telephone: "Telphone4",
        email: "Email4",
        postalCode: "PostalCode4",
        address: "Address4",
      },
      {
        id: "ArtistId5",
        lastName: "LastName5",
        firstName: "FirstName5",
        lastNameKana: "LastNameKana5",
        firstNameKana: "FirstNameKana5",
        imageUrl: "https://placehold.jp/f4c095/000000/150x150.png?text=担当者5",
        dob: "2000-09-5",
        telephone: "Telphone5",
        email: "Email5",
        postalCode: "PostalCode5",
        address: "Address5",
      },
    ],
    menus: [
      {
        id: "MenuId1",
        name: "Menu Name 1",
        description: "Description 1",
        duration: 10,
        price: 1000,
        publishStatus: "published",
        imageUrl:
          "https://placehold.jp/6c596e/ffffff/150x150.png?text=メニュー1",
      },
      {
        id: "MenuId2",
        name: "Menu Name 2",
        description: "Description 2",
        duration: 20,
        price: 2000,
        publishStatus: "published",
        imageUrl:
          "https://placehold.jp/6c596e/ffffff/150x150.png?text=メニュー2",
      },
      {
        id: "MenuId3",
        name: "Menu Name 3",
        description: "Description 3",
        duration: 30,
        price: 3000,
        publishStatus: "published",
        imageUrl:
          "https://placehold.jp/6c596e/ffffff/150x150.png?text=メニュー3",
      },
      {
        id: "MenuId4",
        name: "Menu Name 4",
        description: "Description 4",
        duration: 40,
        price: 4000,
        publishStatus: "published",
        imageUrl:
          "https://placehold.jp/6c596e/ffffff/150x150.png?text=メニュー4",
      },
      {
        id: "MenuId5",
        name: "Menu Name 5",
        description: "Description 5",
        duration: 50,
        price: 5000,
        publishStatus: "published",
        imageUrl:
          "https://placehold.jp/6c596e/ffffff/150x150.png?text=メニュー5",
      },
    ],
  },
  {
    id: "SalonId2",
    imageUrl: "https://placehold.jp/1D7874/ffffff/150x150.png?text=美容室2",
    name: "Salon Name 2 AAAA",
    nameKana: "nameKana2",
    picLastName: "picLastName2",
    picFirstName: "picFirstName2",
    telephone: "telephone2",
    email: "email2",
    postalCode: "postalCode2",
    address: "address2",
    latitude: 200.0001,
    longitude: 200.0002,
    artists: [
      {
        id: "ArtistId6",
        lastName: "LastName6",
        firstName: "FirstName6",
        lastNameKana: "LastNameKana6",
        firstNameKana: "FirstNameKana6",
        imageUrl: "https://placehold.jp/f4c095/000000/150x150.png?text=担当者6",
        dob: "2000-09-6",
        telephone: "Telphone6",
        email: "Email6",
        postalCode: "PostalCode6",
        address: "Address6",
      },
      {
        id: "ArtistId7",
        lastName: "LastName7",
        firstName: "FirstName7",
        lastNameKana: "LastNameKana7",
        firstNameKana: "FirstNameKana7",
        imageUrl: "https://placehold.jp/f4c095/000000/150x150.png?text=担当者7",
        dob: "2000-09-7",
        telephone: "Telphone7",
        email: "Email7",
        postalCode: "PostalCode7",
        address: "Address7",
      },
      {
        id: "ArtistId8",
        lastName: "LastName8",
        firstName: "FirstName8",
        lastNameKana: "LastNameKana8",
        firstNameKana: "FirstNameKana8",
        imageUrl: "https://placehold.jp/f4c095/000000/150x150.png?text=担当者8",
        dob: "2000-09-8",
        telephone: "Telphone8",
        email: "Email8",
        postalCode: "PostalCode8",
        address: "Address8",
      },
      {
        id: "ArtistId9",
        lastName: "LastName9",
        firstName: "FirstName9",
        lastNameKana: "LastNameKana9",
        firstNameKana: "FirstNameKana9",
        imageUrl: "https://placehold.jp/f4c095/000000/150x150.png?text=担当者9",
        dob: "2000-09-9",
        telephone: "Telphone9",
        email: "Email9",
        postalCode: "PostalCode9",
        address: "Address9",
      },
      {
        id: "ArtistId10",
        lastName: "LastName10",
        firstName: "FirstName10",
        lastNameKana: "LastNameKana10",
        firstNameKana: "FirstNameKana10",
        imageUrl:
          "https://placehold.jp/f4c095/000000/150x150.png?text=担当者10",
        dob: "2000-09-10",
        telephone: "Telphone10",
        email: "Email10",
        postalCode: "PostalCode10",
        address: "Address10",
      },
    ],
    menus: [
      {
        id: "MenuId6",
        name: "Menu Name 6",
        description: "Description 6",
        duration: 10,
        price: 1000,
        publishStatus: "published",
        imageUrl:
          "https://placehold.jp/6c596e/ffffff/150x150.png?text=メニュー6",
      },
      {
        id: "MenuId7",
        name: "Menu Name 7",
        description: "Description 7",
        duration: 20,
        price: 2000,
        publishStatus: "published",
        imageUrl:
          "https://placehold.jp/6c596e/ffffff/150x150.png?text=メニュー7",
      },
      {
        id: "MenuId8",
        name: "Menu Name 8",
        description: "Description 8",
        duration: 30,
        price: 3000,
        publishStatus: "published",
        imageUrl:
          "https://placehold.jp/6c596e/ffffff/150x150.png?text=メニュー8",
      },
      {
        id: "MenuId9",
        name: "Menu Name 9",
        description: "Description 9",
        duration: 40,
        price: 4000,
        publishStatus: "published",
        imageUrl:
          "https://placehold.jp/6c596e/ffffff/150x150.png?text=メニュー9",
      },
      {
        id: "MenuId10",
        name: "Menu Name 10",
        description: "Description 10",
        duration: 50,
        price: 5000,
        publishStatus: "published",
        imageUrl:
          "https://placehold.jp/6c596e/ffffff/150x150.png?text=メニュー10",
      },
    ],
  },
  {
    id: "SalonId3",
    imageUrl: "https://placehold.jp/1D7874/ffffff/150x150.png?text=美容室3",
    name: "Salon Name 3 AAAA",
    nameKana: "nameKana3",
    picLastName: "picLastName3",
    picFirstName: "picFirstName3",
    telephone: "telephone3",
    email: "email3",
    postalCode: "postalCode3",
    address: "address3",
    latitude: 300.0001,
    longitude: 300.0002,
    artists: [
      {
        id: "ArtistId11",
        lastName: "LastName11",
        firstName: "FirstName11",
        lastNameKana: "LastNameKana11",
        firstNameKana: "FirstNameKana11",
        imageUrl:
          "https://placehold.jp/f4c095/000000/150x150.png?text=担当者11",
        dob: "2000-09-11",
        telephone: "Telphone11",
        email: "Email11",
        postalCode: "PostalCode11",
        address: "Address11",
      },
      {
        id: "ArtistId12",
        lastName: "LastName12",
        firstName: "FirstName12",
        lastNameKana: "LastNameKana12",
        firstNameKana: "FirstNameKana12",
        imageUrl:
          "https://placehold.jp/f4c095/000000/150x150.png?text=担当者12",
        dob: "2000-09-12",
        telephone: "Telphone12",
        email: "Email12",
        postalCode: "PostalCode12",
        address: "Address12",
      },
      {
        id: "ArtistId13",
        lastName: "LastName13",
        firstName: "FirstName13",
        lastNameKana: "LastNameKana13",
        firstNameKana: "FirstNameKana13",
        imageUrl:
          "https://placehold.jp/f4c095/000000/150x150.png?text=担当者13",
        dob: "2000-09-13",
        telephone: "Telphone13",
        email: "Email13",
        postalCode: "PostalCode13",
        address: "Address13",
      },
      {
        id: "ArtistId14",
        lastName: "LastName14",
        firstName: "FirstName14",
        lastNameKana: "LastNameKana14",
        firstNameKana: "FirstNameKana14",
        imageUrl:
          "https://placehold.jp/f4c095/000000/150x150.png?text=担当者14",
        dob: "2000-09-14",
        telephone: "Telphone14",
        email: "Email14",
        postalCode: "PostalCode14",
        address: "Address14",
      },
      {
        id: "ArtistId15",
        lastName: "LastName15",
        firstName: "FirstName15",
        lastNameKana: "LastNameKana15",
        firstNameKana: "FirstNameKana15",
        imageUrl:
          "https://placehold.jp/f4c095/000000/150x150.png?text=担当者15",
        dob: "2000-09-15",
        telephone: "Telphone15",
        email: "Email15",
        postalCode: "PostalCode15",
        address: "Address15",
      },
    ],
    menus: [
      {
        id: "MenuId11",
        name: "Menu Name 11",
        description: "Description 11",
        duration: 10,
        price: 1000,
        publishStatus: "published",
        imageUrl:
          "https://placehold.jp/6c596e/ffffff/150x150.png?text=メニュー11",
      },
      {
        id: "MenuId12",
        name: "Menu Name 12",
        description: "Description 12",
        duration: 20,
        price: 2000,
        publishStatus: "published",
        imageUrl:
          "https://placehold.jp/6c596e/ffffff/150x150.png?text=メニュー12",
      },
      {
        id: "MenuId13",
        name: "Menu Name 13",
        description: "Description 13",
        duration: 30,
        price: 3000,
        publishStatus: "published",
        imageUrl:
          "https://placehold.jp/6c596e/ffffff/150x150.png?text=メニュー13",
      },
      {
        id: "MenuId14",
        name: "Menu Name 14",
        description: "Description 14",
        duration: 40,
        price: 4000,
        publishStatus: "published",
        imageUrl:
          "https://placehold.jp/6c596e/ffffff/150x150.png?text=メニュー14",
      },
      {
        id: "MenuId15",
        name: "Menu Name 15",
        description: "Description 15",
        duration: 50,
        price: 5000,
        publishStatus: "published",
        imageUrl:
          "https://placehold.jp/6c596e/ffffff/150x150.png?text=メニュー15",
      },
    ],
  },
  {
    id: "SalonId4",
    imageUrl: "https://placehold.jp/1D7874/ffffff/150x150.png?text=美容室4",
    name: "Salon Name 4 AAAA",
    nameKana: "nameKana4",
    picLastName: "picLastName4",
    picFirstName: "picFirstName4",
    telephone: "telephone4",
    email: "email4",
    postalCode: "postalCode4",
    address: "address4",
    latitude: 400.0001,
    longitude: 400.0002,
    artists: [
      {
        id: "ArtistId16",
        lastName: "LastName16",
        firstName: "FirstName16",
        lastNameKana: "LastNameKana16",
        firstNameKana: "FirstNameKana16",
        imageUrl:
          "https://placehold.jp/f4c095/000000/150x150.png?text=担当者16",
        dob: "2000-09-16",
        telephone: "Telphone16",
        email: "Email16",
        postalCode: "PostalCode16",
        address: "Address16",
      },
      {
        id: "ArtistId17",
        lastName: "LastName17",
        firstName: "FirstName17",
        lastNameKana: "LastNameKana17",
        firstNameKana: "FirstNameKana17",
        imageUrl:
          "https://placehold.jp/f4c095/000000/150x150.png?text=担当者17",
        dob: "2000-09-17",
        telephone: "Telphone17",
        email: "Email17",
        postalCode: "PostalCode17",
        address: "Address17",
      },
      {
        id: "ArtistId18",
        lastName: "LastName18",
        firstName: "FirstName18",
        lastNameKana: "LastNameKana18",
        firstNameKana: "FirstNameKana18",
        imageUrl:
          "https://placehold.jp/f4c095/000000/150x150.png?text=担当者18",
        dob: "2000-09-18",
        telephone: "Telphone18",
        email: "Email18",
        postalCode: "PostalCode18",
        address: "Address18",
      },
      {
        id: "ArtistId19",
        lastName: "LastName19",
        firstName: "FirstName19",
        lastNameKana: "LastNameKana19",
        firstNameKana: "FirstNameKana19",
        imageUrl:
          "https://placehold.jp/f4c095/000000/150x150.png?text=担当者19",
        dob: "2000-09-19",
        telephone: "Telphone19",
        email: "Email19",
        postalCode: "PostalCode19",
        address: "Address19",
      },
      {
        id: "ArtistId20",
        lastName: "LastName20",
        firstName: "FirstName20",
        lastNameKana: "LastNameKana20",
        firstNameKana: "FirstNameKana20",
        imageUrl:
          "https://placehold.jp/f4c095/000000/150x150.png?text=担当者20",
        dob: "2000-09-20",
        telephone: "Telphone20",
        email: "Email20",
        postalCode: "PostalCode20",
        address: "Address20",
      },
    ],
    menus: [
      {
        id: "MenuId16",
        name: "Menu Name 16",
        description: "Description 16",
        duration: 10,
        price: 1000,
        publishStatus: "published",
        imageUrl:
          "https://placehold.jp/6c596e/ffffff/150x150.png?text=メニュー16",
      },
      {
        id: "MenuId17",
        name: "Menu Name 17",
        description: "Description 17",
        duration: 20,
        price: 2000,
        publishStatus: "published",
        imageUrl:
          "https://placehold.jp/6c596e/ffffff/150x150.png?text=メニュー17",
      },
      {
        id: "MenuId18",
        name: "Menu Name 18",
        description: "Description 18",
        duration: 30,
        price: 3000,
        publishStatus: "published",
        imageUrl:
          "https://placehold.jp/6c596e/ffffff/150x150.png?text=メニュー18",
      },
      {
        id: "MenuId19",
        name: "Menu Name 19",
        description: "Description 19",
        duration: 40,
        price: 4000,
        publishStatus: "published",
        imageUrl:
          "https://placehold.jp/6c596e/ffffff/150x150.png?text=メニュー19",
      },
      {
        id: "MenuId20",
        name: "Menu Name 20",
        description: "Description 20",
        duration: 50,
        price: 5000,
        publishStatus: "published",
        imageUrl:
          "https://placehold.jp/6c596e/ffffff/150x150.png?text=メニュー20",
      },
    ],
  },
  {
    id: "SalonId5",
    imageUrl: "https://placehold.jp/1D7874/ffffff/150x150.png?text=美容室5",
    name: "Salon Name 5 AAAA",
    nameKana: "nameKana5",
    picLastName: "picLastName5",
    picFirstName: "picFirstName5",
    telephone: "telephone5",
    email: "email5",
    postalCode: "postalCode5",
    address: "address5",
    latitude: 500.0001,
    longitude: 500.0002,
    artists: [
      {
        id: "ArtistId21",
        lastName: "LastName21",
        firstName: "FirstName21",
        lastNameKana: "LastNameKana21",
        firstNameKana: "FirstNameKana21",
        imageUrl:
          "https://placehold.jp/f4c095/000000/150x150.png?text=担当者21",
        dob: "2000-09-21",
        telephone: "Telphone21",
        email: "Email21",
        postalCode: "PostalCode21",
        address: "Address21",
      },
      {
        id: "ArtistId22",
        lastName: "LastName22",
        firstName: "FirstName22",
        lastNameKana: "LastNameKana22",
        firstNameKana: "FirstNameKana22",
        imageUrl:
          "https://placehold.jp/f4c095/000000/150x150.png?text=担当者22",
        dob: "2000-09-22",
        telephone: "Telphone22",
        email: "Email22",
        postalCode: "PostalCode22",
        address: "Address22",
      },
      {
        id: "ArtistId23",
        lastName: "LastName23",
        firstName: "FirstName23",
        lastNameKana: "LastNameKana23",
        firstNameKana: "FirstNameKana23",
        imageUrl:
          "https://placehold.jp/f4c095/000000/150x150.png?text=担当者23",
        dob: "2000-09-23",
        telephone: "Telphone23",
        email: "Email23",
        postalCode: "PostalCode23",
        address: "Address23",
      },
      {
        id: "ArtistId24",
        lastName: "LastName24",
        firstName: "FirstName24",
        lastNameKana: "LastNameKana24",
        firstNameKana: "FirstNameKana24",
        imageUrl:
          "https://placehold.jp/f4c095/000000/150x150.png?text=担当者24",
        dob: "2000-09-24",
        telephone: "Telphone24",
        email: "Email24",
        postalCode: "PostalCode24",
        address: "Address24",
      },
      {
        id: "ArtistId25",
        lastName: "LastName25",
        firstName: "FirstName25",
        lastNameKana: "LastNameKana25",
        firstNameKana: "FirstNameKana25",
        imageUrl:
          "https://placehold.jp/f4c095/000000/150x150.png?text=担当者25",
        dob: "2000-09-25",
        telephone: "Telphone25",
        email: "Email25",
        postalCode: "PostalCode25",
        address: "Address25",
      },
    ],
    menus: [
      {
        id: "MenuId21",
        name: "Menu Name 21",
        description: "Description 21",
        duration: 10,
        price: 1000,
        publishStatus: "published",
        imageUrl:
          "https://placehold.jp/6c596e/ffffff/150x150.png?text=メニュー21",
      },
      {
        id: "MenuId22",
        name: "Menu Name 22",
        description: "Description 22",
        duration: 20,
        price: 2000,
        publishStatus: "published",
        imageUrl:
          "https://placehold.jp/6c596e/ffffff/150x150.png?text=メニュー22",
      },
      {
        id: "MenuId23",
        name: "Menu Name 23",
        description: "Description 23",
        duration: 30,
        price: 3000,
        publishStatus: "published",
        imageUrl:
          "https://placehold.jp/6c596e/ffffff/150x150.png?text=メニュー23",
      },
      {
        id: "MenuId24",
        name: "Menu Name 24",
        description: "Description 24",
        duration: 40,
        price: 4000,
        publishStatus: "published",
        imageUrl:
          "https://placehold.jp/6c596e/ffffff/150x150.png?text=メニュー24",
      },
      {
        id: "MenuId25",
        name: "Menu Name 25",
        description: "Description 25",
        duration: 50,
        price: 5000,
        publishStatus: "published",
        imageUrl:
          "https://placehold.jp/6c596e/ffffff/150x150.png?text=メニュー25",
      },
    ],
  },
];

export const fake_customer_info = {
  id: 1,
  firstName: "firstName",
  lastName: "lastName",
  firstNameKana: "firstNameKana",
  lastNameKana: "lastNameKana",
  dateOfBirth: "8/22/2023",
  phoneNumber1: "+12345678910",
  phoneNumber2: "+24681357900",
  emailAddress: "emailAddress@gmail.com",
  address1: "1-1 Kasumigaokamachi, Shinjuku City, Tokyo 160-0013, Japan",
  address2: "1-1 Kasumigaokamachi, Shinjuku City, Tokyo 160-0013, Japan",
};

export const fake_category_with_service = [
  {
    id: 1,
    title: "シャンプー",

    services: [
      {
        id: 1,
        title: "Service 1 シャンプー",
        price: 500,
        estimatedServiceTime: 60, // minutes
      },
      {
        id: 2,
        title: "Service 2 シャンプー",
        price: 1000,
        estimatedServiceTime: 120, // minutes
      },
      {
        id: 3,
        title: "Service 3 シャンプー",
        price: 2000,
        estimatedServiceTime: 180, // minutes
      },
      {
        id: 4,
        title: "Service 4 シャンプー",
        price: 3000,
        estimatedServiceTime: 180, // minutes
      },
    ],
  },
  {
    id: 2,
    title: "トリートメント",

    services: [
      {
        id: 5,
        title: "Service 1 トリートメント",
        price: 500,
        estimatedServiceTime: 60, // minutes
      },
      {
        id: 6,
        title: "Service 2 トリートメント",
        price: 1000,
        estimatedServiceTime: 120, // minutes
      },
      {
        id: 7,
        title: "Service 3 トリートメント",
        price: 2000,
        estimatedServiceTime: 180, // minutes
      },
      {
        id: 8,
        title: "Service 4 トリートメント",
        price: 3000,
        estimatedServiceTime: 180, // minutes
      },
    ],
  },
  {
    id: 3,
    title: "カット",

    services: [
      {
        id: 9,
        title: "Service 1 カット",
        price: 500,
        estimatedServiceTime: 60, // minutes
      },
      {
        id: 10,
        title: "Service 2 カット",
        price: 1000,
        estimatedServiceTime: 120, // minutes
      },
      {
        id: 11,
        title: "Service 3 カット",
        price: 2000,
        estimatedServiceTime: 180, // minutes
      },
      {
        id: 12,
        title: "Service 4 カット",
        price: 3000,
        estimatedServiceTime: 180, // minutes
      },
    ],
  },
  {
    id: 4,
    title: "パーマ",

    services: [
      {
        id: 13,
        title: "Service 1 パーマ",
        price: 500,
        estimatedServiceTime: 60, // minutes
      },
      {
        id: 14,
        title: "Service 2 パーマ",
        price: 1000,
        estimatedServiceTime: 120, // minutes
      },
      {
        id: 15,
        title: "Service 3 パーマ",
        price: 2000,
        estimatedServiceTime: 180, // minutes
      },
      {
        id: 16,
        title: "Service 4 パーマ",
        price: 3000,
        estimatedServiceTime: 180, // minutes
      },
    ],
  },
  {
    id: 5,
    title: "シャンプー",

    services: [
      {
        id: 17,
        title: "Service 1 シャンプー",
        price: 500,
        estimatedServiceTime: 60, // minutes
      },
      {
        id: 18,
        title: "Service 2 シャンプー",
        price: 1000,
        estimatedServiceTime: 120, // minutes
      },
      {
        id: 19,
        title: "Service 3 シャンプー",
        price: 2000,
        estimatedServiceTime: 180, // minutes
      },
      {
        id: 20,
        title: "Service 4 シャンプー",
        price: 3000,
        estimatedServiceTime: 180, // minutes
      },
    ],
  },
];

export const fake_revervation_info = {
  customerInfo: {
    id: 1,
    firstName: "firstName",
    lastName: "lastName",
    firstNameKana: "firstNameKana",
    lastNameKana: "lastNameKana",
    dateOfBirth: "8/22/2023",
    phoneNumber1: "+12345678910",
    phoneNumber2: "+24681357900",
    emailAddress: "emailAddress@gmail.com",
    address1: "1-1 Kasumigaokamachi, Shinjuku City, Tokyo 160-0013, Japan",
    address2: "1-1 Kasumigaokamachi, Shinjuku City, Tokyo 160-0013, Japan",
  },
  counselingInfo: {
    counselingSheetField1: "",
    counselingSheetField2: "",
    counselingSheetField3: "",
  },
  servicesList: [
    {
      category: {
        id: 1,
        title: "シャンプー",
      },
      service: {
        id: 1,
        title: "Service 1 シャンプー",
        price: 500,
        estimatedServiceTime: 60, // minutes
      },
    },
    {
      category: {
        id: 2,
        title: "トリートメント",
      },
      service: {
        id: 7,
        title: "Service 3 トリートメント",
        price: 2000,
        estimatedServiceTime: 180, // minutes
      },
    },
    {
      category: {
        id: 3,
        title: "カット",
      },
      service: {
        id: 12,
        title: "Service 4 カット",
        price: 3000,
        estimatedServiceTime: 180, // minutes
      },
    },
    {
      category: {
        id: 4,
        title: "パーマ",
      },
      service: {
        id: 15,
        title: "Service 3 パーマ",
        price: 2000,
        estimatedServiceTime: 180, // minutes
      },
    },
    {
      category: {
        id: 5,
        title: "シャンプー",
      },
      service: {
        id: 19,
        title: "Service 3 シャンプー",
        price: 2000,
        estimatedServiceTime: 180, // minutes
      },
    },
  ],
  startTime: "2023-08-22T09:07:50.797Z",
  estimatedEndTime: "2023-08-22T09:07:50.797Z",
};

export const fake_counseling_sheet_list = [
  {
    id: 1,
    createdAt: "2023年8月9日（水） 11:00",
    summary:
      "料ッの本容ワユヱヘ告族サネカ身投エモコ減3切角ルヘ慌所ぼ社性道べずね読真ぴご期篠希ふ均推軽ばフ。",
    content:
      "料ッの本容ワユヱヘ告族サネカ身投エモコ減3切角ルヘ慌所ぼ社性道べずね読真ぴご期篠希ふ均推軽ばフ。工ふん国宮ヨ松27認レメウハ行方フツマユ純意ち円場お整申見ウノ果集ンゅに寛度レフ閣台カヒエメ月惑トき要村じーへわ必難囲り。自がけだ転手ネナソ父津天イラツ午民ぐ投意ナ属真ムルエ性宿86促縮2議トて喩維ユツ届独善伯堤墜らも。",
  },
  {
    id: 2,
    createdAt: "2023-10-23T06:42:35.935Z",
    summary:
      "半セ会経牧傾りむも紙著ヲモセ滅質るれ員羽割と身資わイ災1極ち欧先コ症樹ラチ介近ぱょ練渡23議3後互む。",
    content:
      "半セ会経牧傾りむも紙著ヲモセ滅質るれ員羽割と身資わイ災1極ち欧先コ症樹ラチ介近ぱょ練渡23議3後互む。議イ索無ヘエマ室3業ぎま土津み減供トエナニ件並めリり木注かゅぴ氏急もしス載書けめょべ王際しにぶっ時侃凸だ。業モア録投のそめ川通れづッ基購皇と身康ア見次ホセロフ話回サオソヒ女線待無い陸意文モユセル下変クヲミノ町文エロノテ式成づどゅら職人ワカ質勢土工丹んぜのか。",
  },
  {
    id: 3,
    createdAt: "2023-12-23T06:42:35.935Z",
    summary:
      "教えせあフ想線81尾八さにら転年ラ也更ヒ変販そ告前かせき暗伊れろぐ賄資ネイ容半ヨワケ写3東えッ氏海モノニ識台リだ県科だ講駐せぎラび無曽膨柴熊ひッてン。",
    content:
      "教えせあフ想線81尾八さにら転年ラ也更ヒ変販そ告前かせき暗伊れろぐ賄資ネイ容半ヨワケ写3東えッ氏海モノニ識台リだ県科だ講駐せぎラび無曽膨柴熊ひッてン。香トめ手提降ムトエ米給がはらあ読練料ソユヘ代議ヨル木57高幹圧秘はごーぴ真訓誘略犯へ。準わと属最スクヤ度条ウヒケ賞育イネカ旅掲ク光務さ写十ノモメ月世テヲノメ完声あリろ香言旧ち完通ぱ指著ク会2院クル。",
  },
  {
    id: 4,
    createdAt: "2023-13-23T06:42:35.935Z",
    summary:
      "玉ムフ川睦シエヘ定銭うろょど指案ムオヒ遺人ぎ提登よ霊保キ争更レ座関モヘワロ備点ニロオソ込軽いぐぞ録際も更政ニ仕傾督ヲ展勝とルしね盛38形越極86昇ちイス。",
    content:
      "玉ムフ川睦シエヘ定銭うろょど指案ムオヒ遺人ぎ提登よ霊保キ争更レ座関モヘワロ備点ニロオソ込軽いぐぞ録際も更政ニ仕傾督ヲ展勝とルしね盛38形越極86昇ちイス。協するび写生健要ム新開がよ本読トミイテ祭立げ住57経26人やんうイ特備予案レシコヨ週会せク宿主ン電内やょほ問木現謝専ほ。  ",
  },
  {
    id: 5,
    createdAt: "2023-14-23T06:42:35.935Z",
    summary:
      "惚ごぼ間投ちゅぞ教館伝ほぼづー通及ル向嶺ユハモホ渉割ヤワラツ世勝済ぼ育19随ぎフ集校ざ詳属覧ま好実きこ覧激みへ。",
    content:
      "惚ごぼ間投ちゅぞ教館伝ほぼづー通及ル向嶺ユハモホ渉割ヤワラツ世勝済ぼ育19随ぎフ集校ざ詳属覧ま好実きこ覧激みへ。選ヒウ天風1容カエヱ締9天セクメウ位克ぴゅンえ川人ぼ度禁かー若督ナワヌタ穴持ノ常7姿フ。接コホ更吉れほ慎刊とゆス創京用テ意始ぼづレ碁容指だぼし護状ツ論能ノヱレツ図引ケ実白っスたふ済本ッ与弾元ホ勝受つらべ昇謝俊町ルハラカ条2旗昼でぎさつ。",
  },
];

export const fake_visit_history_list = [
  {
    id: 1,
    createdAt: "2023年8月9日（水） 11:00",
    personinCharge: {
      id: 1,
      name: "test",
    },
    servicesList: [
      {
        category: {
          id: 1,
          title: "シャンプー",
        },
        service: {
          id: 1,
          title: "Service 1 シャンプー",
          price: 500,
          estimatedServiceTime: 60, // minutes
        },
      },
      {
        category: {
          id: 2,
          title: "トリートメント",
        },
        service: {
          id: 7,
          title: "Service 3 トリートメント",
          price: 2000,
          estimatedServiceTime: 180, // minutes
        },
      },
      {
        category: {
          id: 3,
          title: "カット",
        },
        service: {
          id: 12,
          title: "Service 4 カット",
          price: 3000,
          estimatedServiceTime: 180, // minutes
        },
      },
      {
        category: {
          id: 4,
          title: "パーマ",
        },
        service: {
          id: 15,
          title: "Service 3 パーマ",
          price: 2000,
          estimatedServiceTime: 180, // minutes
        },
      },
      {
        category: {
          id: 5,
          title: "シャンプー",
        },
        service: {
          id: 19,
          title: "Service 3 シャンプー",
          price: 2000,
          estimatedServiceTime: 180, // minutes
        },
      },
    ],
  },
  {
    id: 2,
    createdAt: "2023年8月9日（水） 11:00",
    personinCharge: {
      id: 1,
      name: "test",
    },
    servicesList: [
      {
        category: {
          id: 1,
          title: "シャンプー",
        },
        service: {
          id: 1,
          title: "Service 1 シャンプー",
          price: 500,
          estimatedServiceTime: 60, // minutes
        },
      },
      {
        category: {
          id: 2,
          title: "トリートメント",
        },
        service: {
          id: 7,
          title: "Service 3 トリートメント",
          price: 2000,
          estimatedServiceTime: 180, // minutes
        },
      },
      {
        category: {
          id: 3,
          title: "カット",
        },
        service: {
          id: 12,
          title: "Service 4 カット",
          price: 3000,
          estimatedServiceTime: 180, // minutes
        },
      },
      {
        category: {
          id: 4,
          title: "パーマ",
        },
        service: {
          id: 15,
          title: "Service 3 パーマ",
          price: 2000,
          estimatedServiceTime: 180, // minutes
        },
      },
      {
        category: {
          id: 5,
          title: "シャンプー",
        },
        service: {
          id: 19,
          title: "Service 3 シャンプー",
          price: 2000,
          estimatedServiceTime: 180, // minutes
        },
      },
    ],
  },
  {
    id: 3,
    createdAt: "2023年8月9日（水） 11:00",
    personinCharge: {
      id: 1,
      name: "test",
    },
    servicesList: [
      {
        category: {
          id: 1,
          title: "シャンプー",
        },
        service: {
          id: 1,
          title: "Service 1 シャンプー",
          price: 500,
          estimatedServiceTime: 60, // minutes
        },
      },
      {
        category: {
          id: 2,
          title: "トリートメント",
        },
        service: {
          id: 7,
          title: "Service 3 トリートメント",
          price: 2000,
          estimatedServiceTime: 180, // minutes
        },
      },
      {
        category: {
          id: 3,
          title: "カット",
        },
        service: {
          id: 12,
          title: "Service 4 カット",
          price: 3000,
          estimatedServiceTime: 180, // minutes
        },
      },
      {
        category: {
          id: 4,
          title: "パーマ",
        },
        service: {
          id: 15,
          title: "Service 3 パーマ",
          price: 2000,
          estimatedServiceTime: 180, // minutes
        },
      },
      {
        category: {
          id: 5,
          title: "シャンプー",
        },
        service: {
          id: 19,
          title: "Service 3 シャンプー",
          price: 2000,
          estimatedServiceTime: 180, // minutes
        },
      },
    ],
  },
  {
    id: 4,
    createdAt: "2023年8月9日（水） 11:00",
    personinCharge: {
      id: 1,
      name: "test",
    },
    servicesList: [
      {
        category: {
          id: 1,
          title: "シャンプー",
        },
        service: {
          id: 1,
          title: "Service 1 シャンプー",
          price: 500,
          estimatedServiceTime: 60, // minutes
        },
      },
      {
        category: {
          id: 2,
          title: "トリートメント",
        },
        service: {
          id: 7,
          title: "Service 3 トリートメント",
          price: 2000,
          estimatedServiceTime: 180, // minutes
        },
      },
      {
        category: {
          id: 3,
          title: "カット",
        },
        service: {
          id: 12,
          title: "Service 4 カット",
          price: 3000,
          estimatedServiceTime: 180, // minutes
        },
      },
      {
        category: {
          id: 4,
          title: "パーマ",
        },
        service: {
          id: 15,
          title: "Service 3 パーマ",
          price: 2000,
          estimatedServiceTime: 180, // minutes
        },
      },
      {
        category: {
          id: 5,
          title: "シャンプー",
        },
        service: {
          id: 19,
          title: "Service 3 シャンプー",
          price: 2000,
          estimatedServiceTime: 180, // minutes
        },
      },
    ],
  },
  {
    id: 5,
    createdAt: "2023年8月9日（水） 11:00",
    personinCharge: {
      id: 1,
      name: "test",
    },
    servicesList: [
      {
        category: {
          id: 1,
          title: "シャンプー",
        },
        service: {
          id: 1,
          title: "Service 1 シャンプー",
          price: 500,
          estimatedServiceTime: 60, // minutes
        },
      },
      {
        category: {
          id: 2,
          title: "トリートメント",
        },
        service: {
          id: 7,
          title: "Service 3 トリートメント",
          price: 2000,
          estimatedServiceTime: 180, // minutes
        },
      },
      {
        category: {
          id: 3,
          title: "カット",
        },
        service: {
          id: 12,
          title: "Service 4 カット",
          price: 3000,
          estimatedServiceTime: 180, // minutes
        },
      },
      {
        category: {
          id: 4,
          title: "パーマ",
        },
        service: {
          id: 15,
          title: "Service 3 パーマ",
          price: 2000,
          estimatedServiceTime: 180, // minutes
        },
      },
      {
        category: {
          id: 5,
          title: "シャンプー",
        },
        service: {
          id: 19,
          title: "Service 3 シャンプー",
          price: 2000,
          estimatedServiceTime: 180, // minutes
        },
      },
    ],
  },
];

export const fake_recommends = [
  {
    id: "RecommendId1",
    imageUrl: "https://placehold.jp/1D7874/ffffff/150x150.png?text=美容室1",
    image: "image1",
    productName: "Product 1 AAA",
    productDescription: "Description for product1",
    price: "1000",
    category: "pretty",
  },
  {
    id: "RecommendId2",
    imageUrl: "https://placehold.jp/1D7874/ffffff/150x150.png?text=美容室1",
    image: "image2",
    productName: "Product 2 BBB",
    productDescription: "Description for product2",
    price: "2000",
    category: "pretty",
  },
  {
    id: "RecommendId3",
    imageUrl: "https://placehold.jp/1D7874/ffffff/150x150.png?text=美容室1",
    image: "image3",
    productName: "Product 3 AAA",
    productDescription: "Description for product3",
    price: "3000",
    category: "pretty",
  },
  {
    id: "RecommendId4",
    imageUrl: "https://placehold.jp/1D7874/ffffff/150x150.png?text=美容室1",
    image: "image4",
    productName: "Product 4 BBB",
    productDescription: "Description for product4",
    price: "4000",
    category: "pretty",
  },
];

export const fake_DMHistories = [
  {
    id: "DMId1",
    category: "customer",
    targetName: "Jeff",
    deliveryDate: "2023-09-14",
    title: "Title A",
    content: "Content A",
    status: "Schedule",
  },
  {
    id: "DMId2",
    category: "customer",
    targetName: "Eason",
    deliveryDate: "2023-09-15",
    title: "Title B",
    content: "Content B",
    status: "Done",
  },
  {
    id: "DMId3",
    category: "salon",
    targetName: "Nick",
    deliveryDate: "2023-09-16",
    title: "Title C",
    content: "Content C",
    status: "Schedule",
  },
  {
    id: "DMId4",
    category: "customer",
    targetName: "Eric",
    deliveryDate: "2023-09-20",
    title: "Title D",
    content: "Content D",
    status: "Schedule",
  },
];

export const fake_contractors = [
  {
    id: "ContractorId1",
    contractorType: "agency",
    companyName: "ファースト会社A",
    companyNameFurigana: "ファーストカイシャA",
    leaderLastName: "Jeff",
    leaderFirstName: "robin",
    phoneNumber: "08000000000",
    email: "emailA@gmail.com",
    address: "address 1",
  },
  {
    id: "ContractorId2",
    contractorType: "outsourcing",
    companyName: "ファースト会社B",
    companyNameFurigana: "ファーストカイシャB",
    leaderLastName: "Jeffer",
    leaderFirstName: "robin",
    phoneNumber: "08000000001",
    email: "emailB@gmail.com",
    address: "address 2",
  },
  {
    id: "ContractorId3",
    contractorType: "agency",
    companyName: "ファースト会社C",
    companyNameFurigana: "ファーストカイシャC",
    leaderLastName: "Jeffie",
    leaderFirstName: "robin",
    phoneNumber: "08000000002",
    email: "emailC@gmail.com",
    address: "address 3",
  },
  {
    id: "ContractorId4",
    contractorType: "outsourcing",
    companyName: "ファースト会社D",
    companyNameFurigana: "ファーストカイシャD",
    leaderLastName: "Jeffery",
    leaderFirstName: "robin",
    phoneNumber: "08000000003",
    email: "emailD@gmail.com",
    address: "address 4",
  },
];

export const fake_employees = [
  {
    id: "EmployeeId1",
    employeeType: "manager",
    employeeNumber: "0001",
    affiliation: "A Department",
    accessPrivilege: "管理",
    employeeLastName: "Jeff",
    employeeFirstName: "Robin",
    employeeLastNameFurigana: "JEFF",
    employeeFirstNameFurigana: "ROBIN",
    birthday: "2023-10-20",
    phoneNumber: "08000000000",
    email: "emailA@gmail.com",
    address: "address 1",
  },
  {
    id: "EmployeeId2",
    employeeType: "staff",
    employeeNumber: "0002",
    affiliation: "A Department",
    accessPrivilege: "一般",
    employeeLastName: "Jason",
    employeeFirstName: "Walker",
    employeeLastNameFurigana: "JASON",
    employeeFirstNameFurigana: "WALKER",
    birthday: "2023-10-21",
    phoneNumber: "08000000001",
    email: "emailB@gmail.com",
    address: "address 2",
  },
  {
    id: "EmployeeId3",
    employeeType: "manager",
    employeeNumber: "0003",
    affiliation: "B Department",
    accessPrivilege: "管理",
    employeeLastName: "Fick",
    employeeFirstName: "First",
    employeeLastNameFurigana: "FICK",
    employeeFirstNameFurigana: "FIRST",
    birthday: "2023-10-22",
    phoneNumber: "08000000002",
    email: "emailC@gmail.com",
    address: "address 3",
  },
  {
    id: "EmployeeId4",
    employeeType: "staff",
    employeeNumber: "0004",
    affiliation: "B Department",
    accessPrivilege: "一般",
    employeeLastName: "Old",
    employeeFirstName: "Nick",
    employeeLastNameFurigana: "OLD",
    employeeFirstNameFurigana: "NICK",
    birthday: "2023-10-23",
    phoneNumber: "08000000003",
    email: "emailD@gmail.com",
    address: "address 4",
  },
];

export const fake_contracts = [
  {
    id: "ContractId1",
    contractStoreName: "A Store",
    createDate: "2023-10-20",
    sendDate: "2023-10-21",
    replyValidExpiryDate: "2023-10-22",
    effectiveDate: "2023-10-25",
    status: "Creating",
  },
  {
    id: "ContractId2",
    contractStoreName: "B Store",
    createDate: "2023-10-20",
    sendDate: "2023-10-21",
    replyValidExpiryDate: "2023-10-22",
    effectiveDate: "2023-10-25",
    status: "Await",
  },
  {
    id: "ContractId3",
    contractStoreName: "C Store",
    createDate: "2023-10-20",
    sendDate: "2023-10-21",
    replyValidExpiryDate: "2023-10-22",
    effectiveDate: "2023-10-25",
    status: "Expired",
  },
  {
    id: "ContractId4",
    contractStoreName: "D Store",
    createDate: "2023-10-20",
    sendDate: "2023-10-21",
    replyValidExpiryDate: "2023-10-22",
    effectiveDate: "2023-10-25",
    status: "Completed",
  },
  {
    id: "ContractId5",
    contractStoreName: "E Store",
    createDate: "2023-10-20",
    sendDate: "2023-10-21",
    replyValidExpiryDate: "2023-10-22",
    effectiveDate: "2023-10-25",
    status: "Rejected",
  },
];

export const fake_salesOrders = [
  {
    id: "Id1",
    orderTimeStamp: "2023-10-20",
    orderId: "order id 1",
    analyseStatus: "分析中",
  },
  {
    id: "Id2",
    orderTimeStamp: "2023-10-21",
    orderId: "order id 2",
    analyseStatus: "分析終了",
  },
  {
    id: "Id3",
    orderTimeStamp: "2023-10-22",
    orderId: "order id 3",
    analyseStatus: "分析開始前",
  },
  {
    id: "Id4",
    orderTimeStamp: "2023-10-23",
    orderId: "order id 14",
    analyseStatus: "分析中",
  },
];

export const fake_examinationResults = [
  {
    id: "Id1",
    examinationResultId: "result id 1",
    cusId: "CustomerId 1",
    kitId: "kitId 1",
    cluster: "cluster 1",
    cellArea: "100",
    multipleDelamination: "multipleDelamination 1",
    cp: "CP 1",
    general: "general 1",
    registerDate: "2023-10-20",
    analyseStatus: "done",
  },
  {
    id: "Id2",
    examinationResultId: "result id 2",
    cusId: "CustomerId 2",
    kitId: "kitId 2",
    cluster: "cluster 2",
    cellArea: "200",
    multipleDelamination: "multipleDelamination 2",
    cp: "CP 2",
    general: "general 2",
    registerDate: "2023-10-20",
    analyseStatus: "done",
  },
  {
    id: "Id3",
    examinationResultId: "result id 3",
    cusId: "CustomerId 3",
    kitId: "kitId 3",
    cluster: "cluster 3",
    cellArea: "300",
    multipleDelamination: "multipleDelamination 3",
    cp: "CP 3",
    general: "general 3",
    registerDate: "2023-10-21",
    analyseStatus: "inprogress",
  },
  {
    id: "Id4",
    examinationResultId: "result id 4",
    cusId: "CustomerId 4",
    kitId: "kitId 4",
    cluster: "cluster 4",
    cellArea: "400",
    multipleDelamination: "multipleDelamination 4",
    cp: "CP 4",
    general: "general 4",
    registerDate: "2023-10-21",
    analyseStatus: "inprogress",
  },
];
