import * as React from "react";
import "dayjs/locale/ja";
import { Autocomplete, TextField } from "@mui/material";

function AutocompleteInput(props) {
  const {
    value,
    onChange,
    disabled,
    className,
    options,
    getOptionLabel,
    isOptionEqualToValue,
    ...otherProps
  } = props;

  return (
    <Autocomplete
      value={value}
      className={className}
      onChange={onChange}
      disabled={disabled}
      options={options}
      renderInput={(params) => <TextField {...params} />}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={isOptionEqualToValue}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.id}>
            {option.name}
          </li>
        );
      }}
      sx={{
        "& .MuiInputBase-input": {
          padding: "6px 16px !important",
          lineHeight: "24px !important",
          height: "24px !important",
          fontSize: "16px",
        },
        "& .Mui-disabled": {
          backgroundColor: "#f3f4f6 !important",
        },
        "& input.Mui-disabled ": {
          color: "#000 !important",
          "-webkit-text-fill-color": "#000 !important",
        },
      }}
      {...otherProps}
    />
  );
}

export default AutocompleteInput;
