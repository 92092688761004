import React from "react";
import { useNavigate } from "react-router-dom";
import MainMenuItem from "app/components/MainMenuItem";
import Path from "app/route/Path";

function ExaminationDataListMenuItem(props) {
  const navigate = useNavigate();

  const onMenuItemClicked = () => {
    navigate(Path.examination.list);
  };

  return (
    <MainMenuItem
      itemTitleString="検査データを\n確認する"
      onClick={onMenuItemClicked}
      size="100%"
    />
  );
}

export default ExaminationDataListMenuItem;
