import React from "react";

function NotFound() {
  return (
    <div>
      404 ページを見つけられませんでした。
      <a href="/">ホーム</a>へ
    </div>
  );
}

export default NotFound;
