import ObjectHelper from "./ObjectHelper";
import encodePath from "./encodePath";

const exportObjs = {
  Object: ObjectHelper,
  EncodePath: encodePath,
};

export default exportObjs;

export const synchronizeClientParams = (currentParams, states = {}) => {
  const paramsStr = Object?.keys(states)
    ?.filter((key) => {
      return states[key];
    })
    ?.map((key) => {
      return `${key}=${states[key]}`;
    })
    ?.join("&&");
  window && window.history.replaceState(null, "", paramsStr ? currentParams + "?" + paramsStr : currentParams);
};

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
};

export const validatePassword = (password) => {
  // 8 char length && 1 letter && 1 number
  var re = /^(?=.*[a-z])(?=.*\d).{8,}$/im;
  return re.test(password);
}