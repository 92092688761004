import React from "react";
import { useParams } from "react-router-dom";
import DefaultLayout from "app/layouts/DefaultLayout";
import Drc from "app/components/Drc";

function ExaminationResultCreatePage(props) {
  const { stepId } = useParams();

  return (
    <DefaultLayout
      headerProps={{
        headerClassName: "md:!block",
        pageTitle: "検査データ新規登録",
      }}
      showNotice={false}
    >
      <Drc.ExaminationResult.Form stepId={+stepId} />
    </DefaultLayout>
  );
}

export default ExaminationResultCreatePage;
