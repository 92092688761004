import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import dayjs from "dayjs";
import Path from "app/route/Path";
import StepsBar from "app/components/common/StepsBar";
import Input from "app/components/common/Input";
import Button from "app/components/common/Button";
import Label from "app/components/common/Label";
import Panel from "app/components/common/Panel";
import Form from "app/components/common/Form";
import { examinationDatas } from "../../services/api";
import BackBtn from "../BackButton";

function ExaminationDataForm(props) {
  const { isEdit, stepId, children } = props;
  const examinationDataItem = children;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const steps = [
    { name: "検査データ入力" },
    { name: "入力確認" },
    { name: "入力完了" },
  ];

  const navigate = useNavigate();

  const {
    handleSubmit,
    // handleChange,
    // errors,
    values,
    setFieldValue,
    // resetForm,
    // touched,
    // handleBlur,
  } = useFormik({
    initialValues: {
      cusId: isEdit ? examinationDataItem.cusId : "",
      kitId: isEdit ? examinationDataItem.kitId : "",
      cluster: isEdit ? examinationDataItem.cluster : "",
      cellArea: isEdit ? examinationDataItem.cellArea : "",
      peeling: isEdit ? examinationDataItem.peeling : "",
      cp: isEdit ? examinationDataItem.cp : "",
      registerDate: isEdit
        ? dayjs(examinationDataItem.registerDate, "YYYY-MM-DD")
        : dayjs(),
      overall: isEdit ? examinationDataItem.overall : "",
      analyseStatus: isEdit ? examinationDataItem.analyseStatus : "",
    },
    onSubmit: (values) => {
      setIsSubmitting(true);
      if (isEdit) {
        let payload = {
          analyseStatus: values.analyseStatus,
          factorCellArea: Number(values.cellArea),
          factorCluster: Number(values.cluster),
          factorCP: Number(values.cp),
          customerNo: values.cusId,
          inspectionKitId: values.kitId,
          factorScore: Number(values.overall),
          factorPeeling: Number(values.peeling),
          analyseDate: values.registerDate.toISOString(),
          inspectionDate: examinationDataItem.inspectionDate,
          name: examinationDataItem.name,
        };

        return examinationDatas
          .update({
            examinationDataId: examinationDataItem._id,
            payload,
          })
          .then((res) => {
            gotoNextStep();
            setIsSubmitting(false);
          })
          .catch((e) => {
            setIsSubmitting(false);
            alert(e);
          })
          .finally(() => {
            setIsSubmitting(false);
          });
      }

      let payload = {
        factorCellArea: Number(values.cellArea),
        factorCluster: Number(values.cluster),
        factorCP: Number(values.cp),
        customerNo: values.cusId,
        inspectionKitId: values.kitId,
        factorScore: Number(values.overall),
        factorPeeling: Number(values.peeling),
        analyseDate: values.registerDate.toISOString(),
      };

      return examinationDatas
        .store(payload)
        .then((res) => {
          gotoNextStep();
          setIsSubmitting(false);
        })
        .catch((e) => {
          setIsSubmitting(false);
          alert(e);
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    },
  });

  const gotoPrevStep = () => {
    isEdit
      ? navigate(Path.examination.edit(examinationDataItem.id, stepId - 1))
      : navigate(Path.examination.create(stepId - 1));
  };

  const gotoNextStep = () => {
    isEdit
      ? navigate(Path.examination.edit(examinationDataItem.id, stepId + 1))
      : navigate(Path.examination.create(stepId + 1));
  };

  const step1IsValid = () => {
    if (!values.cusId) {
      return false;
    }
    if (!values.kitId) {
      return false;
    }
    if (!values.cluster) {
      return false;
    }
    if (!values.cellArea) {
      return false;
    }
    if (!values.peeling) {
      return false;
    }
    if (!values.cp) {
      return false;
    }
    if (!values.overall) {
      return false;
    }
    if (!values.registerDate) {
      return false;
    }

    return true;
  };

  const examinationDataFinished = () => {
    navigate(Path.examination.list);
  };

  const renderByStepIndex = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <>
            <Form.Row>
              <Form.Col>お客様ID</Form.Col>
              <Form.ColGrow>
                <Input.Field
                  name="cusId"
                  type="text"
                  className={isEdit ? "w-full opacity-50" : "w-full"}
                  value={values.cusId}
                  setValue={(newValue) => {
                    setFieldValue("cusId", newValue);
                  }}
                  disabled={isEdit}
                ></Input.Field>
              </Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>キットID</Form.Col>
              <Form.ColGrow>
                <Input.Field
                  name="kitId"
                  type="text"
                  className={isEdit ? "w-full opacity-50" : "w-full"}
                  value={values.kitId}
                  setValue={(newValue) => {
                    setFieldValue("kitId", newValue);
                  }}
                  disabled={isEdit}
                ></Input.Field>
              </Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>クラスター</Form.Col>
              <Form.ColGrow>
                <Input.Field
                  name="cluster"
                  type="text"
                  className="w-full"
                  value={values.cluster}
                  setValue={(newValue) => {
                    setFieldValue("cluster", newValue);
                  }}
                ></Input.Field>
              </Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>細胞面積</Form.Col>
              <Form.ColGrow>
                <Input.Field
                  name="cellArea"
                  type="text"
                  className="w-full"
                  value={values.cellArea}
                  setValue={(newValue) => {
                    setFieldValue("cellArea", newValue);
                  }}
                ></Input.Field>
              </Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>多重剥離</Form.Col>
              <Form.ColGrow>
                <Input.Field
                  name="peeling"
                  type="text"
                  className="w-full"
                  value={values.peeling}
                  setValue={(newValue) => {
                    setFieldValue("peeling", newValue);
                  }}
                ></Input.Field>
              </Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>CP</Form.Col>
              <Form.ColGrow>
                <Input.Field
                  name="cp"
                  type="text"
                  className="w-full"
                  value={values.cp}
                  setValue={(newValue) => {
                    setFieldValue("cp", newValue);
                  }}
                ></Input.Field>
              </Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>総合</Form.Col>
              <Form.ColGrow>
                <Input.Field
                  name="overall"
                  type="text"
                  className="w-full"
                  value={values.overall}
                  setValue={(newValue) => {
                    setFieldValue("overall", newValue);
                  }}
                ></Input.Field>
              </Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>登録日時</Form.Col>
              <Form.ColGrow>
                <Input.DatePicker
                  name="registerDate"
                  className={isEdit ? "w-full opacity-50" : "w-full"}
                  value={values.registerDate}
                  setValue={(newValue) => {
                    setFieldValue("registerDate", newValue);
                  }}
                  disabled={isEdit}
                  disablePast={!isEdit}
                  disableFuture={isEdit}
                ></Input.DatePicker>
              </Form.ColGrow>
            </Form.Row>
            <Panel.Justify className="!sticky !bottom-0 w-full bg-white p-2">
              <BackBtn url={Path.examination.list}/>
              <Button.Primary onClick={gotoNextStep} disabled={!step1IsValid()}>
                次へ
              </Button.Primary>
            </Panel.Justify>
          </>
        );
      case 1:
        return (
          <div className="mx-2 space-y-4">
            <Form.Row>
              <Form.Col>お客様ID</Form.Col>
              <Form.ColGrow>{values.cusId}</Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>キットID</Form.Col>
              <Form.ColGrow>{values.kitId}</Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>クラスター</Form.Col>
              <Form.ColGrow>{values.cluster}</Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>細胞面積</Form.Col>
              <Form.ColGrow>{values.cellArea}</Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>多重剥離</Form.Col>
              <Form.ColGrow>{values.peeling}</Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>CP</Form.Col>
              <Form.ColGrow>{values.cp}</Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>総合</Form.Col>
              <Form.ColGrow>{values.overall}</Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>登録日時</Form.Col>
              <Form.ColGrow>
                <Label.DateTime dateFormat="YYYY-MM-DD">
                  {values.registerDate}
                </Label.DateTime>
              </Form.ColGrow>
            </Form.Row>
            <Panel.Justify className="!sticky !bottom-0 w-full bg-white p-2">
              <Button onClick={gotoPrevStep}>戻る</Button>
              <Button.Primary type="submit" loading={isSubmitting}>
                {isEdit ? "検査データを編集する" : "検査データを登録する"}
              </Button.Primary>
            </Panel.Justify>
          </div>
        );

      case 2:
        return (
          <div className="text-center space-y-4 mx-2">
            <Form.Row>
              <Form.ColGrow>
                {isEdit ? "検査データを編集完了" : "検査デーを登録完了"}
              </Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.ColGrow>
                <Button.Primary onClick={examinationDataFinished}>
                  完了
                </Button.Primary>
              </Form.ColGrow>
            </Form.Row>
          </div>
        );

      default:
        return <></>;
    }
  };

  return (
    <>
      <StepsBar steps={steps} currentStepIndex={stepId} />
      <Form.Form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
        {renderByStepIndex(stepId)}
      </Form.Form>
    </>
  );
}

export default ExaminationDataForm;
