export const defaultDateFormat = "YYYY/MM/DD";
export const defaultDateTimeFormat = "YYYY/MM/DD HH:mm";
export const SCALP_LAB_ACCOUNT_TYPE = {
  MANAGER: 1,
  STAFF: 2,
  AGENT: 3,
  SUBCONTRACTOR: 4,
  DRC: 5,
};

export const SCALP_LAB_ACCOUNT_STATUS = {
  ACTIVE: 1,
  INACTIVE: 0,
}

export const SCALP_LAB_ACCOUNT_TEXT = {
  1: 'manager',
  2: 'staff',
};

export const ACCOUNT_STATUS = [
  { name: "Active", value: 1, key: "status_1" },
  { name: "Inactive", value: 0 , key: "status_0" },
];

export const DEFAULT_TOTAL_PER_PAGE = 10;