import React from "react";
import DefaultLayout from "app/layouts/DefaultLayout";
import Employee from "app/components/SystemSetting/Employee";
import MainMenuItem from "app/components/MainMenuItem";
import BackBtn from "../../components/BackButton";
import Path from "app/route/Path";

function SystemSettingHomePage() {
  return (
    <DefaultLayout
      headerProps={{
        headerClassName: "md:!block",
        // pageTitle: "ホーム",
      }}
    >
      <Employee.MenuGrid>
        <div className="flex items-center justify-center aspect-square w-full h-full p-[10%]">
          {<MainMenuItem.SystemSetting.Manager.Create />}
        </div>
        <div className="flex items-center justify-center aspect-square w-full h-full p-[10%]">
          {<MainMenuItem.SystemSetting.Manager.List />}
        </div>
        <div className="flex items-center justify-center aspect-square w-full h-full p-[10%]">
          {<MainMenuItem.SystemSetting.Staff.Create />}
        </div>
        <div className="flex items-center justify-center aspect-square w-full h-full p-[10%]">
          {<MainMenuItem.SystemSetting.Staff.List />}
        </div>
        <div className="flex items-center justify-center aspect-square w-full h-full p-[10%]">
          {<MainMenuItem.SystemSetting.SharedInfoLevel />}
        </div>
      </Employee.MenuGrid>
      <BackBtn url={Path.home}/>
    </DefaultLayout>
  );
}

export default SystemSettingHomePage;
