import React, { useEffect } from "react";
import GoogleMapReact from "google-map-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { useRef } from "react";

export default function GoogleMapComponent({ geoCode, setGeoCode, center, setCenter, mapRef }) {

  useEffect(() => {
    mapRef?.current?.panTo(geoCode);
  },[])

  const MarkerGeoCode = () => (
    <div className="w-6 h-6 flex items-center justify-center -translate-y-4">
      <FontAwesomeIcon icon={faLocationDot} size="xl" color="red" />
    </div>
  );

  return (
    <>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "",
        }}
        defaultCenter={center}
        defaultZoom={15}
        onChange={(e) => {}}
        onClick={(e) => {
          setGeoCode({
            lat: e?.lat,
            lng: e?.lng,
          });
          setCenter({
            lat: e?.lat,
            lng: e?.lng,
          });
          mapRef?.current?.panTo({
            lat: e?.lat,
            lng: e?.lng,
          });
        }}
        onGoogleApiLoaded={({ map, ...rest }) => (mapRef.current = map)}>
        {geoCode ? <MarkerGeoCode lat={geoCode?.lat} lng={geoCode?.lng} text="My Marker" /> : null}
      </GoogleMapReact>
    </>
  );
}
