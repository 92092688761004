import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import DefaultLayout from "app/layouts/DefaultLayout";
import ExaminationData from "app/components/ExaminationData";

function ExaminationDataCreatePage(props) {
  const { stepId } = useParams();

  return (
    <DefaultLayout
      headerProps={{
        headerClassName: "md:!block",
        pageTitle: "検査データ新規登録",
      }}
      showNotice={false}
    >
      <ExaminationData.Form stepId={+stepId} />
    </DefaultLayout>
  );
}

export default ExaminationDataCreatePage;
