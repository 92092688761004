import React from "react";

function KitMenuGrid(props) {
  const { children } = props;

  return (
    <div className="w-full h-full overflow-auto">
      <div className="w-full grid grid-cols-1 xs:grid-cols-2 md:grid-cols-3">
        {children}
      </div>
    </div>
  );
}

export default KitMenuGrid;
