import * as React from 'react';
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import {createSearchParams, useNavigate} from "react-router-dom";
import {useEffect} from "react"

export default function PaginationRounded({ pageRoute, currentPage, queryConfig, totalPage }) {
  const [page, setPage] = React.useState(Number(currentPage));
  const navigate = useNavigate()

  const handleChange = (_, value) => {
    navigate({
      pathname: pageRoute ? pageRoute : "",
      search: createSearchParams({
        ...queryConfig,
        page: value.toString(),
      }).toString(),
    });
  };

  useEffect(() => {
    if(currentPage) setPage(Number(currentPage))
  }, [currentPage])

  return (
    <Stack spacing={2}>
      <Pagination sx={{ display: "flex", justifyContent: "center", paddingTop: "2%", paddingBottom: "2%" }} count={totalPage} page={page} onChange={handleChange} />
    </Stack>
  );
}
