import React, { forwardRef, useRef } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import Path from "app/route/Path";
import StepsBar from "app/components/common/StepsBar";
import Input from "app/components/common/Input";
import Button from "app/components/common/Button";
import Panel from "app/components/common/Panel";
import Form from "app/components/common/Form";
import { articles, images } from "../../../services/api";
import { getTruthyImageFileType } from "../../../utils";
import { Box } from "@mui/system";
import defaultThumpnail from "app/assets/images/default-thumbnail.jpg";
import { v4 as uuidv4 } from "uuid";
import encodePath from "../../../helper/encodePath";
import BackBtn from "../../BackButton";

function ArticleForm(props) {
  const { isEdit, stepId, children } = props;
  const article = children;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [thumbPre, setThumbPre] = useState(defaultThumpnail);
  const [imgs, setImgs] = useState([]);
  const [fileList, setFileList] = useState();
  const [fileThumb, setFileThumb] = useState();

  const steps = [
    { name: "記事内容を入力する" },
    { name: "入力確認" },
    { name: "入力完了" },
  ];

  var createActionMessage = "記事内容を登録する";
  var editActionMessage = "記事内容を修正する";
  var createFinishMessage = "記事内容を登録しました";
  var editFinishMessage = "記事内容を修正しました";

  const navigate = useNavigate();

  const {
    handleSubmit,
    // handleChange,
    // errors,
    values,
    setFieldValue,
    // resetForm,
    // touched,
    // handleBlur,
  } = useFormik({
    initialValues: {
      // id: isEdit ? article.id : "",
      title: isEdit ? article.title : "",
      content: isEdit ? article.content : "",
    },
    onSubmit: async (values) => {
      setIsSubmitting(true);

      if (isEdit) {
        return;
      }

      if(Boolean(fileThumb)) {
        let formData = new FormData();
        formData.append("file", fileThumb);
        await images
          .upload(formData)
          .then((res) => {
            values.thumbnailFile = {
              fileUrl: encodePath(res.data.result),
              fileType: fileThumb.type,
              fileSize: fileThumb.size,
            };
          })
          .catch((e) => {
            alert("Upload Image Fail");
            return;
          });
      }

      if(Boolean(fileList)) {
        let formDatas = new FormData();

        fileList.map((item) => {
          formDatas.append("files", item);
        });

        await images
          .uploads(formDatas)
          .then((res) => {
            console.log("Ressss", res.data.result);
            values.articleFiles = [];

            res.data.result.map((item, index) => {
              values.articleFiles.push({
                fileUrl: encodePath(item),
                fileType: fileList[index].type,
                fileSize: fileList[index].size,
              });
            });
          })
          .catch((e) => {
            alert("Upload Image Fail");
            return;
          });
      }

      return articles
        .create(values)
        .then((res) => {
          gotoNextStep();
          setIsSubmitting(false);
        })
        .catch((e) => {
          alert("Error");
          setIsSubmitting(false);
        });
    },
  });

  const gotoPrevStep = () => {
    isEdit
      ? navigate(Path.salon.manage.article.edit(article.id, stepId - 1))
      : navigate(Path.salon.manage.article(stepId - 1));
  };

  const gotoNextStep = () => {
    isEdit
      ? navigate(Path.salon.manage.article(article.id, stepId + 1))
      : navigate(Path.salon.manage.article(stepId + 1));
  };

  const step1IsValid = () => {
    if (!values.title) {
      return false;
    }
    if (!values.content) {
      return false;
    }

    return true;
  };

  const articleCreateFinished = () => {
    navigate(Path.salon.manage.home);
  };

  const handleDeleteImg = (e) => {
    setImgs(
      imgs.filter((item) => item.key !== e.target.getAttribute("data-value"))
    );

    setFileList(
      Array.from(fileList).filter(
        (item) => item.key !== e.target.getAttribute("data-value")
      )
    );
  };

  const renderByStepIndex = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <>
            <Form.Row>
              <Form.Col>タイトル</Form.Col>
              <Form.ColGrow>
                <Input.Field
                  name="title"
                  type="text"
                  className="w-full"
                  value={values.title}
                  setValue={(newValue) => {
                    setFieldValue("title", newValue);
                  }}
                ></Input.Field>
              </Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>本文</Form.Col>
              <Form.ColGrow>
                <Input.MultilineField
                  name="content"
                  type="text"
                  className="w-full"
                  value={values.content}
                  setValue={(newValue) => {
                    setFieldValue("content", newValue);
                  }}
                ></Input.MultilineField>
              </Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>Thumbnail</Form.Col>
              <Form.ColGrow>
                <Box
                  component="img"
                  sx={{
                    height: 233,
                    width: 350,
                    maxHeight: { xs: 233, md: 167 },
                    maxWidth: { xs: 350, md: 250 },
                    objectFit: "contain",
                  }}
                  className="mb-5"
                  alt="Thumbnail preview"
                  src={thumbPre}
                />
                <label
                  htmlFor="fileInput"
                  className="rounded border-solid border-2 border-black p-1 cursor-pointer bg-slate-100"
                >
                  Choose Files
                </label>
                <Input.Field
                  id="fileInput"
                  name="thumbnail"
                  type="file"
                  className="w-full hidden"
                  onChange={handleChangeThumb}
                ></Input.Field>
              </Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>Images</Form.Col>
              <Form.ColGrow>
                {imgs &&
                  imgs.map((file) => (
                    <Box className="flex items-center" key={file.key}>
                      <Box
                        component="img"
                        sx={{
                          height: 233,
                          width: 350,
                          maxHeight: { xs: 233, md: 167 },
                          maxWidth: { xs: 350, md: 250 },
                          objectFit: "contain",
                        }}
                        className="mb-5 mr-3"
                        alt="Preview for image"
                        src={file.url}
                      />
                      <Button
                        className="w-3"
                        variant="contained"
                        onClick={handleDeleteImg}
                        data-value={file.key}
                        color="error"
                      >
                        Delete
                      </Button>
                    </Box>
                  ))}
                <label
                  htmlFor="filesInput"
                  className="rounded border-solid border-2 border-black p-1 cursor-pointer bg-slate-100"
                >
                  Choose Files
                </label>
                <Input.Field
                  id="filesInput"
                  name="images"
                  type="file"
                  className="hidden"
                  onChange={handleChangeImgs}
                  multiple
                ></Input.Field>
              </Form.ColGrow>
            </Form.Row>

            <Panel.Justify className="!sticky !bottom-0 w-full bg-white p-2">
              <BackBtn url={Path.salon.manage.home}/>
              <Button.Primary onClick={gotoNextStep} disabled={!step1IsValid()}>
                次へ
              </Button.Primary>
            </Panel.Justify>
          </>
        );
      case 1:
        return (
          <div className="mx-2 space-y-4">
            <Form.Row>
              <Form.Col>タイトル</Form.Col>
              <Form.ColGrow>{values.title}</Form.ColGrow>
            </Form.Row>
            <Form.Row></Form.Row>
            <Form.Row>
              <Form.Col>本文</Form.Col>
              <Form.ColGrow>{values.content}</Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>Thumbnail</Form.Col>
              <Form.ColGrow>
                <Box
                  component="img"
                  sx={{
                    height: 233,
                    width: 350,
                    maxHeight: { xs: 233, md: 167 },
                    maxWidth: { xs: 350, md: 250 },
                    objectFit: "contain",
                  }}
                  className="mb-5"
                  alt="Thumbnail preview"
                  src={thumbPre}
                />
              </Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>Images</Form.Col>
              <Form.ColGrow>
                {imgs &&
                  imgs.map((file) => (
                    <Box className="flex items-center" key={file.key}>
                      <Box
                        component="img"
                        sx={{
                          height: 233,
                          width: 350,
                          maxHeight: { xs: 233, md: 167 },
                          maxWidth: { xs: 350, md: 250 },
                          objectFit: "contain",
                        }}
                        className="mb-5 mr-3"
                        alt="Preview for image"
                        src={file.url}
                      />
                    </Box>
                  ))}
              </Form.ColGrow>
            </Form.Row>
            <Panel.Justify className="!sticky !bottom-0 w-full bg-white p-2">
              <Button onClick={gotoPrevStep}>戻る</Button>
              <Button.Primary type="submit" loading={isSubmitting}>
                {isEdit ? editActionMessage : createActionMessage}
              </Button.Primary>
            </Panel.Justify>
          </div>
        );

      case 2:
        return (
          <div className="text-center space-y-4 mx-2">
            <Form.Row>
              <Form.ColGrow>
                {isEdit ? editFinishMessage : createFinishMessage}
              </Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.ColGrow>
                <Button.Primary onClick={articleCreateFinished}>
                  完了
                </Button.Primary>
              </Form.ColGrow>
            </Form.Row>
          </div>
        );

      default:
        return <></>;
    }
  };

  const handleChangeThumb = (e) => {
    const thumb = e.target.files?.[0];
    if (!getTruthyImageFileType(thumb.type)) {
      return alert("File type not supported");
    }

    setThumbPre(URL.createObjectURL(thumb));
    setFileThumb(thumb);
  };

  const handleChangeImgs = (e) => {
    const selectedFIles = [];
    const targetFiles = e.target.files;
    const newTargetFiles = Array.from(targetFiles).map((item) => {
      item.key = uuidv4();
      return item;
    });
    const targetFilesObject = [...newTargetFiles];

    setFileList(targetFilesObject);

    targetFilesObject.map((file) => {
      return selectedFIles.push({
        url: URL.createObjectURL(file),
        key: file.key,
      });
    });

    setImgs([...imgs, ...selectedFIles]);

    e.target.value = null;
  };

  return (
    <>
      <StepsBar steps={steps} currentStepIndex={stepId} />
      <Form.Form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
        {renderByStepIndex(stepId)}
      </Form.Form>
    </>
  );
}

export default ArticleForm;
