import React from "react";
import { useParams } from "react-router-dom";
import DefaultLayout from "app/layouts/DefaultLayout";
import Kit from "app/components/Kit";

function KitOrderCreatePage(props) {
  const { stepId } = useParams();

  return (
    <DefaultLayout
      headerProps={{
        headerClassName: "md:!block",
        pageTitle: "発注登録",
      }}
      showNotice={false}
    >
      <Kit.Order.Form stepId={+stepId}></Kit.Order.Form>
    </DefaultLayout>
  );
}

export default KitOrderCreatePage;
