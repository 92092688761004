import homePage from "./homePage";
import createPage from "./createPage";
import listPage from "./listPage";
import detailPage from "./detailPage";
import editPage from "./editPage";
import sharedInfoLevelPage from "./sharedInfoLevelPage";

const exportObjs = {
  Home: homePage,
  SharedInfoLevel: sharedInfoLevelPage,
  Employee: {
    Create: createPage,
    List: listPage,
    Detail: detailPage,
    Edit: editPage,
  },
};

export default exportObjs;
