import React, { useMemo } from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import dayjs from "dayjs";
import Path from "app/route/Path";
import StepsBar from "app/components/common/StepsBar";
import Input from "app/components/common/Input";
import Button from "app/components/common/Button";
import Label from "app/components/common/Label";
import Panel from "app/components/common/Panel";
import Form from "app/components/common/Form";
import { recommends } from "app/services/api";
import { v4 as uuidv4 } from "uuid";
import logo from "app/assets/images/logo.png";
import Image from "app/components/Image";
import { getTruthyImageFileType } from "app/utils";
import CategorySelection from "app/components/CategorySelection"
import {getLeftUrl, getRightUrl} from "app/utils"
import BackBtn from "../BackButton";

function RecommendForm(props) {
  const { isEdit, stepId, children } = props;
  const recommendItem = children;

  const [isSubmitting, setIsSubmitting] = useState(false);
  // imageUrl state
  const [productPreviewImage, setProductPreviewImage] = useState();

  const steps = [
    { name: "検査データ入力" },
    { name: "入力確認" },
    { name: "入力完了" },
  ];

  const navigate = useNavigate();

  const {
    handleSubmit,
    // handleChange,
    // errors,
    values,
    setFieldValue,
    // resetForm,
    // touched,
    // handleBlur,
  } = useFormik({
    initialValues: {
      id: isEdit ? recommendItem?._id : "",
      name: isEdit ? recommendItem?.name : "",
      description: isEdit ? recommendItem?.description : "",
      imageUrl: isEdit ? recommendItem?.imageUrl : "",
      price: isEdit ? recommendItem?.price : "",
      category: isEdit ? recommendItem?.category : "",
      productScope: isEdit ? recommendItem?.productScope : "",
    },
    onSubmit: async (values) => {
      if (isEdit) {
        const editingRequestData = {
          // productNumber: isEdit ? recommendItem?.productNumber :  uuidv4().slice(0, 12),
          maker: recommendItem?.maker,
          series: recommendItem?.series,
          name: values.name,
          imageUrl: productPreviewImage,
          category: values.category,
          // price type must be number
          price: Number(values.price),
          description: values.description,
          productScope: values.productScope,
        };
        // edit product item
        const recommendItemId = recommendItem?._id;
        await recommends
          .updateProductItemDetailApi(editingRequestData, recommendItemId)
          .then(() => {
            gotoNextStep();
            setIsSubmitting(false);
          });
      } else {
        // requires: productNumber, maker, series, productScope
        const requestData = {
          productNumber: uuidv4().slice(0, 12),
          maker: uuidv4(),
          series: uuidv4(),
          name: values.name,
          imageUrl: productPreviewImage,
          category: values.category,
          // price type must be number
          price: Number(values.price),
          description: values.description,
          productScope: values.productScope,
        };
        // create product item - no id
        await recommends
          .createProductItemApi(requestData)
          .then(() => {
            gotoNextStep();
            setIsSubmitting(false);
          })
          .catch((error) => {
            const errorMessage = error?.response?.data?.error;
            if (errorMessage) alert(errorMessage);
          });
      }
    },
  });

  const gotoPrevStep = () => {
    isEdit
      ? navigate(Path.recommend.edit(recommendItem._id, stepId - 1))
      : navigate(Path.recommend.create(stepId - 1));
  };

  const gotoNextStep = () => {
    isEdit
      ? navigate(Path.recommend.edit(recommendItem?._id, stepId + 1))
      : navigate(Path.recommend.create(stepId + 1));
  };

  const step1IsValid = () => {
    if (!values.name) {
      return false;
    }
    if (!values.description) {
      return false;
    }
    // if (!values.imageUrl) {
    //   return false;
    // }
    if (!values.price) {
      return false;
    }
    if (!values.category) {
      return false;
    }
    return true;
  };

  const recommendFinished = () => {
    navigate(Path.recommend.list);
  };

  // choose image file from local
  const handleChangeProductPreviewImage = async (event) => {
    const previewProductImageLocalFile = event.target.files?.[0];
    const truthyImageFileType = getTruthyImageFileType(
      previewProductImageLocalFile?.type
    );
    if (truthyImageFileType) {
      const formData = new FormData();
      formData.append("file", previewProductImageLocalFile);
      await recommends.uploadProductImageApi(formData).then((data) => {
        const result = data.data.result;
        const leftUrl = getLeftUrl(result);
        const rightUrl = getRightUrl(result);
        const encodeURIRightUrl = encodeURIComponent(rightUrl);
        const encodeImageLink = `${leftUrl}/${encodeURIRightUrl}`;
        setFieldValue("imageUrl", encodeImageLink);
        setProductPreviewImage(encodeImageLink);
      });
    }
  };

  // set price method, input Number only
  const handleSetPrice = (event) => {
    const { value } = event.target;
    if (/^\d+$/.test(value) || value === "") {
      setFieldValue("price", value);
    }
  };

  // method manage category values
  const handleSetCategoriesValues = (categoryValue) => {
    setFieldValue("category", categoryValue);
  };

  // method manage product scope values
  const handleSetProductScopeValues = (productScopeValue) => {
    setFieldValue("productScope", productScopeValue);
  };

  useEffect(() => {
    const productItemImage = recommendItem?.imageUrl;
    if (productItemImage) setProductPreviewImage(productItemImage);
  }, []);

  // categories values
  const categoriesValues = [
    {
      id: 1,
      value: "shampoo",
    },
    {
      id: 2,
      value: "treatment",
    },
    {
      id: 3,
      value: "rinse",
    },
    {
      id: 4,
      value: "other",
    },
  ];

  // product scope values
  const productScopeValues = [
    {
      id: 1,
      value: "monopoly",
    },
    {
      id: 2,
      value: "general",
    },
  ];

  const renderByStepIndex = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <>
            <Form.Row>
              <Form.Col>商品名</Form.Col>
              <Form.ColGrow>
                <Input.Field
                  name="name"
                  type="text"
                  className="w-full"
                  value={values.name}
                  setValue={(newValue) => {
                    setFieldValue("name", newValue);
                  }}
                ></Input.Field>
              </Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>商品説明</Form.Col>
              <Form.ColGrow>
                <Input.MultilineField
                  name="description"
                  type="text"
                  className="w-full"
                  value={values.description}
                  setValue={(newValue) => {
                    setFieldValue("description", newValue);
                  }}
                ></Input.MultilineField>
              </Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>商品写真</Form.Col>
              <Form.ColGrow>
                <div>
                  <Image
                    src={productPreviewImage}
                    alt={"productPreviewImageUrl"}
                  />
                </div>
                <Input.Field
                  name="imageUrl"
                  type="file"
                  onChange={handleChangeProductPreviewImage}
                  className="w-full"
                  // value={values.imageUrl}
                  // setValue={(newValue) => {
                  //   setFieldValue("image", newValue);
                  // }}
                ></Input.Field>
              </Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>販売価格</Form.Col>
              <Form.ColGrow>
                <Input.Field
                  name="price"
                  type="text"
                  className="w-full"
                  onChange={(event) => handleSetPrice(event)}
                  value={values.price}
                  setValue={(newValue) => {
                    setFieldValue("price", newValue);
                  }}
                ></Input.Field>
              </Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>対応カテゴリ</Form.Col>
              <CategorySelection
                defaultValue={recommendItem?.category || "shampoo"}
                onChange={handleSetCategoriesValues}
                valuesArray={categoriesValues}
              />
            </Form.Row>
            <Form.Row>
              <Form.Col>製品範囲</Form.Col>
              <CategorySelection
                defaultValue={recommendItem?.productScope || "monopoly"}
                onChange={handleSetProductScopeValues}
                valuesArray={productScopeValues}
              />
            </Form.Row>
            <Panel.Justify className="!sticky !bottom-0 w-full bg-white p-2">
              <BackBtn url={Path.recommend.list}/>
              <Button.Primary onClick={gotoNextStep} disabled={!step1IsValid()}>
                次へ
              </Button.Primary>
            </Panel.Justify>
          </>
        );
      case 1:
        return (
          <div className="mx-2 space-y-4">
            <Form.Row>
              <Form.Col>商品名</Form.Col>
              <Form.ColGrow>{values.name}</Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>商品説明</Form.Col>
              <Form.ColGrow>{values.description}</Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>商品写真</Form.Col>
              <Image src={values.imageUrl} alt={values.name} />
            </Form.Row>
            <Form.Row>
              <Form.Col>販売価格</Form.Col>
              <Form.ColGrow>{values.price}</Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>対応カテゴリ</Form.Col>
              <Form.ColGrow>{values.category}</Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>製品範囲 (product scope)</Form.Col>
              <Form.ColGrow>{values.productScope}</Form.ColGrow>
            </Form.Row>
            <Panel.Justify className="!sticky !bottom-0 w-full bg-white p-2">
              <Button onClick={gotoPrevStep}>戻る</Button>
              <Button.Primary type="submit" loading={isSubmitting}>
                {isEdit ? "おすすめ商品を編集する" : "おすすめ商品を登録する"}
              </Button.Primary>
            </Panel.Justify>
          </div>
        );

      case 2:
        return (
          <div className="text-center space-y-4 mx-2">
            <Form.Row>
              <Form.ColGrow>
                {isEdit ? "おすすめ商品を編集完了" : "おすすめ商品を登録完了"}
              </Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.ColGrow>
                <Button.Primary onClick={recommendFinished}>
                  完了
                </Button.Primary>
              </Form.ColGrow>
            </Form.Row>
          </div>
        );

      default:
        return <></>;
    }
  };

  return (
    <>
      <StepsBar steps={steps} currentStepIndex={stepId} />
      <Form.Form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
        {renderByStepIndex(stepId)}
      </Form.Form>
    </>
  );
}

export default RecommendForm;
