import React from "react";
import Label from "app/components/common/Label";
import Button from "app/components/common/Button";

function KitStockHistoryTableItem(props) {
  const { children, onStockEditButtonClick } = props;
  const kitStockHistoryItem = children;

  const onStockEditButtonClicked = () => {
    onStockEditButtonClick(kitStockHistoryItem);
  };

  return (
    <tr className="border-b dark:border-neutral-500">
      <td className="whitespace-nowrap px-6 py-4 font-medium">
        <Label.DateTime dateFormat="YYYY-MM-DD">
          {kitStockHistoryItem.inStockDate}
        </Label.DateTime>
      </td>
      <td className="whitespace-nowrap px-6 py-4">
        {kitStockHistoryItem.amount}
      </td>
      <td className="whitespace-nowrap px-6 py-4">
        <p className="whitespace-pre-wrap">{kitStockHistoryItem.memo}</p>
      </td>
      <td className="whitespace-nowrap px-6 py-4">
        <Button.Primary onClick={onStockEditButtonClicked}>修正</Button.Primary>
      </td>
    </tr>
  );
}

export default KitStockHistoryTableItem;
