import React from "react";
import DefaultLayout from "app/layouts/DefaultLayout";
import DM from "app/components/DM";
import MainMenuItem from "app/components/MainMenuItem";
import BackBtn from "../../components/BackButton";
import Path from "app/route/Path";

function DMHomePage() {
  return (
    <DefaultLayout
      headerProps={{
        headerClassName: "md:!block",
        // pageTitle: "ホーム",
      }}
    >
      <DM.MenuGrid>
        <div className="flex items-center justify-center aspect-square w-full h-full p-[10%]">
          {<MainMenuItem.DM.Customer.Create />}
        </div>
        <div className="flex items-center justify-center aspect-square w-full h-full p-[10%]">
          {<MainMenuItem.DM.Customer.History />}
        </div>
        <div className="flex items-center justify-center aspect-square w-full h-full p-[10%]">
          {<MainMenuItem.DM.Salon.Create />}
        </div>
        <div className="flex items-center justify-center aspect-square w-full h-full p-[10%]">
          {<MainMenuItem.DM.Salon.History />}
        </div>
      </DM.MenuGrid>
      <BackBtn url={Path.home}/>
    </DefaultLayout>
  );
}

export default DMHomePage;
