import React from "react";
import { useState, useEffect } from "react";
import DefaultLayout from "app/layouts/DefaultLayout";
import { useNavigate } from "react-router-dom";
import Api from "app/services/api";
import Path from "app/route/Path";
import Kit from "app/components/Kit";
import Button from "app/components/common/Button";
import BackBtn from "../../../components/BackButton";

function KitRequestListPage() {
  const navigate = useNavigate();

  const [kitRequestHistories, setKitRequestHistories] = useState([]);
  const [kitRequestHistoriesLoaded, setKitRequestHistoriesLoaded] =
    useState(false);

  useEffect(() => {
    async function getAllKitRequestHistories() {
      const response = await Api.kit.order.requests.notDeliver();
      const items = response.data;
      setKitRequestHistories(items);
      setKitRequestHistoriesLoaded(true);
    }

    getAllKitRequestHistories();
  }, []);

  const onFulfillDeliverButtonClicked = (kitRequest) => {
    navigate(Path.kit.delivery.fulfill(kitRequest.id, 0));
  };

  const onCreateDeliveryButtonClicked = () => {
    navigate(Path.kit.delivery.create(0));
  };

  return (
    <DefaultLayout
      headerProps={{
        headerClassName: "md:!block",
        pageTitle: "発送登録・履歴",
      }}
    >
      <p>受注済　未発送リスト</p>
      <Button.Primary onClick={onCreateDeliveryButtonClicked}>
        新規登録
      </Button.Primary>
      <Kit.Request.Table.Table
        items={kitRequestHistories}
        loading={!kitRequestHistoriesLoaded}
      >
        <Kit.Request.Table.Loading>
          <tbody>
            <tr>
              <td colSpan="100">ローディング中</td>
            </tr>
          </tbody>
        </Kit.Request.Table.Loading>
        <Kit.Request.Table.Empty>
          キットの発注がないです。
        </Kit.Request.Table.Empty>
        <Kit.Request.Table.Header>
          <tr>
            <th scope="col" className="px-6 py-4">
              受注日時
            </th>
            <th scope="col" className="px-6 py-4">
              店舗No
            </th>
            <th scope="col" className="px-6 py-4">
              店舗名
            </th>
            <th scope="col" className="px-6 py-4">
              数量
            </th>
            <th scope="col" className="px-6 py-4">
              選択
            </th>
          </tr>
        </Kit.Request.Table.Header>
        <Kit.Request.Table.Items>
          {kitRequestHistories.map((item) => (
            <Kit.Request.Table.Item
              key={item.id}
              onDeliverButtonClick={onFulfillDeliverButtonClicked}
            >
              {item}
            </Kit.Request.Table.Item>
          ))}
        </Kit.Request.Table.Items>
      </Kit.Request.Table.Table>
      <BackBtn url={Path.kit.home}/>
    </DefaultLayout>
  );
}

export default KitRequestListPage;
