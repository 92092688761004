import React from "react";
import Form from "app/components/common/Form";
import Input from "app/components/common/Input";
import Button from "app/components/common/Button";
import Image from "app/components/Image";
import { useState } from "react";
import { getLeftUrl, getRightUrl, getTruthyImageFileType } from "app/utils";
import Api from "app/services/api";
import { useRef } from "react";
import { useEffect } from "react";

function SalonArtistForm(props) {
  const { values, setFieldValue, onProfileButtonClicked } = props;
  const [artistPreviewImage, setArtistPreviewImage] = useState();
  const fileInputRef = useRef();

  useEffect(() => {
    setArtistPreviewImage(values?.addArtist?.imageUrl);
  }, [values?.addArtist?.imageUrl]);

  const handleProfileButtonClicked = async (event) => {
    const previewProductImageLocalFile = event.target.files?.[0];
    const truthyImageFileType = getTruthyImageFileType(previewProductImageLocalFile?.type);
    if (truthyImageFileType) {
      const formData = new FormData();
      formData.append("file", previewProductImageLocalFile);
      await Api.uploadImageApi(formData).then((data) => {
        const result = data.data.result;
        const leftUrl = getLeftUrl(result);
        const rightUrl = getRightUrl(result);
        const encodeURIRightUrl = encodeURIComponent(rightUrl);
        const encodeImageLink = `${leftUrl}/${encodeURIRightUrl}`;
        setFieldValue("addArtist.imageUrl", encodeImageLink);
        setArtistPreviewImage(encodeImageLink);
      });
    }
  };

  return (
    <div className="space-y-2">
      <Form.Row>
        <Form.Col>氏名</Form.Col>
        <Form.ColGrow>
          <Form.Row className="space-x-2">
            <Form.Col className="basis-1 pr-2">姓</Form.Col>
            <Form.ColGrow>
              <Input.Field
                name="addArtist.lastName"
                type="text"
                className="w-full"
                value={values.addArtist.lastName}
                setValue={(newValue) => {
                  setFieldValue("addArtist.lastName", newValue);
                }}
              ></Input.Field>
            </Form.ColGrow>
            <Form.Col className="basis-1 pr-2">名</Form.Col>
            <Form.ColGrow>
              <Input.Field
                name="addArtist.firstName"
                type="text"
                className="w-full"
                value={values.addArtist.firstName}
                setValue={(newValue) => {
                  setFieldValue("addArtist.firstName", newValue);
                }}
              ></Input.Field>
            </Form.ColGrow>
          </Form.Row>
        </Form.ColGrow>
      </Form.Row>
      <Form.Row>
        <Form.Col>氏名（フリガナ）</Form.Col>
        <Form.ColGrow>
          <Form.Row className="space-x-2">
            <Form.Col className="basis-1 pr-2">セイ</Form.Col>
            <Form.ColGrow>
              <Input.Field
                name="addArtist.lastNameKana"
                type="text"
                className="w-full"
                value={values.addArtist.lastNameKana}
                setValue={(newValue) => {
                  setFieldValue("addArtist.lastNameKana", newValue);
                }}
              ></Input.Field>
            </Form.ColGrow>
            <Form.Col className="basis-1 pr-2">メイ</Form.Col>
            <Form.ColGrow>
              <Input.Field
                name="addArtist.firstNameKana"
                type="text"
                className="w-full"
                value={values.addArtist.firstNameKana}
                setValue={(newValue) => {
                  setFieldValue("addArtist.firstNameKana", newValue);
                }}
              ></Input.Field>
            </Form.ColGrow>
          </Form.Row>
        </Form.ColGrow>
      </Form.Row>
      <Form.Row>
        <Form.Col>プロファイル写真</Form.Col>
              <Form.ColGrow>
                <div>
                  <Image
                    src={artistPreviewImage}
                    alt={"artistPreviewImageUrl"}
                  />
                </div>
                <input 
                  name="imageUrl"
                  type="file"
                  onChange={handleProfileButtonClicked}
                  className="hidden"
                  ref={fileInputRef}
                  />
                <Form.Row>
                  <Button.Secondary onClick={()=>{
                    fileInputRef?.current?.click();
                  }}>
                    ファイルを選択
                  </Button.Secondary>
                </Form.Row>
              </Form.ColGrow>
      </Form.Row>
      <Form.Row>
        <Form.Col>生年月日</Form.Col>
        <Form.ColGrow>
          <Input.DatePicker
            name="dob"
            type="text"
            className="w-full"
            value={values.addArtist.dob}
            setValue={(newValue) => {
              setFieldValue("addArtist.dob", newValue);
            }}
          ></Input.DatePicker>
        </Form.ColGrow>
      </Form.Row>
      <Form.Row>
        <Form.Col>電話番号</Form.Col>
        <Form.ColGrow>
          <Input.Field
            name="telephone"
            type="text"
            className="w-full"
            value={values.addArtist.telephone}
            setValue={(newValue) => {
              setFieldValue("addArtist.telephone", newValue);
            }}
          ></Input.Field>
        </Form.ColGrow>
      </Form.Row>
      <Form.Row>
        <Form.Col>メールアドレス</Form.Col>
        <Form.ColGrow>
          <Input.Field
            name="email"
            type="text"
            className="w-full"
            value={values.addArtist.email}
            setValue={(newValue) => {
              setFieldValue("addArtist.email", newValue);
            }}
          ></Input.Field>
        </Form.ColGrow>
      </Form.Row>
      <Form.Row>
        <Form.Col>住所</Form.Col>
        <Form.ColGrow>
          <Input.Field
            name="postalCode"
            type="text"
            className="w-full"
            value={values.addArtist.postalCode}
            setValue={(newValue) => {
              setFieldValue("addArtist.postalCode", newValue);
            }}
          ></Input.Field>
        </Form.ColGrow>
      </Form.Row>
      <Form.Row>
        <Form.Col></Form.Col>
        <Form.ColGrow>
          <Input.Field
            name="address"
            type="text"
            className="w-full"
            value={values.addArtist.address}
            setValue={(newValue) => {
              setFieldValue("addArtist.address", newValue);
            }}
          ></Input.Field>
        </Form.ColGrow>
      </Form.Row>
      {!values?.addArtist?.id && (
        <>
          <Form.Row>
            <Form.Col>ユーザーネーム</Form.Col>
            <Form.ColGrow>
              <Input.Field
                name="loginName"
                type="text"
                className="w-full"
                value={values.loginName}
                setValue={(newValue) => {
                  setFieldValue("addArtist.loginName", newValue);
                }}></Input.Field>
            </Form.ColGrow>
          </Form.Row>
          <Form.Row>
            <Form.Col>パスワード</Form.Col>
            <Form.ColGrow>
              <Input.Field
                name="password"
                type="password"
                className="w-full"
                value={values.password}
                setValue={(newValue) => {
                  setFieldValue("addArtist.password", newValue);
                }}></Input.Field>
            </Form.ColGrow>
          </Form.Row>
        </>
      )}
    </div>
  );
}
export default SalonArtistForm;
