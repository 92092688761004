import DMCustTableItem from "./Customer/DMCustTableItem";
import DMCustForm from "./Customer/DMCustForm";
import DMDetail from "./DMDetail";
import DMGridItem from "./DMGridItem";
import Grid from "app/components/common/Grid";
import MainMenuGrid from "app/components/MainMenuGrid";

import Table from "app/components/common/Table";

const exportObjs = {
  MenuGrid: MainMenuGrid,
  Grid: {
    ...Grid,
    Item: DMGridItem,
  },
  Table: {
    ...Table,
    Item: DMCustTableItem,
  },
  Form: DMCustForm,
  Detail: DMDetail,
};

export default exportObjs;
