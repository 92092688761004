import DepositPendingTableItem from "./Deposit/DepositPendingTableItem";
import DepositDoneTableItem from "./Deposit/DepositDoneTableItem";
import DepositForm from "./Deposit/DepositForm";
import ManagementForm from "./Management/ManagementForm";
import ContactForm from "./Contact/ContactForm";
import ArticleForm from "./Article/ArticleForm";
import SalonMenuGridItem from "./Menu/SalonMenuGridItem";
import SalonMenuForm from "./Menu/SalonMenuForm";
import SalonMenuFormConfirm from "./Menu/SalonMenuFormConfirm";
import SalonArtistGridItem from "./Artist/SalonArtistGridItem";
import SalonArtistForm from "./Artist/SalonArtistForm";
import SalonArtistFormConfirm from "./Artist/SalonArtistFormConfirm";
import MainMenuGrid from "app/components/MainMenuGrid";
import SalonGridItem from "./SalonGridItem";
import SalonTableItem from "./SalonTableItem";
import SalonBasicInfoForm from "./SalonBasicInfoForm";
import SalonBasicInfoFormConfirm from "./SalonBasicInfoFormConfirm";
import Grid from "app/components/common/Grid";
import Table from "app/components/common/Table";

const exportObjs = {
  Deposit: {
    Table: {
      ...Table,
      PendingItem: DepositPendingTableItem,
      DoneItem: DepositDoneTableItem,
    },
    Form: DepositForm,
  },
  Manage: {
    Form: ManagementForm,
  },
  Contact: {
    Form: ContactForm,
  },
  Article: {
    Form: ArticleForm,
  },
  Menu: {
    Grid: {
      ...Grid,
      Item: SalonMenuGridItem,
    },
    Form: SalonMenuForm,
    FormConfirm: SalonMenuFormConfirm,
  },
  Artist: {
    Grid: {
      ...Grid,
      Item: SalonArtistGridItem,
    },
    Form: SalonArtistForm,
    FormConfirm: SalonArtistFormConfirm,
  },
  MenuGrid: MainMenuGrid,
  Grid: {
    ...Grid,
    Item: SalonGridItem,
  },
  Table: {
    ...Table,
    Item: SalonTableItem,
  },
  BasicInfoForm: SalonBasicInfoForm,
  BasicInfoFormConfirm: SalonBasicInfoFormConfirm,
};

export default exportObjs;
