import { useQueryParams } from "app/hooks";
import {useEffect, useState} from "react";
import omitBy from "lodash/omitBy";
import isUndefined from "lodash/isUndefined";

export default function useQueryConfig() {
  const queryParams = useQueryParams();
  const [result, setResult] = useState({})

  useEffect(() => {
    const queryConfig = omitBy({
      page: queryParams.page || "1",
      perPage: queryParams.perPage || "10",
      sort:  queryParams.sort || "1",
      staffNumber: queryParams.staffNumber ? queryParams.staffNumber : undefined,
      fullname: queryParams.fullname ? queryParams.fullname : undefined,
      staffTeam: queryParams.staffTeam ? queryParams.staffTeam : undefined,
      accessLevel: queryParams.accessLevel ? queryParams.accessLevel : undefined,
    }, isUndefined);
    setResult(queryConfig)
  }, [queryParams])

  return result;
}
