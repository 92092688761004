import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Popover from "@mui/material/Popover";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import { setAuthUser, setAuthToken } from "app/redux/authReducer";
import defaultAvatar from "app/assets/svg/default_avatar.svg";
import logo from "app/assets/images/logo.png";
import "./Header.css";

function Header(props) {
  const { headerClassName } = props;

  const [loggedInMenuAnchorEl, setLoggedInMenuAnchorEl] = useState(null);
  const dispatch = useDispatch();

  const openLoggedInMenu = (e) => {
    setLoggedInMenuAnchorEl(e.currentTarget);
  };

  const closeLoggedInMenu = () => {
    setLoggedInMenuAnchorEl(null);
  };

  const handleLogout = () => {
    closeLoggedInMenu();
    const token = {
      accessToken: null,
      refreshToken: null,
    };
    dispatch(setAuthUser(null));
    dispatch(setAuthToken(token));
  };

  const isDrc = useSelector((state) => state.auth.user?.isDrc);

  return (
    <>
      <div
        className={`sticky py-2 top-0 inset-x-0 z-20 w-full h-16 md:h-20 border-b border-gray-200 bg-white ${
          headerClassName || ""
        }`}
        style={{ height: 47 }}
      >
        <div className="w-full h-full px-6 flex items-center justify-between">
          <div className="flex-grow flex items-center justify-start">
            <Link to="/" className="flex items-center">
              <img className="h-7 lg:h-8 mr-4" src={logo} alt="Logo" />

              <h2 className="text-base sm:text-xl hidden xs:!block">
                {isDrc ? "頭皮ラボ　DRCシステム" : "頭皮ラボ　頭皮ラボシステム"}
              </h2>
            </Link>
          </div>
          {/* <div className="w-1/3 h-12 border border-[#dddddd] rounded-full shadow-[0_4px_12px_4px_rgba(0,0,0,0.088)] relative py-1 pl-12 pr-3">
            <div className="absolute top-1/2 left-1 -translate-y-1/2 w-10 h-10 p-1 rounded-full bg-gray-200">
              <SearchIcon classes={{ root: "!text-[32px]" }} />
            </div>
            <input
              className="w-full h-full !outline-none text px-1"
              placeholder="検索窓..."
            />
          </div> */}
          <div className="flex flex-1 items-center justify-end min-w-0">
            <button
              // type="button"
              className="ml-6 flex items-center border rounded-full p-1 hover:shadow-md"
              // style={{ "-webkit-appearance": "none" }}
              onClick={openLoggedInMenu}
            >
              <div className="relative w-[30px] h-[30px] rounded-full bg-white">
                <img
                  src={defaultAvatar}
                  alt="no-avatar"
                  className="w-full h-full rounded-full overflow-hidden"
                  onError={(event) => {
                    event.target.src = defaultAvatar;
                  }}
                />
              </div>
            </button>
          </div>
        </div>
      </div>

      <Popover
        open={Boolean(loggedInMenuAnchorEl)}
        anchorEl={loggedInMenuAnchorEl}
        onClose={closeLoggedInMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          classes: {
            root: "w-[210px] md:w-[220px] !rounded-xl !translate-y-2",
          },
        }}
      >
        <MenuList>
          <MenuItem classes={{ root: "!py-1" }} onClick={handleLogout}>
            <p className="">Logout</p>
          </MenuItem>
        </MenuList>
      </Popover>
    </>
  );
}

export default Header;
