import React from "react";

function MainMenuGrid(props) {
  const { children } = props;

  return (
    <div className="w-full h-full overflow-auto">
      <div className="w-full grid grid-cols-1 xs:grid-cols-2 sm:grid-cols-3 md:grid-cols-5">
        {children}
      </div>
    </div>
  );
}

export default MainMenuGrid;
