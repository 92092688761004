import React from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import dayjs from "dayjs";
import Path from "app/route/Path";
import StepsBar from "app/components/common/StepsBar";
import Input from "app/components/common/Input";
import Button from "app/components/common/Button";
import Label from "app/components/common/Label";
import Panel from "app/components/common/Panel";
import Form from "app/components/common/Form";
import Api from "app/services/api";
import { DEPOSIT_STATUS } from "app/pages/Salon/Deposit/depositManagementPage";
import BackBtn from "../../BackButton";


function DepositForm(props) {
  const { isEdit, isPrefilled, stepId, salonId, children } = props;
  const { invoiceNumber } = useParams();
  const depositItem = children;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const steps = [
    { name: "入金登録" },
    { name: "入力確認" },
    { name: "入力完了" },
  ];

  const navigate = useNavigate();

  const {
    handleSubmit,
    // handleChange,
    // errors,
    values,
    setFieldValue,
    // resetForm,
    // touched,
    // handleBlur,
  } = useFormik({
    initialValues: {
      paymentDate: isEdit || isPrefilled ? dayjs(depositItem?.paymentDate) : dayjs(),
      paymentNumber: isEdit || isPrefilled ? depositItem?.paymentNumber : "",
      paymentTitle: isEdit || isPrefilled ? depositItem?.paymentTitle : "",
      paymentAccount: isEdit || isPrefilled ? depositItem?.paymentAccount : "",
      paymentAmount: isEdit || isPrefilled ? depositItem?.paymentAmount : 0,
      memo: isEdit || isPrefilled ? depositItem?.memo : "",
    },
    onSubmit: (values) => {
      const commonFormatPayload ={
        ...values,
        paymentDate:dayjs(values?.paymentDate).format()
      }
      //       depositDate_paymentDate
      //       depositNumber_paymentNumber
      //       title_paymentTitle
      //       depositAccount_paymentAccount
      //       depositAmount_paymentAmount
      //       memo_memo

      const defaultCallback = () => {
        gotoNextStep();
        setIsSubmitting(false);
      };

      setIsSubmitting(true);
      if (isPrefilled) {
        Api.deposits.edit({
          id: invoiceNumber,
          payload: { ...commonFormatPayload, status: DEPOSIT_STATUS.DONE, salonId },
          callbackSuccess: () => {
            defaultCallback();
          },
          callbackFailed: () => {
            defaultCallback();
          },
        });
      } else {
        if (isEdit) {
          Api.deposits.edit({
            id: invoiceNumber,
            payload: { ...commonFormatPayload, status: depositItem?.status, salonId },
            callbackSuccess: () => {
              defaultCallback();
            },
            callbackFailed: () => {
              defaultCallback();
            },
          });
        } else {
          Api.deposits.create({
            payload: { ...commonFormatPayload, status: DEPOSIT_STATUS.PENDING, salonId },
            callbackSuccess: () => {
              defaultCallback();
            },
            callbackFailed: () => {
              defaultCallback();
            },
          });
        }
      }
    },
  });

  const gotoPrevStep = () => {
    isEdit
      ? navigate(
          Path.salon.deposit.edit(
            depositItem?.salonId,
            depositItem?._id,
            stepId - 1
          )
        )
      : isPrefilled
      ? navigate(
          Path.salon.deposit.fulfill(
            depositItem?.salonId,
            depositItem?._id,
            stepId - 1
          )
        )
      : navigate(Path.salon.deposit.create(salonId, stepId - 1));
  };

  const gotoNextStep = () => {
    isEdit
      ? navigate(
          Path.salon.deposit.edit(
            depositItem?.salonId,
            depositItem?._id,
            stepId + 1
          )
        )
      : isPrefilled
      ? navigate(
          Path.salon.deposit.fulfill(
            depositItem?.salonId,
            depositItem?._id,
            stepId + 1
          )
        )
      : navigate(Path.salon.deposit.create(salonId, stepId + 1));
  };

  const step1IsValid = () => {
    if (!values.paymentDate) {
      return false;
    }
    if (!values.paymentNumber) {
      return false;
    }
    if (!values.paymentTitle) {
      return false;
    }
    if (!values.paymentAccount) {
      return false;
    }
    if (!values.paymentAmount) {
      return false;
    }
    if (!values.memo) {
      return false;
    }

    return true;
  };

  const depositFinished = () => {
    navigate(Path.salon.deposit.management(salonId));
  };

  const renderByStepIndex = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <>
            <Form.Row>
              <Form.Col>入金日</Form.Col>
              <Form.ColGrow>
                <Input.DatePicker
                  name="paymentDate"
                  type="text"
                  className="w-full"
                  disablePast={!isEdit && !isPrefilled}
                  value={values.paymentDate}
                  setValue={(newValue) => {
                    setFieldValue("paymentDate", newValue);
                  }}/>
              </Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>番号</Form.Col>
              <Form.ColGrow>
                <Input.Field
                  name="paymentNumber"
                  type="text"
                  className="w-full"
                  value={values.paymentNumber}
                  setValue={(newValue) => {
                    setFieldValue("paymentNumber", newValue);
                  }}></Input.Field>
              </Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>件名</Form.Col>
              <Form.ColGrow>
                <Input.Field
                  name="paymentTitle"
                  type="text"
                  className="w-full"
                  value={values.paymentTitle}
                  setValue={(newValue) => {
                    setFieldValue("paymentTitle", newValue);
                  }}></Input.Field>
              </Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>入金口座</Form.Col>
              <Form.ColGrow>
                <Input.Field
                  name="paymentAccount"
                  type="text"
                  className="w-full"
                  value={values.paymentAccount}
                  setValue={(newValue) => {
                    setFieldValue("paymentAccount", newValue);
                  }}></Input.Field>
              </Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>入金額</Form.Col>
              <Form.ColGrow>
                <Input.Field
                  name="paymentAmount"
                  type="number"
                  className="w-full"
                  value={values.paymentAmount}
                  positiveOnly
                  setValue={(newValue) => {
                    setFieldValue("paymentAmount", newValue);
                  }}></Input.Field>
              </Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>備考</Form.Col>
              <Form.ColGrow>
                <Input.MultilineField
                  name="memo"
                  type="number"
                  className="w-full"
                  value={values.memo}
                  setValue={(newValue) => {
                    setFieldValue("memo", newValue);
                  }}></Input.MultilineField>
              </Form.ColGrow>
            </Form.Row>
            <Panel.Justify className="!sticky !bottom-0 w-full bg-white p-2">
              <BackBtn url={Path.salon.deposit.home(salonId)}/>
              <Button.Primary onClick={gotoNextStep} disabled={!step1IsValid()}>
                次へ
              </Button.Primary>
            </Panel.Justify>
          </>
        );
      case 1:
        return (
          <div className="mx-2 space-y-4">
            <Form.Row>
              <Form.Col>入金日</Form.Col>
              <Form.ColGrow>
                <Label.DateTime dateFormat="YYYY-MM-DD">
                  {values.paymentDate}
                </Label.DateTime>
              </Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>番号</Form.Col>
              <Form.ColGrow>{values.paymentNumber}</Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>件名</Form.Col>
              <Form.ColGrow>{values.paymentTitle}</Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>入金口座</Form.Col>
              <Form.ColGrow>{values.paymentAccount}</Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>入金額</Form.Col>
              <Form.ColGrow>{values.paymentAmount}</Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>備考</Form.Col>
              <Form.ColGrow className="whitespace-pre-wrap">
                {values.memo}
              </Form.ColGrow>
            </Form.Row>
            <Panel.Justify className="!sticky !bottom-0 w-full bg-white p-2">
              <Button onClick={gotoPrevStep}>戻る</Button>
              <Button.Primary type="submit" loading={isSubmitting}>
                {isEdit ? "入金情報を編集する" : "入金情報を登録する"}
              </Button.Primary>
            </Panel.Justify>
          </div>
        );

      case 2:
        return (
          <div className="text-center space-y-4 mx-2">
            <Form.Row>
              <Form.ColGrow>
                {isEdit ? "入金情報を編集完了" : "入金情報を登録完了"}
              </Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.ColGrow>
                <Button.Primary onClick={depositFinished}>完了</Button.Primary>
              </Form.ColGrow>
            </Form.Row>
          </div>
        );

      default:
        return <></>;
    }
  };

  return (
    <>
      <StepsBar steps={steps} currentStepIndex={stepId} />
      <Form.Form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
        {renderByStepIndex(stepId)}
      </Form.Form>
    </>
  );
}

export default DepositForm;
