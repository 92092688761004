import React from "react";
import DefaultLayout from "app/layouts/DefaultLayout";
import Contract from "app/components/Contract";
import MainMenuItem from "app/components/MainMenuItem";
import BackBtn from "../../components/BackButton";
import Path from "app/route/Path";

function ContractHomePage() {
  return (
    <DefaultLayout
      headerProps={{
        headerClassName: "md:!block",
        // pageTitle: "ホーム",
      }}
    >
      <Contract.MenuGrid>
        <div className="flex items-center justify-center aspect-square w-full h-full p-[10%]">
          {<MainMenuItem.Contract.Send />}
        </div>
        <div className="flex items-center justify-center aspect-square w-full h-full p-[10%]">
          {<MainMenuItem.Contract.Status />}
        </div>
        <div className="flex items-center justify-center aspect-square w-full h-full p-[10%]">
          {<MainMenuItem.Contract.Contact />}
        </div>
      </Contract.MenuGrid>
      <BackBtn url={Path.home}/>
    </DefaultLayout>
  );
}

export default ContractHomePage;
