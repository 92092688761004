import React, { useMemo } from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DefaultLayout from "app/layouts/DefaultLayout";
import Path from "app/route/Path";
import Api from "app/services/api";
import Button from "app/components/common/Button";
import SearchBox from "app/components/common/SearchBox";
import Salon from "app/components/Salon";
import { CSVLink } from "react-csv";
import BackBtn from "../../../components/BackButton";

function SalonManageListPage() {
  const navigate = useNavigate();

  const [searchText, setSearchText] = useState("");
  const [searchSalons, setSearchSalons] = useState([]);
  const [searchSalonsLoaded, setSearchSalonsLoaded] = useState(false);

  async function getAllSalons() {
    const response = await Api.salons.all();
    const items = response.data;
    // sort items by name:
    const sortedList = items.sort((a, b) => a.name.localeCompare(b.name));
    setSearchSalons(sortedList);
    setSearchSalonsLoaded(true);
  }

  useEffect(() => {
    getAllSalons();
  }, []);

  const searchClicked = async (evt) => {
    if (searchText === "") {
      return getAllSalons();
    }

    setSearchSalonsLoaded(false);
    const response = await Api.salons.search(searchText);
    const items = response.data;
    setSearchSalons(items);
    setSearchSalonsLoaded(true);
  };

  const onDetailClicked = (salonItem) => {
    navigate(Path.salon.manage.detail(salonItem.id));
  };

  const csvData = useMemo(() => {
    const formatCsvList = searchSalons?.map((item) => {
      const { _id, name, address, telephone, createdAt } = item || {};
      return [_id, name, address, telephone, createdAt];
    });
    return [["加盟No", "店舗名", "住所", "電話番号", "登録日"], ...(formatCsvList || [])];
  }, [searchSalons]);

  return (
    <DefaultLayout
      headerProps={{
        headerClassName: "md:!block",
        pageTitle: "加盟店一覧",
      }}
      showNotice={false}
    >
      <Button.Secondary>
        <CSVLink data={csvData} filename={"加盟店一覧"}>
        エクスポート
        </CSVLink>
      </Button.Secondary>

      <SearchBox
        placeholder="加盟店を探す"
        value={searchText}
        setValue={setSearchText}
        onSearch={searchClicked}
      />
      <Salon.Table.Table items={searchSalons} loading={!searchSalonsLoaded}>
        <Salon.Table.Loading>
          <tbody>
            <tr>
              <td colSpan="100">ローディング中</td>
            </tr>
          </tbody>
        </Salon.Table.Loading>
        <Salon.Table.Empty>加盟店がないです。</Salon.Table.Empty>
        <Salon.Table.Header>
          <tr>
            <th scope="col" className="px-6 py-4">
              加盟No 
            </th>
            <th scope="col" className="px-6 py-4">
              店舗名
            </th>
            <th scope="col" className="px-6 py-4">
              住所
            </th>
            <th scope="col" className="px-6 py-4">
              電話番号 
            </th>
            <th scope="col" className="px-6 py-4">
              登録日
            </th>
            <th scope="col" className="px-6 py-4">
              選択
            </th>
          </tr>
        </Salon.Table.Header>
        <Salon.Table.Items>
          {searchSalons.map((item) => (
            <Salon.Table.Item key={item.id} onDetailClick={onDetailClicked}>
              {item}
            </Salon.Table.Item>
          ))}
        </Salon.Table.Items>
      </Salon.Table.Table>
      <BackBtn url={Path.salon.manage.home}/>
    </DefaultLayout>
  );
}

export default SalonManageListPage;

// 2401-2