import React from "react";
import DefaultLayout from "app/layouts/DefaultLayout";
import MainMenuGrid from "app/components/MainMenuGrid";
import MainMenuItem from "app/components/MainMenuItem";

function HomePage() {
  return (
    <DefaultLayout
      headerProps={{
        headerClassName: "md:!block",
        // pageTitle: "ホーム",
      }}
    >
      <MainMenuGrid>
        <div className="flex items-center justify-center aspect-square w-full h-full p-[10%]">
          <MainMenuItem.Examination.List />
        </div>
        <div className="flex items-center justify-center aspect-square w-full h-full p-[10%]">
          <MainMenuItem.Kit.Home />
        </div>
        <div className="flex items-center justify-center aspect-square w-full h-full p-[10%]">
          <MainMenuItem.Salon.Deposit.List />
        </div>
        <div className="flex items-center justify-center aspect-square w-full h-full p-[10%]">
          <MainMenuItem.Salon.Manage.Menu />
        </div>
        <div className="flex items-center justify-center aspect-square w-full h-full p-[10%]">
          <MainMenuItem.Contract.Menu />
        </div>
        <div className="flex items-center justify-center aspect-square w-full h-full p-[10%]">
          <MainMenuItem.Recommend />
        </div>
        <div className="flex items-center justify-center aspect-square w-full h-full p-[10%]">
          <MainMenuItem.DM.Menu />
        </div>
        <div className="flex items-center justify-center aspect-square w-full h-full p-[10%]">
          <MainMenuItem.Contractor.Menu />
        </div>
        <div className="flex items-center justify-center aspect-square w-full h-full p-[10%]">
          {<MainMenuItem.SystemSetting.Menu />}
        </div>
      </MainMenuGrid>
    </DefaultLayout>
  );
}

export default HomePage;
