import ContractTableItem from "./ContractTableItem";
import ContactForm from "./ContactForm";
import ContractDetail from "./ContractDetail";
import ContractGridItem from "./ContractGridItem";
import Grid from "app/components/common/Grid";
import MainMenuGrid from "app/components/MainMenuGrid";

import Table from "app/components/common/Table";

const exportObjs = {
  MenuGrid: MainMenuGrid,
  Grid: {
    ...Grid,
    Item: ContractGridItem,
  },
  Table: {
    ...Table,
    Item: ContractTableItem,
  },
  Contact: {
    Form: ContactForm,
  },
  Detail: ContractDetail,
};

export default exportObjs;
