import React from "react";
import Button from "app/components/common/Button";
import Label from "app/components/common/Label";
import dayjs from "dayjs";


export const CONTRACT_STATUS_VAL = {
  CREATING: "0",
  PENDING_REPLY: "1",
  EXPIRED: "2",
  FINISHED: "3",
  ABANDONED: "4",
};

export const CONTRACT_STATUS_LABEL = {
  [CONTRACT_STATUS_VAL.CREATING]: "Creating",
  [CONTRACT_STATUS_VAL.PENDING_REPLY]: "Pending reply",
  [CONTRACT_STATUS_VAL.EXPIRED]: "Expired",
  [CONTRACT_STATUS_VAL.FINISHED]: "Finished",
  [CONTRACT_STATUS_VAL.ABANDONED]: "Abandoned",
};

function ContractTableItem(props) {
  const { children, onDetailClick } = props;
  const contract = children;

  const onDetailClicked = () => {
    onDetailClick(contract);
  };

  const dateFormat = "YYYY-MM-DD";

  const formatDate = (date) => dayjs(date).format(dateFormat);

  return (
    <tr className="border-b dark:border-neutral-500">
      <td className="whitespace-nowrap px-6 py-4">{contract.contractStoreName}</td>
      <td className="whitespace-nowrap px-6 py-4">{formatDate(contract.createDate)}</td>
      <td className="whitespace-nowrap px-6 py-4">{formatDate(contract.sendDate)}</td>
      <td className="whitespace-nowrap px-6 py-4">{formatDate(contract.replyValidExpiryDate)}</td>
      <td className="whitespace-nowrap px-6 py-4">{formatDate(contract.effectiveDate)}</td>
      <td className="whitespace-nowrap px-6 py-4">{!contract.status ? CONTRACT_STATUS_LABEL[CONTRACT_STATUS_VAL.CREATING] : CONTRACT_STATUS_LABEL[contract.status]}</td>
    </tr>
  );
}

export default ContractTableItem;
