import React, { useMemo } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Path from "app/route/Path";
import Api from "app/services/api";
import Button from "app/components/common/Button";
import Label from "app/components/common/Label";
import Layout from "app/components/common/Layout";
import Modal from "app/components/common/Modal";
import Input from "app/components/common/Input";
import {getNameFromFirstLast} from "app/utils";
import dayjs from "dayjs";
import { defaultDateTimeFormat } from "app/constants";

function DMHistoryDetail(props) {
  const { children, customerOrSalon } = props;
  const DMHistoryItem = children;

  const navigate = useNavigate();

  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false);
  const [showDeleteReconfirmModal, setShowDeleteReconfirmModal] =
    useState(false);
  const [showCompleteModal, setShowCompleteModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const showDeleteConfirmationModalClicked = (evt) => {
    setShowDeleteConfirmationModal(true);
  };

  const confirmDeleteClicked = (evt) => {
    setShowDeleteConfirmationModal(false);
    setShowDeleteReconfirmModal(true);
  };

  const reconfirmDeleteClicked = async (evt) => {
    setIsSubmitting(true);
    const response = await Api.DMHistories.delete(DMHistoryItem.productName);
    if (response.data.status === "done") {
      setShowDeleteReconfirmModal(false);
      setShowCompleteModal(true);
      setIsSubmitting(false);
    } else {
      // TODO: Error handling
      setIsSubmitting(false);
      alert("Error");
    }
  };

  const closeCompleteModalClicked = (evt) => {
    setShowCompleteModal(false);
    navigate(Path.dm.home);
  };

  const editClicked = () => {
    navigate(Path.dm.edit(DMHistoryItem.id, 0));
  };

  // get customer, salon name:
  const customerOrSalonName = useMemo(() => {
    if(customerOrSalon === "salon"){
      // get salon name
      const salonName = DMHistoryItem?.salon[0]?.name ? DMHistoryItem?.salon[0]?.name : "";
      return salonName
    }
    // get customer name
    const customerName = DMHistoryItem?.customers[0] ? getNameFromFirstLast(DMHistoryItem?.customers[0]) : "";
    return customerName
  }, [customerOrSalon, DMHistoryItem])

  const presetTime = useMemo(() => {
    const formatDate = dayjs(DMHistoryItem.presetTime).format(defaultDateTimeFormat);
    return formatDate
  }, [DMHistoryItem])

  return (
    <>
      <Modal
        show={showDeleteConfirmationModal}
        setShow={setShowDeleteConfirmationModal}
        staticBackdrop={true}
        title="このおすすめ商品を削除しますか？"
        button1={
          <Button.Danger onClick={(evt) => confirmDeleteClicked(evt)}>
            削除を確認する
          </Button.Danger>
        }
        button2={
          <Button onClick={() => setShowDeleteConfirmationModal(false)}>
            閉じる
          </Button>
        }
      ></Modal>

      <Modal
        show={showDeleteReconfirmModal}
        setShow={setShowDeleteReconfirmModal}
        staticBackdrop={true}
        title="（最終確認）このおすすめ商品を削除しますか？"
        button1={
          <Button.Danger
            onClick={(evt) => reconfirmDeleteClicked(evt)}
            loading={isSubmitting}
          >
            削除を確認する
          </Button.Danger>
        }
        button2={
          <Button onClick={() => setShowDeleteReconfirmModal(false)}>
            閉じる
          </Button>
        }
      ></Modal>

      <Modal
        show={showCompleteModal}
        setShow={setShowCompleteModal}
        staticBackdrop={true}
        title="このおすすめ商品を削除しました。"
        button1={
          <Button.Secondary onClick={(evt) => closeCompleteModalClicked(evt)}>
            終了
          </Button.Secondary>
        }
      ></Modal>

      <Layout.Container>
        <Layout.Row>
          <Layout.Col grow={true}>
            <p>id: {DMHistoryItem._id}</p>
            <p className="font-bold">配信日時: {presetTime}</p>
            <p>配信対象: {customerOrSalonName}</p>
            <p>タイトル: {DMHistoryItem.title}</p>
            <p>本文: {DMHistoryItem.content}</p>
            <p>配信状況: {DMHistoryItem.status}</p>
          </Layout.Col>
        </Layout.Row>
        {/* 
        <Layout.Row single={true}>
          <Layout.Col>
            <Button.Danger onClick={showDeleteConfirmationModalClicked}>
              削除
            </Button.Danger>
          </Layout.Col>
        </Layout.Row>
        <Layout.Row single={true}>
          <Layout.Col>
            <Button.Secondary onClick={editClicked}>
              このおすすめ商品を編集
            </Button.Secondary>
          </Layout.Col>
        </Layout.Row> 
        */}
      </Layout.Container>
    </>
  );
}

export default DMHistoryDetail;
