import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DefaultLayout from "app/layouts/DefaultLayout";
import Path from "app/route/Path";
import Api from "app/services/api";
import Button from "app/components/common/Button";
import SearchBox from "app/components/common/SearchBox";
import Drc from "app/components/Drc";
import { useParams } from "react-router-dom";

function SalesOrderListPage() {
  const {} = useParams();
  const navigate = useNavigate();

  // **
  const registerButtonClicked = () => {
    navigate(Path.salesOrder.create(0));
  };
  const articleregisterButtonClicked = () => {
    navigate(Path.itemImport);
  };
  // **

  const [searchText, setSearchText] = useState("");
  const [searchSalesOrders, setSearchSalesOrders] = useState([]);
  const [searchSalesOrdersLoaded, setSearchSalesOrdersLoaded] = useState(false);

  useEffect(() => {
    async function getAllSalesOrders() {
      const response =
        await Api.examinationDatas.getScalpDeliverStatusNotNone();
      const items = response.data;
      setSearchSalesOrders(items);
      setSearchSalesOrdersLoaded(true);

      console.log(items);
    }

    getAllSalesOrders();
  }, []);

  const searchClicked = async (evt) => {
    if (searchText === "") {
      return;
    }

    setSearchSalesOrdersLoaded(false);
    const response = await Api.salesOrders.search(searchText);
    const items = response.data;
    setSearchSalesOrders(items);
    setSearchSalesOrdersLoaded(true);
  };

  const onDetailClicked = (salesOrderItem) => {
    navigate(Path.salesOrder.detail(salesOrderItem.id));
  };

  const onArrivedClicked = (examinationData) => {
    // update salonDeliverStatus for scalp data
    console.log("到着しました", examinationData);

    let payload = {
      customerNo: examinationData.customerNo,
      inspectionKitId: examinationData.inspectionKitId,
      scalpLabDeliverStatus: "done",
    };

    Api.examinationDatas
      .updateScalpLabDeliverStatus({
        examinationDataId: examinationData.id,
        payload,
      })
      .then((res) => {
        async function getAllExaminationDatas() {
          const response =
            await Api.examinationDatas.getScalpDeliverStatusNotNone();
          const items = response.data;
          setSearchSalesOrders(items);
          setSearchSalesOrdersLoaded(true);
        }

        getAllExaminationDatas();
        //gotoNextStep();
        //setIsSubmitting(false);
      })
      .catch((e) => {
        //setIsSubmitting(false);
        alert(e);
      })
      .finally(() => {
        //setIsSubmitting(false);
      });
  };

  return (
    <DefaultLayout
      headerProps={{
        headerClassName: "md:!block",
        pageTitle: "頭皮データ受注リスト",
      }}
      showNotice={false}
    >
      {/* <SearchBox
        placeholder="IDを探す"
        value={searchText}
        setValue={setSearchText}
        onSearch={searchClicked}
      /> */}
      <Drc.SalesOrder.Table.Table
        items={searchSalesOrders}
        loading={!searchSalesOrdersLoaded}
      >
        <Drc.SalesOrder.Table.Loading>
          <tbody>
            <tr>
              <td colSpan="100">ローディング中</td>
            </tr>
          </tbody>
        </Drc.SalesOrder.Table.Loading>
        <Drc.SalesOrder.Table.Empty>
          受注データがないです。
        </Drc.SalesOrder.Table.Empty>
        <Drc.SalesOrder.Table.Header>
          <tr>
            <th scope="col" className="px-6 py-4">
              -
            </th>
            <th scope="col" className="px-6 py-4">
              受注日時
            </th>
            <th scope="col" className="px-6 py-4">
              ID
            </th>
            <th scope="col" className="px-6 py-4">
              配送状況
            </th>
          </tr>
        </Drc.SalesOrder.Table.Header>
        <Drc.SalesOrder.Table.Items>
          {searchSalesOrders.map((item) => (
            <Drc.SalesOrder.Table.Item
              key={item.id}
              onDetailClick={onDetailClicked}
              onArrivedClick={onArrivedClicked}
              arriveBtnTxt={
                item.scalpLabDeliverStatus == "pending" ? "到着" : "到着した"
              }
            >
              {item}
            </Drc.SalesOrder.Table.Item>
          ))}
        </Drc.SalesOrder.Table.Items>
      </Drc.SalesOrder.Table.Table>
    </DefaultLayout>
  );
}

export default SalesOrderListPage;
