import React, { useState } from "react";
import Salon from "..";
import DoubleConfirmDeleteModal, { DELETE_STEP } from "app/components/DoubleConfirmDeleteModal";

const MODAL_DELETE_TITLE = {
  [DELETE_STEP.STEP_1]: "以下のメニューを削除しますか？",
  [DELETE_STEP.STEP_2]: "本当に以下のメニューを削除しますか？",
  [DELETE_STEP.STEP_3]: "メニューを削除しました",
  [DELETE_STEP.FAILED]: "Default Delete title failed",
};

function SalonMenuForm(props) {
  const { values, onMenuDeleteClicked, onEditClicked } = props;
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteData, setDeleteData] = useState({});

  const handleMenuDeleteClicked = () => {
    if (onMenuDeleteClicked) {
      onMenuDeleteClicked(deleteData);
      setShowDeleteModal(DELETE_STEP.STEP_3);
    }
  };

  return (
    <div className="space-y-2">
      <DoubleConfirmDeleteModal onCancel={() => setDeleteData({})} onDelete={handleMenuDeleteClicked} name={deleteData?.name} setShowDeleteModal={setShowDeleteModal} showDeleteModal={showDeleteModal} titleObj={MODAL_DELETE_TITLE} />
      <Salon.Menu.Grid.Grid items={values?.menus} noheight={true}>
        <Salon.Menu.Grid.Empty>メニューがないです。</Salon.Menu.Grid.Empty>

        <Salon.Menu.Grid.Items>
          {values?.menus?.map((menu, index) => (
            <Salon.Menu.Grid.Item
              key={index}
              onDeleteClicked={(menuItem) => {
                onMenuDeleteClicked && setShowDeleteModal(DELETE_STEP.STEP_1);
                setDeleteData(menuItem);
              }}
              onEditClicked={onEditClicked}>
              {menu}
            </Salon.Menu.Grid.Item>
          ))}
        </Salon.Menu.Grid.Items>
      </Salon.Menu.Grid.Grid>
    </div>
  );
}
export default SalonMenuForm;
