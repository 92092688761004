import React from "react";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Api from "app/services/api";
import DefaultLayout from "app/layouts/DefaultLayout";
import Employee from "app/components/SystemSetting/Employee";
import Path from "app/route/Path";

function EmployeeEditPage(props) {
  const { employeeId, stepId } = useParams();
  const navigate = useNavigate();

  const [employeeItem, setEmployeeItem] = useState({});
  const [employeeItemLoaded, setEmployeeItemLoaded] = useState(false);

  useEffect(() => {
    async function getEmployeeItem(employeeId) {
      await Api.employees.get(employeeId)
        .then((res) => {
          setEmployeeItem(res.data);
          setEmployeeItemLoaded(true);
        })
        .catch((e) => {
          alert("Error");
          navigate(Path.systemSetting.home);
        });
    }

    getEmployeeItem(employeeId);
  }, [employeeId]);

  return (
    <DefaultLayout
      headerProps={{
        headerClassName: "md:!block",
        pageTitle: "管理者・スタッフを編集",
      }}
      showNotice={false}
    >
      {!employeeItemLoaded ? (
        <div>ローディング中</div>
      ) : (
        <Employee.Form
          isEdit={true}
          stepId={+stepId}
          employeeType={employeeItem.employeeType}
          employeeId={employeeId}
        >
          {employeeItem}
        </Employee.Form>
      )}
    </DefaultLayout>
  );
}

export default EmployeeEditPage;
