import React from "react";
import { useState, useEffect } from "react";
import DefaultLayout from "app/layouts/DefaultLayout";
import { useNavigate } from "react-router-dom";
import Api from "app/services/api";
import Path from "app/route/Path";
import Kit from "app/components/Kit";
import BackBtn from "../../../components/BackButton";

function KitDeliveryListPage() {
  const navigate = useNavigate();

  const [kitDeliveryHistories, setKitDeliveryHistories] = useState([]);
  const [kitDeliveryHistoriesLoaded, setKitDeliveryHistoriesLoaded] =
    useState(false);

  useEffect(() => {
    async function getAllKitDeliveryHistories() {
      const response = await Api.kit.delivery.histories.all();
      const items = response.data;
      setKitDeliveryHistories(items);
      setKitDeliveryHistoriesLoaded(true);
    }

    getAllKitDeliveryHistories();
  }, []);

  const onDeliverButtonClicked = (kitDelivery) => {
    navigate(Path.kit.delivery.create(0), {
      state: { kitDeliveryItem: kitDelivery },
    });
  };

  return (
    <DefaultLayout
      headerProps={{
        headerClassName: "md:!block",
        pageTitle: "発送登録・履歴",
      }}
    >
      <Kit.Delivery.Table.Table
        items={kitDeliveryHistories}
        loading={!kitDeliveryHistoriesLoaded}
      >
        <Kit.Delivery.Table.Loading>
          <tbody>
            <tr>
              <td colSpan="100">ローディング中</td>
            </tr>
          </tbody>
        </Kit.Delivery.Table.Loading>
        <Kit.Delivery.Table.Empty>
          発送履歴がないです。
        </Kit.Delivery.Table.Empty>
        <Kit.Delivery.Table.Header>
          <tr>
            <th scope="col" className="px-6 py-4">
              発送日
            </th>
            <th scope="col" className="px-6 py-4">
              店舗No
            </th>
            <th scope="col" className="px-6 py-4">
              店舗名
            </th>
            <th scope="col" className="px-6 py-4">
              数量
            </th>
            <th scope="col" className="px-6 py-4">
              備考
            </th>
          </tr>
        </Kit.Delivery.Table.Header>
        <Kit.Delivery.Table.Items>
          {kitDeliveryHistories.map((item) => (
            <Kit.Delivery.Table.Item
              key={item.id}
              onDeliverButtonClick={onDeliverButtonClicked}
            >
              {item}
            </Kit.Delivery.Table.Item>
          ))}
        </Kit.Delivery.Table.Items>
      </Kit.Delivery.Table.Table>
      <BackBtn url={Path.kit.home}/>
    </DefaultLayout>
  );
}

export default KitDeliveryListPage;
