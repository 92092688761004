import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DefaultLayout from "app/layouts/DefaultLayout";
import Path from "app/route/Path";
import Api from "app/services/api";
import Button from "app/components/common/Button";
import SearchBox from "app/components/common/SearchBox";
import Drc from "app/components/Drc";
import { useParams } from "react-router-dom";

function ExaminationResultListPage() {
  const {} = useParams();
  const navigate = useNavigate();

  // **
  const registerButtonClicked = () => {
    navigate(Path.drc.examinationResult.create(0));
  };
  const articleregisterButtonClicked = () => {
    console.log("articleregisterButtonClicked22222");
    navigate(Path.itemImport);
  };
  // **

  const [searchText, setSearchText] = useState("");
  const [searchExaminationResults, setSearchExaminationResults] = useState([]);
  const [searchExaminationResultsLoaded, setSearchExaminationResultsLoaded] =
    useState(false);

  async function getAllExaminationResults() {
    const response = await Api.examinationDatas.getScalpDeliverStatusIsDone();
    const items = response.data;
    setSearchExaminationResults(items);
    setSearchExaminationResultsLoaded(true);
  }

  useEffect(() => {
    getAllExaminationResults();
  }, []);

  const searchClicked = async (evt) => {
    if (searchText === "") {
      return getAllExaminationResults();
    }

    setSearchExaminationResultsLoaded(false);
    const response = await Api.examinationDatas.search(searchText);
    const items = response.data;
    setSearchExaminationResults(items);
    setSearchExaminationResultsLoaded(true);
  };

  const onDetailClicked = (examinationResultItem) => {
    navigate(Path.drc.examinationResult.detail(examinationResultItem.id));
  };

  const onAnalysisStartClicked = (examinationResultItem) => {
    console.log("到着しました", examinationResultItem);

    let payload = {
      customerNo: examinationResultItem.customerNo,
      inspectionKitId: examinationResultItem.inspectionKitId,
      analyseStatus: "analyse_in_progress",
    };

    Api.examinationDatas
      .updateAnalyseStatus({
        examinationDataId: examinationResultItem.id,
        payload,
      })
      .then((res) => {
        async function getAllExaminationResults() {
          const response =
            await Api.examinationDatas.getScalpDeliverStatusIsDone();
          const items = response.data;
          setSearchExaminationResults(items);
          setSearchExaminationResultsLoaded(true);
        }

        getAllExaminationResults();
        //gotoNextStep();
        //setIsSubmitting(false);
      })
      .catch((e) => {
        //setIsSubmitting(false);
        alert(e);
      })
      .finally(() => {
        //setIsSubmitting(false);
      });
  };

  const exportButtonClicked = async () => {
    await Api.examinationDatas.drcExportApi().then((data) => {
      const csvDataFromServer = data.data;
      const blob = new Blob([csvDataFromServer], { type: "text/csv" });
      const csvURL = URL.createObjectURL(blob);
      const fileName = "csv-file";
      // create a element to get download
      const link = document.createElement("a");
      // set a element properties: href, download
      link.href = csvURL;
      link.setAttribute("download", `${fileName}.csv`);
      // add a element to DOM
      document.body.appendChild(link);
      link.click();
      // remove a element from DOM after finish
      document.body.removeChild(link);
    });
  };

  return (
    <DefaultLayout
      headerProps={{
        headerClassName: "md:!block",
        pageTitle: "頭皮データ結果一覧",
      }}
      showNotice={false}
    >
      {/* <Button.Primary onClick={registerButtonClicked}>新規登録</Button.Primary> */}
      <Button.Secondary onClick={exportButtonClicked}>
        エクスポート
      </Button.Secondary>
      <SearchBox
        placeholder="お客様IDで探す"
        value={searchText}
        setValue={setSearchText}
        onSearch={searchClicked}
      />
      <Drc.ExaminationResult.Table.Table
        items={searchExaminationResults}
        loading={!searchExaminationResultsLoaded}
      >
        <Drc.ExaminationResult.Table.Loading>
          <tbody>
            <tr>
              <td colSpan="100">ローディング中</td>
            </tr>
          </tbody>
        </Drc.ExaminationResult.Table.Loading>
        <Drc.ExaminationResult.Table.Empty>
          頭皮データ結果がないです。
        </Drc.ExaminationResult.Table.Empty>
        <Drc.ExaminationResult.Table.Header>
          <tr>
            <th scope="col" className="px-6 py-4">
              分析状況
            </th>
            {/* <th scope="col" className="px-6 py-4">
              ID
            </th> */}
            <th scope="col" className="px-6 py-4">
              お客様 ID
            </th>
            <th scope="col" className="px-6 py-4">
              キット ID
            </th>
            <th scope="col" className="px-6 py-4">
              クラスター
            </th>
            <th scope="col" className="px-6 py-4">
              細胞面積
            </th>
            <th scope="col" className="px-6 py-4">
              多重剥離
            </th>
            <th scope="col" className="px-6 py-4">
              CP
            </th>
            <th scope="col" className="px-6 py-4">
              総合
            </th>
            <th scope="col" className="px-6 py-4">
              登録日時
            </th>
            <th scope="col" className="px-6 py-4">
              詳細
            </th>
          </tr>
        </Drc.ExaminationResult.Table.Header>
        <Drc.ExaminationResult.Table.Items>
          {searchExaminationResults.map((item) => (
            <Drc.ExaminationResult.Table.Item
              key={item.id}
              onDetailClick={onDetailClicked}
              onAnalysisStartClick={onAnalysisStartClicked}
            >
              {item}
            </Drc.ExaminationResult.Table.Item>
          ))}
        </Drc.ExaminationResult.Table.Items>
      </Drc.ExaminationResult.Table.Table>
    </DefaultLayout>
  );
}

export default ExaminationResultListPage;
