import React from "react";
import { useParams } from "react-router-dom";
import DefaultLayout from "app/layouts/DefaultLayout";
import Salon from "app/components/Salon";

function SalonManageCreatePage(props) {
  const { stepId } = useParams();

  return (
    <DefaultLayout
      headerProps={{
        headerClassName: "md:!block",
        pageTitle: "加盟店情報登録",
      }}
      showNotice={false}
    >
      <Salon.Manage.Form stepId={+stepId} />
    </DefaultLayout>
  );
}

export default SalonManageCreatePage;
