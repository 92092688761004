import React from "react";
import TableLoading from "./TableLoading";
import TableEmpty from "./TableEmpty";
import TableHeader from "./TableHeader";
import TableItems from "./TableItems";

function Table(props) {
  const { children, items, loading } = props;
  let loadingChild = <p>ローディング中</p>;
  let emptyChild = <p>該当アイテムがありません</p>;
  let itemsChild = <tbody></tbody>;
  let headers = <thead></thead>;

  children.forEach((child, idx) => {
    if (child.type === TableLoading) {
      loadingChild = child;
      return;
    } else if (child.type === TableEmpty) {
      emptyChild = child;
      return;
    } else if (child.type === TableHeader) {
      headers = child;
      return;
    } else if (child.type === TableItems) {
      itemsChild = child;
      return;
    }

    return;
  });

  return (
    <div className="flex flex-col">
      <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
          <div className="overflow-hidden">
            <table className="min-w-full text-left text-sm font-light">
              {headers}
              {loading
                ? loadingChild
                : items.length === 0
                ? emptyChild
                : itemsChild}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Table;
