import React from "react";

function SalonGridItem(props) {
  const { children, onClicked } = props;
  const AgencyItem = children;

  const onSalonClick = () => {
    if (onClicked) {
      onClicked(AgencyItem);
    }
  };

  return (
    <div className="bg-white px-2 py-6 flex flex-row" onClick={onSalonClick}>
      <div>
        <img src={AgencyItem.imageUrl} alt={AgencyItem.name} />
      </div>
      <div className="px-2">
        <p className="font-bold">id: {AgencyItem.id}</p>
        <p className="font-bold">name: {AgencyItem.name}</p>
      </div>
    </div>
  );
}

export default SalonGridItem;
