import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import dayjs from "dayjs";
import Path from "app/route/Path";
import StepsBar from "app/components/common/StepsBar";
import Input from "app/components/common/Input";
import Button from "app/components/common/Button";
import Label from "app/components/common/Label";
import Panel from "app/components/common/Panel";
import Form from "app/components/common/Form";
import { contractors, outsourcings } from "../../services/api";
import BackBtn from "../BackButton";
import { validateEmail } from "../../helper";

function ContractorForm(props) {
  const { isEdit, stepId, contractorType, children } = props;
  const contractor = children;

  const contractorTypeTemp = contractorType;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const steps = [
    { name: "代理店情報を入力する" },
    { name: "入力確認" },
    { name: "入力完了" },
  ];

  var createActionMessage = "代理店を登録する";
  var editActionMessage = "代理店を修正する";
  var createFinishMessage = "代理店を登録しました";
  var editFinishMessage = "代理店を修正しました";

  if (contractorTypeTemp == "outsourcing") {
    steps[0].name = "委託先情報を入力する";
    createActionMessage = "委託先を登録する";
    editActionMessage = "委託先を修正する";
    createFinishMessage = "委託先を登録しました";
    editFinishMessage = "委託先を修正しました";
  }

  const navigate = useNavigate();

  const {
    handleSubmit,
    // handleChange,
    errors,
    values,
    setFieldValue,
    // resetForm,
    touched,
    handleBlur,
  } = useFormik({
    initialValues: {
      // id: isEdit ? contractor.id : "",
      contractorType: isEdit ? contractor.contractorType : contractorTypeTemp,
      companyName: isEdit ? contractor.companyName : "",
      companyNameFurigana: isEdit ? contractor.companyNameFurigana : "",
      leaderLastName: isEdit ? contractor.leaderLastName : "",
      leaderFirstName: isEdit ? contractor.leaderFirstName : "",
      phoneNumber: isEdit ? contractor.phoneNumber : "",
      email: isEdit ? contractor.email : "",
      address: isEdit ? contractor.address : "",
    },
    onSubmit: (values) => {
      setIsSubmitting(true);

      let payload = {
        companyName: values.companyName,
        companyNameKata: values.companyNameFurigana,
        picFirstName: values.leaderFirstName,
        picLastName: values.leaderLastName,
        phone: values.phoneNumber,
        email: values.email,
        address: values.address,
      }

      if (values.contractorType === "agency") {
        if (isEdit) {
          return contractors.update({agencyId: contractor.id, payload})
            .then((res) => {
              gotoNextStep();
              setIsSubmitting(false);
            })
            .catch((e) => {
              alert("Error");
              setIsSubmitting(false);
            });
        }

        return contractors.store(payload)
          .then((res) => {
            gotoNextStep();
            setIsSubmitting(false);
          })
          .catch((e) => {
            alert("Error");
            setIsSubmitting(false);
          });
      } else {
        if (isEdit) {
          return outsourcings.update({agencyId: contractor.id, payload})
            .then((res) => {
              gotoNextStep();
              setIsSubmitting(false);
            })
            .catch((e) => {
              alert("Error");
              setIsSubmitting(false);
            });
        }

        return outsourcings.store(payload)
          .then((res) => {
            gotoNextStep();
            setIsSubmitting(false);
          })
          .catch((e) => {
            alert("Error");
            setIsSubmitting(false);
          });
      }

    },
    
    validate: (values) => {
      const errors = {};
      if (!values.companyName) {
        errors.companyName = "The Company Name field required";
      }
      if (!values.companyNameFurigana) {
        errors.companyNameFurigana = "The Company Name Furigana field required";
      }
      if (!values.leaderLastName) {
        errors.leaderLastName = "The Leader Last Name field required";
      }
      if (!values.leaderFirstName) {
        errors.leaderFirstName = "The Leader First Name field required";
      }
      if (!values.phoneNumber) {
        errors.phoneNumber = "The PhoneNumber field required";
      }
      if (!values.email) {
        errors.email = "The Email field required";
      } else if (!validateEmail(values.email)) {
        errors.email = "Email must be an email format";
      }
      if (!values.address) {
        errors.address = "The Address field required";
      }
      return errors;
    }
  });

  const gotoPrevStep = () => {
    if (values.contractorType == "agency")
      isEdit
        ? navigate(Path.contractor.edit(contractor.id, stepId - 1, contractorType))
        : navigate(Path.contractor.create(stepId - 1, "agency"));
    if (values.contractorType == "outsourcing")
      isEdit
        ? navigate(Path.contractor.edit(contractor.id, stepId - 1, contractorType))
        : navigate(Path.contractor.create(stepId - 1, "outsourcing"));
  };

  const gotoNextStep = () => {
    if (values.contractorType == "agency")
      isEdit
        ? navigate(Path.contractor.edit(contractor.id, stepId + 1, contractorType))
        : navigate(Path.contractor.create(stepId + 1, "agency"));
    if (values.contractorType == "outsourcing")
      isEdit
        ? navigate(Path.contractor.edit(contractor.id, stepId + 1, contractorType))
        : navigate(Path.contractor.create(stepId + 1, "outsourcing"));
  };

  const step1IsValid = !Object?.keys(errors)?.length && !!Object?.keys(touched)?.length

  // const step1IsValid = () => {
  //   if (!values.companyName) {
  //     return false;
  //   }
  //   if (!values.companyNameFurigana) {
  //     return false;
  //   }
  //   if (!values.leaderLastName) {
  //     return false;
  //   }
  //   if (!values.leaderFirstName) {
  //     return false;
  //   }
  //   if (!values.phoneNumber) {
  //     return false;
  //   }
  //   if (!values.email) {
  //     return false;
  //   }
  //   if (!values.address) {
  //     return false;
  //   }

  //   return true;
  // };

  const contractorCreateFinished = () => {
    navigate(Path.contractor.list(values.contractorType));
  };

  const renderByStepIndex = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <>
            <Form.Row>
              <Form.Col>会社名</Form.Col>
              <Form.ColGrow>
                <Input.Field
                  name="companyName"
                  type="text"
                  className="w-full"
                  value={values.companyName}
                  onBlur={handleBlur}
                  errorMsg={errors?.companyName && touched?.companyName && errors?.companyName}
                  setValue={(newValue) => {
                    setFieldValue("companyName", newValue);
                  }}
                ></Input.Field>
              </Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>会社名（フリガナ）</Form.Col>
              <Form.ColGrow>
                <Input.Field
                  name="companyNameFurigana"
                  type="text"
                  className="w-full"
                  value={values.companyNameFurigana}
                  onBlur={handleBlur}
                  errorMsg={errors?.companyNameFurigana && touched?.companyNameFurigana && errors?.companyNameFurigana}
                  setValue={(newValue) => {
                    setFieldValue("companyNameFurigana", newValue);
                  }}
                ></Input.Field>
              </Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>担当者</Form.Col>
              <Form.ColGrow>
                <Form.Col>姓</Form.Col>
                <Input.Field
                  name="leaderLastName"
                  type="text"
                  value={values.leaderLastName}
                  onBlur={handleBlur}
                  errorMsg={errors?.leaderLastName && touched?.leaderLastName && errors?.leaderLastName}
                  setValue={(newValue) => {
                    setFieldValue("leaderLastName", newValue);
                  }}
                ></Input.Field>
              </Form.ColGrow>
              <Form.ColGrow>
                <Form.Col>名</Form.Col>
                <Input.Field
                  name="leaderFirstName"
                  type="text"
                  value={values.leaderFirstName}
                  onBlur={handleBlur}
                  errorMsg={errors?.leaderFirstName && touched?.leaderFirstName && errors?.leaderFirstName}
                  setValue={(newValue) => {
                    setFieldValue("leaderFirstName", newValue);
                  }}
                ></Input.Field>
              </Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>電話番号</Form.Col>
              <Form.ColGrow>
                <Input.Field
                  name="phoneNumber"
                  type="text"
                  className="w-full"
                  value={values.phoneNumber}
                  onBlur={handleBlur}
                  errorMsg={errors?.phoneNumber && touched?.phoneNumber && errors?.phoneNumber}
                  setValue={(newValue) => {
                    setFieldValue("phoneNumber", newValue);
                  }}
                ></Input.Field>
              </Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>メールアドレス</Form.Col>
              <Form.ColGrow>
                <Input.Field
                  name="email"
                  type="text"
                  className="w-full"
                  value={values.email}
                  onBlur={handleBlur}
                  errorMsg={errors?.email && touched?.email && errors?.email}
                  setValue={(newValue) => {
                    setFieldValue("email", newValue);
                  }}
                ></Input.Field>
              </Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>住所</Form.Col>
              <Form.ColGrow>
                <Input.Field
                  name="address"
                  type="text"
                  className="w-full"
                  value={values.address}
                  onBlur={handleBlur}
                  errorMsg={errors?.address && touched?.address && errors?.address}
                  setValue={(newValue) => {
                    setFieldValue("address", newValue);
                  }}
                ></Input.Field>
              </Form.ColGrow>
            </Form.Row>

            <Panel.Justify className="!sticky !bottom-0 w-full bg-white p-2">
              <BackBtn url={Path.contractor.home}/>
              <Button.Primary onClick={gotoNextStep} disabled={!step1IsValid}>
                次へ
              </Button.Primary>
            </Panel.Justify>
          </>
        );
      case 1:
        return (
          <div className="mx-2 space-y-4">
            <Form.Row>
              <Form.Col>会社名</Form.Col>
              <Form.ColGrow>{values.companyName}</Form.ColGrow>
            </Form.Row>
            <Form.Row></Form.Row>
            <Form.Row>
              <Form.Col>会社名（フリガナ）</Form.Col>
              <Form.ColGrow>{values.companyNameFurigana}</Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>担当者</Form.Col>
              <Form.ColGrow>
                {values.leaderLastName} {values.leaderFirstName}
              </Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>電話番号</Form.Col>
              <Form.ColGrow>{values.phoneNumber}</Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>メールアドレス</Form.Col>
              <Form.ColGrow>{values.email}</Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>住所</Form.Col>
              <Form.ColGrow>{values.address}</Form.ColGrow>
            </Form.Row>
            <Panel.Justify className="!sticky !bottom-0 w-full bg-white p-2">
              <Button onClick={gotoPrevStep}>戻る</Button>
              <Button.Primary type="submit" loading={isSubmitting}>
                {isEdit ? editActionMessage : createActionMessage}
              </Button.Primary>
            </Panel.Justify>
          </div>
        );

      case 2:
        return (
          <div className="text-center space-y-4 mx-2">
            <Form.Row>
              <Form.ColGrow>
                {isEdit ? editFinishMessage : createFinishMessage}
              </Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.ColGrow>
                <Button.Primary onClick={contractorCreateFinished}>
                  完了
                </Button.Primary>
              </Form.ColGrow>
            </Form.Row>
          </div>
        );

      default:
        return <></>;
    }
  };

  return (
    <>
      <StepsBar steps={steps} currentStepIndex={stepId} />
      <Form.Form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
        {renderByStepIndex(stepId)}
      </Form.Form>
    </>
  );
}

export default ContractorForm;
