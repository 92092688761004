import React from "react";
import { useMemo, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DefaultLayout from "app/layouts/DefaultLayout";
import Path from "app/route/Path";
import Api from "app/services/api";
import SearchBox from "app/components/common/SearchBox";
import Salon from "app/components/Salon";
import useQueryConfig from "./useQueryConfig";
import PaginationRounded from "app/components/common/PaginationRounded";
import Button from "app/components/common/Button";
import { CSVLink } from "react-csv";
import {createSearchParams} from "react-router-dom"
import BackBtn from "../../../components/BackButton";
import omitBy from "lodash/omitBy";
import isUndefined from "lodash/isUndefined";
import {useSearchParams} from "react-router-dom";
import isEqual from "lodash/isEqual";

function SalonDepositListPage() {
  const pageRoute = "/salon/deposit/list";
  const navigate = useNavigate();
  const queryConfig = useQueryConfig();
  const [searchParams] = useSearchParams();

  const initialQueryConfig = {
    page: "1",
    perPage: "10",
  }
  const emtyQueryConfig = {}

  const [searchText, setSearchText] = useState(searchParams.get("searchText") ? searchParams.get("searchText") : "");
  const [searchSalons, setSearchSalons] = useState({ data: [], result: {} });
  const [searchSalonsLoaded, setSearchSalonsLoaded] = useState(false);
  const [searchSalonsFullListData, setSearchSalonsFullListData] = useState([]);

  async function getAllSalons(queryConfig) {
    await Api.salons.all(queryConfig).then((response) => {
      setSearchSalons(response);
      setSearchSalonsLoaded(true);
    });
  }

  // use effect handle get the full data to export csv file
  useEffect(() => {
    // handleExportCsvData
    const handleGetFullCsvData = async () => {
      await Api.salons.allNoPaginate().then((response) => {
        const fullListData = response.data;
        setSearchSalonsFullListData(fullListData);
      });
    };
    handleGetFullCsvData();
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search).toString();
    if(!isEqual(queryConfig, initialQueryConfig) && !isEqual(queryConfig, emtyQueryConfig)){
      getAllSalons(queryConfig);
    } else if (!queryParams){
      getAllSalons(queryConfig);
    } else if (isEqual(queryConfig, initialQueryConfig) && !isEqual(queryConfig, emtyQueryConfig) && !searchText){
      getAllSalons(queryConfig);
    }
  }, [queryConfig, navigate]);

  const searchClicked = async () => {
    navigate({
      pathname: pageRoute,
      search: createSearchParams(
        omitBy(
          {
            ...queryConfig,
            page: 1,
            perPage: 10,
            searchText: searchText ? searchText : undefined,
          },
          isUndefined
        )
      ).toString(),
    });
  };

  const onDetailClicked = (salonItem) => {
    navigate(Path.salon.deposit.home(salonItem.id));
  };

  const totalPage = useMemo(() => {
    if (searchSalons?.result?.totalPages)
      return searchSalons?.result?.totalPages;
  }, [searchSalons]);
  const currentPage = useMemo(() => {
    if (searchSalons?.result?.page) return searchSalons?.result?.page;
  }, [searchSalons]);

  const csvData = useMemo(() => {
    let result = [];
    if (searchSalonsFullListData.length > 0) {
      const formatCsvList = searchSalonsFullListData.map((item) => {
        const { _id, name, address, telephone, createdAt } = item || {};
        return [_id, name, address, telephone, createdAt];
      });
      result = [
        ["加盟No", "店舗名", "住所", "電話番号", "登録日"],
        ...formatCsvList,
      ];
    }
    return result;
  }, [searchSalonsFullListData]);

  return (
    <DefaultLayout
      headerProps={{
        headerClassName: "md:!block",
        pageTitle: "加盟店一覧",
      }}
    >
      <SearchBox
        placeholder="加盟店を探す"
        value={searchText}
        setValue={setSearchText}
        onSearch={searchClicked}
      />
      <Button.Secondary disabled={searchSalonsFullListData.length < 1}>
        <CSVLink data={csvData} filename={"加盟店一覧"} className="w-full">
          エクスポート
        </CSVLink>
      </Button.Secondary>
      <Salon.Table.Table
        items={searchSalons}
        loading={!searchSalonsLoaded}
        style={{ display: "flex" }}
      >
        <Salon.Table.Loading>
          <tbody>
            <tr>
              <td colSpan="100">ローディング中</td>
            </tr>
          </tbody>
        </Salon.Table.Loading>
        <Salon.Table.Empty>加盟店がないです。</Salon.Table.Empty>
        <Salon.Table.Header>
          <tr>
            <th scope="col" className="px-6 py-4">
              加盟No
            </th>
            <th scope="col" className="px-6 py-4">
              店舗名
            </th>
            <th scope="col" className="px-6 py-4">
              選択
            </th>
          </tr>
        </Salon.Table.Header>
        <Salon.Table.Items>
          {searchSalons?.data?.map((item) => (
            <Salon.Table.Item key={item.id} onDetailClick={onDetailClicked}>
              {item}
            </Salon.Table.Item>
          ))}
        </Salon.Table.Items>
      </Salon.Table.Table>
      <PaginationRounded
        queryConfig={queryConfig}
        totalPage={totalPage}
        currentPage={currentPage}
      />
      <BackBtn url={Path.home} />
    </DefaultLayout>
  );
}

export default SalonDepositListPage;
