import React from "react";
import Button from "app/components/common/Button";
import Label from "app/components/common/Label";

function SalonTableItem(props) {
  const { children, onDetailClick } = props;
  const salonItem = children;

  const onDetailClicked = () => {
    onDetailClick(salonItem);
  };

  return (
    <tr className="border-b dark:border-neutral-500">
      <td className="whitespace-nowrap px-6 py-4">{salonItem.id}</td>
      <td className="whitespace-nowrap px-6 py-4">{salonItem.name}</td>
      <td className="whitespace-nowrap px-6 py-4">
        {salonItem.address}
      </td>
      <td className="whitespace-nowrap px-6 py-4">{salonItem.telephone}</td>
      <td className="whitespace-nowrap px-6 py-4">
        <Label.DateTime formatString="YYYY-MM-DD">
          {salonItem.createdAt}
        </Label.DateTime>
      </td>
      <td className="whitespace-nowrap px-6 py-4">
        <Button.Primary onClick={onDetailClicked}>詳細</Button.Primary>
      </td>
    </tr>
  );
}

export default SalonTableItem;
