import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Api from "app/services/api";
import DefaultLayout from "app/layouts/DefaultLayout";
import Contact from "app/components/Contact";

function DepositSalonContactPage() {
  const { salonId, stepId } = useParams();

  const [salonItem, setSalonItem] = useState({});
  const [salonItemLoaded, setSalonItemLoaded] = useState(false);

  useEffect(() => {
    async function getSalonById(salonId) {
      const response = await Api.salons.get(salonId);
      const item = response.data;
      setSalonItem(item);
      setSalonItemLoaded(true);
    }

    getSalonById(salonId);
  }, [salonId]);

  return (
    <DefaultLayout
      headerProps={{
        headerClassName: "md:!block",
        pageTitle: salonItem.name ?? "ローティング中",
      }}
      showNotice={false}
    >
      {!salonItemLoaded ? (
        <div>ローディング中</div>
      ) : (
        <Contact.Form stepId={+stepId} salonId={salonId}></Contact.Form>
      )}
    </DefaultLayout>
  );
}

export default DepositSalonContactPage;
