import React from "react";
import Label from "app/components/common/Label";
import Layout from "app/components/common/Layout";
import Button from "app/components/common/Button";
import Text from "app/components/common/Text";
import Image from "app/components/Image";

function SalonArtistGridItem(props) {
  const { children, onClicked, onDeleteClicked,onEditClicked, selected } = props;
  const artistItem = children;
  console.log(props)

  const onArtistClick = () => {
    if (onClicked) {
      onClicked(artistItem);
    }
  };

  const handleDeleteClicked = () => {
    console.log(onDeleteClicked)
    if (onDeleteClicked) {
      onDeleteClicked(artistItem);
    }
  };

  const handleEditClicked = () => {
    if (onEditClicked) {
      onEditClicked(artistItem);
    }
  };

  return (
    <Layout.Container
      className={`bg-white px-2 py-6 flex flex-row rounded-lg ${
        selected
          ? "!border-main-default !border-2 !border-solid"
          : "!border-gridItem-border !border-2 !border-solid"
      }`}
      onClick={onArtistClick}
    >
      {artistItem ? (
        <Layout.Row>
          {artistItem?.imageUrl && (
            <Layout.Col>
              <Image src={artistItem?.imageUrl} alt={artistItem?.name} />
            </Layout.Col>
          )}
          <Layout.Col grow={true}>
            {/* <p>ID: {artistItem?.id}</p> */}
            <Text.Thick useParag={true}>
              氏名: {artistItem?.lastName} {artistItem?.firstName}
            </Text.Thick>
            <Text.Thick useParag={true}>
              氏名（フリガナ）: {artistItem?.lastNameKana}
              {artistItem?.firstNameKana}
            </Text.Thick>
            <Text.Normal useParag={true}>
              生年月日：
              <Label.DateTime dateFormat="YYYY-MM-DD">
                {artistItem?.dob}
              </Label.DateTime>
            </Text.Normal>
            <Text.Normal useParag={true}>
              電話番号：
              {artistItem?.telephone}
            </Text.Normal>
            <Text.Normal useParag={true}>
              メールアドレス：
              {artistItem?.email}
            </Text.Normal>
            <Text.Normal useParag={true}>
              住所：
              {artistItem?.postalCode}
              {artistItem?.address}
            </Text.Normal>
            {onEditClicked && <Button.Primary onClick={handleEditClicked}>編集</Button.Primary>}
            {onDeleteClicked && onEditClicked && (
              <Button.Danger onClick={handleDeleteClicked}>削除</Button.Danger>
            )}
          </Layout.Col>
        </Layout.Row>
      ) : (
        <Layout.Row>美容師しない</Layout.Row>
      )}
    </Layout.Container>
  );
}

export default SalonArtistGridItem;
