import ExaminationDataTableItem from "./ExaminationDataTableItem";
import ExaminationDataDetail from "./ExaminationDataDetail";
import ExaminationDataForm from "./ExaminationDataForm";
import Table from "app/components/common/Table";

const exportObjs = {
  Table: {
    ...Table,
    Item: ExaminationDataTableItem,
  },
  Detail: ExaminationDataDetail,
  Form: ExaminationDataForm,
};

export default exportObjs;
