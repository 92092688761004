import React from "react";
import Label from "app/components/common/Label";

function KitOrderHistoryTableItem(props) {
  const { children } = props;
  const kitOrderHistoryItem = children;

  return (
    <tr className="border-b dark:border-neutral-500">
      <td className="whitespace-nowrap px-6 py-4 font-medium">
        <Label.DateTime dateFormat="YYYY-MM-DD">
          {kitOrderHistoryItem.orderDate}
        </Label.DateTime>
      </td>
      <td className="whitespace-nowrap px-6 py-4">
        {kitOrderHistoryItem.amount}
      </td>
      <td className="whitespace-nowrap px-6 py-4">
        <p className="whitespace-pre-wrap">{kitOrderHistoryItem.memo}</p>
      </td>
    </tr>
  );
}

export default KitOrderHistoryTableItem;
