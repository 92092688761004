import React, { useLayoutEffect } from "react";
import { ToastContainer } from "react-toastify";
import { useLocation } from "react-router-dom";

import Header from "./Header";
import Breadcrumb from "./Breadcrumb";
import Debug from "app/components/common/Debug";
import NoticeSectionPC from "./NoticeSectionPC";

import "react-toastify/dist/ReactToastify.css";

function DefaultLayout(props) {
  const { headerProps, showNotice, className, children } = props;

  const shouldShowNotice = showNotice === false ? false : true;

  const location = useLocation();

  useLayoutEffect(() => {
    // eslint-disable-next-line no-undef
    document.documentElement.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, [location.pathname]);

  return (
    <>
      <div className="h-screen w-full flex flex-col">
        <Debug.BreakPoint />
        <Header {...headerProps} />
        {headerProps.pageTitle ? (
          <Breadcrumb pageTitle={headerProps.pageTitle} />
        ) : (
          <></>
        )}

        <div className="md:custom-container w-full pt-6 md:pb-6 flex-grow h-[calc(100vh-80px)] flex">
          <div
            className={`main-section-width h-full flex flex-col space-y-4 ${
              className ?? ""
            }`}
          >
            {children}
            {shouldShowNotice && (
              <div className="w-full h-[160px] border border-gray-400 shrink-0">
                <NoticeSectionPC />
              </div>
            )}
          </div>
        </div>
      </div>

      <ToastContainer
        position="bottom-left"
        autoClose={1500}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
        limit={3}
      />
    </>
  );
}

export default DefaultLayout;
