import React from "react";
import Button from "app/components/common/Button";
import Label from "app/components/common/Label";

function ContractorTableItem(props) {
  const { children, onDetailClick } = props;
  const contractor = children;

  const onDetailClicked = () => {
    onDetailClick(contractor);
  };

  return (
    <tr className="border-b dark:border-neutral-500">
      <td className="whitespace-nowrap px-6 py-4">{contractor.companyName}</td>
      <td className="whitespace-nowrap px-6 py-4">{contractor.phoneNumber}</td>
      <td className="whitespace-nowrap px-6 py-4">
        {contractor.leaderLastName} {contractor.leaderFirstName}
      </td>
      <td className="whitespace-nowrap px-6 py-4">
        <Button.Primary onClick={onDetailClicked}>詳細</Button.Primary>
      </td>
    </tr>
  );
}

export default ContractorTableItem;
