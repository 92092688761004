import { useQueryParams } from "app/hooks";
import {useEffect, useState} from "react";
import omitBy from "lodash/omitBy";
import isUndefined from "lodash/isUndefined";

export default function useQueryConfig() {
  const queryParams = useQueryParams();
  const [result, setResult] = useState({})

  useEffect(() => {
    const queryConfig = omitBy({
      fromDate: queryParams.fromDate ? queryParams.fromDate : undefined,
      toDate: queryParams.toDate ? queryParams.toDate : undefined,
      distributionTarget: queryParams.distributionTarget ? queryParams.distributionTarget : undefined,
      deliveryInformation: queryParams.deliveryInformation ? queryParams.deliveryInformation : undefined,
    }, isUndefined);
    setResult(queryConfig)
  }, [queryParams])

  return result;
}
