import React from "react";
import DefaultLayout from "app/layouts/DefaultLayout";
import Contractor from "app/components/Contractor";
import MainMenuItem from "app/components/MainMenuItem";
import BackBtn from "../../components/BackButton";
import Path from "app/route/Path";

function ContractorHomePage() {
  return (
    <DefaultLayout
      headerProps={{
        headerClassName: "md:!block",
        // pageTitle: "ホーム",
      }}
    >
      <Contractor.MenuGrid>
        <div className="flex items-center justify-center aspect-square w-full h-full p-[10%]">
          {<MainMenuItem.Contractor.Agency.Create />}
        </div>
        <div className="flex items-center justify-center aspect-square w-full h-full p-[10%]">
          {<MainMenuItem.Contractor.Agency.List />}
        </div>
        <div className="flex items-center justify-center aspect-square w-full h-full p-[10%]">
          {<MainMenuItem.Contractor.Outsourcing.Create />}
        </div>
        <div className="flex items-center justify-center aspect-square w-full h-full p-[10%]">
          {<MainMenuItem.Contractor.Outsourcing.List />}
        </div>
      </Contractor.MenuGrid>
      <BackBtn url={Path.home}/>
    </DefaultLayout>
  );
}

export default ContractorHomePage;
