import React, { useState } from "react";
import Salon from "..";
import DoubleConfirmDeleteModal, { DELETE_STEP } from "../../DoubleConfirmDeleteModal";

const MODAL_DELETE_TITLE = {
  [DELETE_STEP.STEP_1]: "以下の担当者を削除しますか？",
  [DELETE_STEP.STEP_2]: "本当に以下の担当者を削除しますか？",
  [DELETE_STEP.STEP_3]: "担当者を削除しました",
  [DELETE_STEP.FAILED]: "Default Delete title failed",
};

function SalonArtistFormConfirm(props) {
  const { values, onArtistDeleteClicked, onEditClicked } = props;
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteData, setDeleteData] = useState({});

  const handleArtistDeleteClicked = () => {
    if (onArtistDeleteClicked) {
      onArtistDeleteClicked(deleteData);
      setShowDeleteModal(DELETE_STEP.STEP_3);
    }
  };

  console.log("deleteData", deleteData);

  return (
    <div className="space-y-2">
      <DoubleConfirmDeleteModal onCancel={() => setDeleteData({})} onDelete={handleArtistDeleteClicked} name={deleteData?.lastName + deleteData?.firstName} setShowDeleteModal={setShowDeleteModal} showDeleteModal={showDeleteModal} titleObj={MODAL_DELETE_TITLE} />
      <Salon.Artist.Grid.Grid items={values.artists} noheight={true}>
        <Salon.Artist.Grid.Empty>担当者がいないです。</Salon.Artist.Grid.Empty>
        <Salon.Artist.Grid.Items>
          {values.artists.map((artist, index) => (
            <Salon.Artist.Grid.Item
              key={index}
              onDeleteClicked={(artistItem) => {
                onArtistDeleteClicked && setShowDeleteModal(DELETE_STEP.STEP_1);
                setDeleteData(artistItem);
              }}
              onEditClicked={onEditClicked}>
              {artist}
            </Salon.Artist.Grid.Item>
          ))}
        </Salon.Artist.Grid.Items>
      </Salon.Artist.Grid.Grid>
    </div>
  );
}
export default SalonArtistFormConfirm;
