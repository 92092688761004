import React from "react";

function SearchBoxNoSearchButton(props) {
  const { placeholder, value, setValue, onSearch, className } = props;

  const onChanged = (evt) => {
    evt.preventDefault();
    setValue(evt.target.value);
  };

  return (
    <div className={`flex justify-end w-full px-2 ${className ?? ""}`}>
      <div className="relative mb-4 flex w-full flex-wrap items-stretch">
        <input
          type="search"
          className="relative m-0 -mr-0.5 block w-[1px] min-w-0 flex-auto rounded-l border border-solid border-neutral-300 bg-transparent bg-clip-padding px-3 py-[0.25rem] text-base font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-primary focus:text-neutral-700 focus:shadow-[inset_0_0_0_1px_rgb(59,113,202)] focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:focus:border-primary"
          placeholder={placeholder ?? "検索"}
          aria-label="Search"
          aria-describedby="button-addon1"
          onChange={onChanged}
          value={value}
        />
      </div>
    </div>
  );
}

export default SearchBoxNoSearchButton;
