import * as React from "react";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import 'dayjs/locale/ja';

import { defaultDateFormat } from "app/constants";
import { ErrorText } from "../common/Input/FieldInput";

export default function InputDateField(props) {
  const { value, setValue, disabled, disableFuture ,onBlur, errorMsg, errStyles,  } = props;

  return (
    <>
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale="ja"
    >
      <DatePicker
        value={value}
        onChange={(newValue) => setValue(newValue)}
        format={defaultDateFormat}
        disabled={disabled}
        disableFuture={disableFuture}
        onBlur={onBlur}
        sx={{
          "& .MuiInputBase-input": {
            padding: "6px 16px !important",
            lineHeight: "24px !important",
            height: "24px !important",
            fontSize: "16px",
          },
          "& .Mui-disabled": {
            backgroundColor: "#f3f4f6 !important",
          },
          "& input.Mui-disabled ": {
            color: "#000 !important",
            "-webkit-text-fill-color": "#000 !important",
          },
        }}
      />
    </LocalizationProvider>
    {errorMsg && <ErrorText errorMsg={errorMsg} style={errStyles} />}
    </>
  );
}
