import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import Path from "app/route/Path";
import StepsBar from "app/components/common/StepsBar";
import Input from "app/components/common/Input";
import Button from "app/components/common/Button";
import Panel from "app/components/common/Panel";
import Form from "app/components/common/Form";
import { salonContact } from "../../../services/api";
import BackBtn from "../../BackButton";

function ContactForm(props) {
  const { isEdit, stepId, children } = props;
  const contact = children;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const steps = [
    { name: "連絡内容を入力する" },
    { name: "入力確認" },
    { name: "入力完了" },
  ];

  var createActionMessage = "連絡内容を登録する";
  var editActionMessage = "連絡内容を修正する";
  var createFinishMessage = "連絡内容を登録しました";
  var editFinishMessage = "連絡内容を修正しました";

  const navigate = useNavigate();

  const {
    handleSubmit,
    // handleChange,
    // errors,
    values,
    setFieldValue,
    // resetForm,
    // touched,
    // handleBlur,
  } = useFormik({
    initialValues: {
      // id: isEdit ? contact.id : "",
      title: isEdit ? contact.title : "",
      content: isEdit ? contact.content : "",
    },
    onSubmit: (values) => {
      setIsSubmitting(true);
      salonContact.create(values)
        .then((res) => {
          gotoNextStep();
          setIsSubmitting(false);
        })
        .catch((e) => {
          console.log('Create Contact Field');
          setIsSubmitting(false);
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    },
  });

  const gotoPrevStep = () => {
    isEdit
      ? navigate(Path.salon.manage.contact.edit(contact.id, stepId - 1))
      : navigate(Path.salon.manage.contact(stepId - 1));
  };

  const gotoNextStep = () => {
    isEdit
      ? navigate(Path.salon.manage.contact(contact.id, stepId + 1))
      : navigate(Path.salon.manage.contact(stepId + 1));
  };

  const step1IsValid = () => {
    if (!values.title) {
      return false;
    }
    if (!values.content) {
      return false;
    }

    return true;
  };

  const contactCreateFinished = () => {
    navigate(Path.salon.manage.home);
  };

  const renderByStepIndex = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <>
            <Form.Row>
              <Form.Col>タイトル</Form.Col>
              <Form.ColGrow>
                <Input.Field
                  name="title"
                  type="text"
                  className="w-full"
                  value={values.title}
                  setValue={(newValue) => {
                    setFieldValue("title", newValue);
                  }}
                ></Input.Field>
              </Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>本文</Form.Col>
              <Form.ColGrow>
                <Input.MultilineField
                  name="content"
                  type="text"
                  className="w-full"
                  value={values.content}
                  setValue={(newValue) => {
                    setFieldValue("content", newValue);
                  }}
                ></Input.MultilineField>
              </Form.ColGrow>
            </Form.Row>

            <Panel.Justify className="!sticky !bottom-0 w-full bg-white p-2">
              <BackBtn url={Path.salon.manage.home}/>
              <Button.Primary onClick={gotoNextStep} disabled={!step1IsValid()}>
                次へ
              </Button.Primary>
            </Panel.Justify>
          </>
        );
      case 1:
        return (
          <div className="mx-2 space-y-4">
            <Form.Row>
              <Form.Col>タイトル</Form.Col>
              <Form.ColGrow>{values.title}</Form.ColGrow>
            </Form.Row>
            <Form.Row></Form.Row>
            <Form.Row>
              <Form.Col>本文</Form.Col>
              <Form.ColGrow>{values.content}</Form.ColGrow>
            </Form.Row>
            <Panel.Justify className="!sticky !bottom-0 w-full bg-white p-2">
              <Button onClick={gotoPrevStep}>戻る</Button>
              <Button.Primary type="submit" loading={isSubmitting}>
                {isEdit ? editActionMessage : createActionMessage}
              </Button.Primary>
            </Panel.Justify>
          </div>
        );

      case 2:
        return (
          <div className="text-center space-y-4 mx-2">
            <Form.Row>
              <Form.ColGrow>
                {isEdit ? editFinishMessage : createFinishMessage}
              </Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.ColGrow>
                <Button.Primary onClick={contactCreateFinished}>
                  完了
                </Button.Primary>
              </Form.ColGrow>
            </Form.Row>
          </div>
        );

      default:
        return <></>;
    }
  };

  return (
    <>
      <StepsBar steps={steps} currentStepIndex={stepId} />
      <Form.Form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
        {renderByStepIndex(stepId)}
      </Form.Form>
    </>
  );
}

export default ContactForm;
