import salonListPage from "./Deposit/salonListPage";
import depositHomePage from "./Deposit/homePage";
import salesPage from "./Deposit/salesPage";
import depositContactPage from "./Deposit/contactPage";
import depositManagementPage from "./Deposit/depositManagementPage";
import depositEditPage from "./Deposit/depositEditPage";
import depositFulfillPage from "./Deposit/depositFulfillPage";
import depositCreatePage from "./Deposit/depositCreatePage";
import manageHomePage from "./Manage/homePage";
import manageCreatePage from "./Manage/createPage";
import manageContactPage from "./Manage/contactPage";
import manageArticlePage from "./Manage/articlePage";
import manageListPage from "./Manage/listPage";
import manageDetailPage from "./Manage/detailPage";
import manageEditPage from "./Manage/editPage";

const exportObjs = {
  Deposit: {
    SalonList: salonListPage,
    Home: depositHomePage,
    Sales: salesPage,
    Contact: depositContactPage,
    Management: depositManagementPage,
    Edit: depositEditPage,
    Fulfill: depositFulfillPage,
    Create: depositCreatePage,
  },
  Manage: {
    Home: manageHomePage,
    Create: manageCreatePage,
    Contact: manageContactPage,
    Article: manageArticlePage,
    List: manageListPage,
    Detail: manageDetailPage,
    Edit: manageEditPage,
  },
};

export default exportObjs;
