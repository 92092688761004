import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Path from "app/route/Path";
import Api from "app/services/api";
import Button from "app/components/common/Button";
import Label from "app/components/common/Label";
import Layout from "app/components/common/Layout";
import Modal from "app/components/common/Modal";
import Input from "app/components/common/Input";

function EmployeeDetail(props) {
  const { children } = props;
  const employeeItem = children;

  const navigate = useNavigate();

  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false);
  const [showDeleteReconfirmModal, setShowDeleteReconfirmModal] =
    useState(false);
  const [showCompleteModal, setShowCompleteModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const showDeleteConfirmationModalClicked = (evt) => {
    setShowDeleteConfirmationModal(true);
  };

  const confirmDeleteClicked = (evt) => {
    setShowDeleteConfirmationModal(false);
    setShowDeleteReconfirmModal(true);
  };

  const reconfirmDeleteClicked = async (evt) => {
    setIsSubmitting(true);
    const response = await Api.employees.delete(employeeItem.id);

    if (response.data.success) {
      setShowDeleteReconfirmModal(false);
      setShowCompleteModal(true);
      setIsSubmitting(false);
    } else {
      // TODO: Error handling
      setIsSubmitting(false);
      alert("Error");
    }
  };

  const closeCompleteModalClicked = (evt) => {
    setShowCompleteModal(false);
    navigate(Path.systemSetting.employee.list(employeeItem.employeeType));
  };

  const editClicked = () => {
    navigate(Path.systemSetting.employee.edit(employeeItem.id, 0));
  };

  var employeeTypeText = "";
  if (employeeItem.employeeType == "manager") employeeTypeText = "管理者";
  if (employeeItem.employeeType == "staff") employeeTypeText = "スタッフ";

  return (
    <>
      <Modal
        show={showDeleteConfirmationModal}
        setShow={setShowDeleteConfirmationModal}
        staticBackdrop={true}
        title={"この" + employeeTypeText + "を削除しますか？"}
        button1={
          <Button.Danger onClick={(evt) => confirmDeleteClicked(evt)}>
            削除を確認する
          </Button.Danger>
        }
        button2={
          <Button onClick={() => setShowDeleteConfirmationModal(false)}>
            閉じる
          </Button>
        }
      ></Modal>

      <Modal
        show={showDeleteReconfirmModal}
        setShow={setShowDeleteReconfirmModal}
        staticBackdrop={true}
        title={"（最終確認）この" + employeeTypeText + "を削除しますか？"}
        button1={
          <Button.Danger
            onClick={(evt) => reconfirmDeleteClicked(evt)}
            loading={isSubmitting}
          >
            削除を確認する
          </Button.Danger>
        }
        button2={
          <Button onClick={() => setShowDeleteReconfirmModal(false)}>
            閉じる
          </Button>
        }
      ></Modal>

      <Modal
        show={showCompleteModal}
        setShow={setShowCompleteModal}
        staticBackdrop={true}
        title={"この" + employeeTypeText + "を削除しました。"}
        button1={
          <Button.Secondary onClick={(evt) => closeCompleteModalClicked(evt)}>
            終了
          </Button.Secondary>
        }
      ></Modal>

      <Layout.Container>
        <Layout.Row>
          <Layout.Col grow={true}>
            <p>id: {employeeItem.id}</p>
            <p className="font-bold">社員番号: {employeeItem.employeeNumber}</p>
            <p>所属: {employeeItem.affiliation}</p>
            <p>アクセス権限: {employeeItem.accessPrivilege}</p>
            <p>
              氏名: {employeeItem.employeeLastName}{" "}
              {employeeItem.employeeFirstName}
            </p>
            <p>
              氏名（フリガナ）: {employeeItem.employeeLastNameFurigana}{" "}
              {employeeItem.employeeFirstNameFurigana}
            </p>
            <p>生年月日: {employeeItem.birthday}</p>
            <p>電話番号: {employeeItem.phoneNumber}</p>
            <p>メールアドレス: {employeeItem.email}</p>
            <p>住所: {employeeItem.address}</p>
          </Layout.Col>
        </Layout.Row>

        <Layout.Row single={true}>
          <Layout.Col>
            <Button.Danger onClick={showDeleteConfirmationModalClicked}>
              削除
            </Button.Danger>
          </Layout.Col>
        </Layout.Row>
        <Layout.Row single={true}>
          <Layout.Col>
            <Button.Secondary onClick={editClicked}>
              この{employeeTypeText}を編集
            </Button.Secondary>
          </Layout.Col>
        </Layout.Row>
      </Layout.Container>
    </>
  );
}

export default EmployeeDetail;
