import RecommendTableItem from "./RecommendTableItem";
import RecommendForm from "./RecommendForm";
import RecommendDetail from "./RecommendDetail";

import Table from "app/components/common/Table";

const exportObjs = {
  Table: {
    ...Table,
    Item: RecommendTableItem,
  },
  Form: RecommendForm,
  Detail: RecommendDetail,

};

export default exportObjs;
