import React from "react";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Api from "app/services/api";
import DefaultLayout from "app/layouts/DefaultLayout";
import Employee from "app/components/SystemSetting/Employee";
import Path from "app/route/Path";
import BackBtn from "../../components/BackButton";

function EmployeeDetailPage(props) {
  const { employeeId } = useParams();
  const navigate = useNavigate();

  const [employeeItem, setEmployeeItem] = useState({});
  const [employeeItemLoaded, setEmployeeItemLoaded] = useState(false);

  useEffect(() => {
    async function getEmployeeDetail(employeeId) {
      Api.employees.get(employeeId)
        .then((res) => {
          setEmployeeItem(res.data);
          setEmployeeItemLoaded(true);
        })
        .catch((e) => {
          alert("Error");
          navigate(Path.systemSetting.home);
        });
    }

    getEmployeeDetail(employeeId);
  }, [employeeId]);

  return (
    <DefaultLayout
      headerProps={{
        headerClassName: "md:!block",
        pageTitle: employeeItem.employeeFirstName,
      }}
      showNotice={false}
    >
      {!employeeItemLoaded ? (
        <div>ローディング中</div>
      ) : (
        <div className="space-y-4">
          <Employee.Detail>{employeeItem}</Employee.Detail>
        </div>
      )}
      <BackBtn/>
    </DefaultLayout>
  );
}

export default EmployeeDetailPage;
