import React, { forwardRef } from "react";

const FieldForwardInput = forwardRef((props, ref) => {
  const { value, setValue, positiveOnly, ...otherProps } = props;

  return (
    <input
      value={
        positiveOnly ? (value > 0 ? parseInt(value, 10).toString() : 0) : value
      }
      onChange={(evt) => setValue(evt.target.value)}
      {...otherProps}
      ref={ref}
    />
  );
});

export default FieldForwardInput;
