import React from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Path from "app/route/Path";
import Api from "app/services/api";
import Button from "app/components/common/Button";
import Label from "app/components/common/Label";
import Layout from "app/components/common/Layout";
import Modal from "app/components/common/Modal";
import Input from "app/components/common/Input";

function ContractorDetail(props) {
  const { children } = props;
  const contractorItem = children;

  const navigate = useNavigate();

  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false);
  const [showDeleteReconfirmModal, setShowDeleteReconfirmModal] =
    useState(false);
  const [showCompleteModal, setShowCompleteModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const showDeleteConfirmationModalClicked = (evt) => {
    setShowDeleteConfirmationModal(true);
  };

  const confirmDeleteClicked = (evt) => {
    setShowDeleteConfirmationModal(false);
    setShowDeleteReconfirmModal(true);
  };

  const reconfirmDeleteClicked = async (evt) => {
    setIsSubmitting(true);
    if (contractorItem.contractorType === "agency") {
      return await Api.contractors.delete(contractorItem.id)
        .then((res) => {
          setShowDeleteReconfirmModal(false);
          setShowCompleteModal(true);
          setIsSubmitting(false);
        })
        .catch((e) => {
          setIsSubmitting(false);
          alert("Error");
        });
    } else {
      return await Api.outsourcings.delete(contractorItem.id)
        .then((res) => {
          setShowDeleteReconfirmModal(false);
          setShowCompleteModal(true);
          setIsSubmitting(false);
        })
        .catch((e) => {
          setIsSubmitting(false);
          alert("Error");
        });
    }
  };

  const closeCompleteModalClicked = (evt) => {
    setShowCompleteModal(false);
    navigate(Path.contractor.list(contractorItem.contractorType));
  };

  const editClicked = () => {
    navigate(Path.contractor.edit(contractorItem.id, 0, contractorItem.contractorType));
  };

  var contractorTypeText = "";
  if (contractorItem.contractorType == "agency") contractorTypeText = "代理店";
  if (contractorItem.contractorType == "outsourcing")
    contractorTypeText = "委託先";

  return (
    <>
      <Modal
        show={showDeleteConfirmationModal}
        setShow={setShowDeleteConfirmationModal}
        staticBackdrop={true}
        title={"この" + contractorTypeText + "を削除しますか？"}
        button1={
          <Button.Danger onClick={(evt) => confirmDeleteClicked(evt)}>
            削除を確認する
          </Button.Danger>
        }
        button2={
          <Button onClick={() => setShowDeleteConfirmationModal(false)}>
            閉じる
          </Button>
        }
      ></Modal>

      <Modal
        show={showDeleteReconfirmModal}
        setShow={setShowDeleteReconfirmModal}
        staticBackdrop={true}
        title={"（最終確認）この" + contractorTypeText + "を削除しますか？"}
        button1={
          <Button.Danger
            onClick={(evt) => reconfirmDeleteClicked(evt)}
            loading={isSubmitting}
          >
            削除を確認する
          </Button.Danger>
        }
        button2={
          <Button onClick={() => setShowDeleteReconfirmModal(false)}>
            閉じる
          </Button>
        }
      ></Modal>

      <Modal
        show={showCompleteModal}
        setShow={setShowCompleteModal}
        staticBackdrop={true}
        title={"この" + contractorTypeText + "を削除しました。"}
        button1={
          <Button.Secondary onClick={(evt) => closeCompleteModalClicked(evt)}>
            終了
          </Button.Secondary>
        }
      ></Modal>

      <Layout.Container>
        <Layout.Row>
          <Layout.Col grow={true}>
            <p>id: {contractorItem.id}</p>
            <p className="font-bold">会社名: {contractorItem.companyName}</p>
            <p>会社名（フリガナ）: {contractorItem.companyNameFurigana}</p>
            <p>
              担当者: {contractorItem.leaderLastName}{" "}
              {contractorItem.leaderFirstName}
            </p>
            <p>電話番号: {contractorItem.phoneNumber}</p>
            <p>メールアドレス: {contractorItem.email}</p>
            <p>住所: {contractorItem.address}</p>
          </Layout.Col>
        </Layout.Row>

        <Layout.Row single={true}>
          <Layout.Col>
            <Button.Danger onClick={showDeleteConfirmationModalClicked}>
              削除
            </Button.Danger>
          </Layout.Col>
        </Layout.Row>
        <Layout.Row single={true}>
          <Layout.Col>
            <Button.Secondary onClick={editClicked}>
              この{contractorTypeText}を編集
            </Button.Secondary>
          </Layout.Col>
        </Layout.Row>
      </Layout.Container>
    </>
  );
}
export default ContractorDetail;
