import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import Path from "app/route/Path";
import StepsBar from "app/components/common/StepsBar";
import Input from "app/components/common/Input";
import Button from "app/components/common/Button";
import Panel from "app/components/common/Panel";
import Form from "app/components/common/Form";
import Api from "app/services/api";

function LabContactForm(props) {
  const { salonId, stepId } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const steps = [
    { name: "連絡内容入力" },
    { name: "入力確認" },
    { name: "入力完了" },
  ];

  const navigate = useNavigate();

  const {
    handleSubmit,
    // handleChange,
    // errors,
    values,
    setFieldValue,
    // resetForm,
    // touched,
    // handleBlur,
  } = useFormik({
    initialValues: {
      title: "",
      content: "",
    },
    onSubmit: (values) => {
      setIsSubmitting(true);
      Api.salonContact
        .create(values)
        .then((res) => {
          gotoNextStep();
          setIsSubmitting(false);
        })
        .catch((e) => {
          console.log("Create Contact Field");
          setIsSubmitting(false);
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    },
  });

  const gotoPrevStep = () => {
    navigate(Path.drc.labContact.create(stepId - 1));
  };

  const gotoNextStep = () => {
    navigate(Path.drc.labContact.create(stepId + 1));
  };

  const step1IsValid = () => {
    if (!values.title) {
      return false;
    }
    if (!values.content) {
      return false;
    }

    return true;
  };

  const labContactFinished = () => {
    navigate(Path.drc.home);
  };

  const renderByStepIndex = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <>
            <Form.Row>
              <Form.Col>タイトル</Form.Col>
              <Form.ColGrow>
                <Input.Field
                  name="title"
                  type="text"
                  className="w-full"
                  value={values.title}
                  setValue={(newValue) => {
                    setFieldValue("title", newValue);
                  }}
                ></Input.Field>
              </Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>本文</Form.Col>
              <Form.ColGrow>
                <Input.MultilineField
                  name="content"
                  type="text"
                  className="w-full"
                  value={values.content}
                  setValue={(newValue) => {
                    setFieldValue("content", newValue);
                  }}
                ></Input.MultilineField>
              </Form.ColGrow>
            </Form.Row>
            <Panel.Justify className="!sticky !bottom-0 w-full bg-white p-2">
              <div></div>
              <Button.Primary onClick={gotoNextStep} disabled={!step1IsValid()}>
                次へ
              </Button.Primary>
            </Panel.Justify>
          </>
        );
      case 1:
        return (
          <div className="mx-2 space-y-4">
            <Form.Row>
              <Form.Col>タイトル</Form.Col>
              <Form.ColGrow>{values.title}</Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>本文</Form.Col>
              <Form.ColGrow className="whitespace-pre-wrap">
                {values.content}
              </Form.ColGrow>
            </Form.Row>
            <Panel.Justify className="!sticky !bottom-0 w-full bg-white p-2">
              <Button onClick={gotoPrevStep}>戻る</Button>
              <Button.Primary type="submit" loading={isSubmitting}>
                連絡内容を登録する
              </Button.Primary>
            </Panel.Justify>
          </div>
        );

      case 2:
        return (
          <div className="text-center space-y-4 mx-2">
            <Form.Row>
              <Form.ColGrow>連絡内容を登録完了</Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.ColGrow>
                <Button.Primary onClick={labContactFinished}>
                  完了
                </Button.Primary>
              </Form.ColGrow>
            </Form.Row>
          </div>
        );

      default:
        return <></>;
    }
  };

  return (
    <>
      <StepsBar steps={steps} currentStepIndex={stepId} />
      <Form.Form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
        {renderByStepIndex(stepId)}
      </Form.Form>
    </>
  );
}

export default LabContactForm;
