import { createTheme } from "@mui/material/styles";

const CustomTheme = createTheme({
  palette: {
    // mode: "dark",
    primary: {
      main: "#1F4E79",
    },
    secondary: {
      main: "#00213f",
    },
    error: {
      main: "#d32f2f",
    },
  },
});

export default CustomTheme;
