import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Api from "app/services/api";
import DefaultLayout from "app/layouts/DefaultLayout";
import Kit from "app/components/Kit";

function KitDeliveryFulfillPage() {
  const { kitRequestId, stepId } = useParams();

  const [kitRequestItem, setKitRequestDataItem] = useState({});
  const [kitRequestItemLoaded, setKitRequestDataItemLoaded] = useState(false);

  useEffect(() => {
    async function getDepositItem(kitRequestId) {
      const response = await Api.kit.order.requests.get(kitRequestId);
      const item = response.data;
      setKitRequestDataItem(item);
      setKitRequestDataItemLoaded(true);
    }

    getDepositItem(kitRequestId);
  }, [kitRequestId]);

  return (
    <DefaultLayout
      headerProps={{
        headerClassName: "md:!block",
        pageTitle: "発送登録",
      }}
      showNotice={false}
    >
      {!kitRequestItemLoaded ? (
        <div>ローディング中</div>
      ) : (
        <Kit.Delivery.Form
          isPrefilled={true}
          stepId={+stepId}
          kitRequestId={kitRequestId}
        >
          {kitRequestItem}
        </Kit.Delivery.Form>
      )}
    </DefaultLayout>
  );
}

export default KitDeliveryFulfillPage;
