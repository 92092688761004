import React from "react";
import GridPending from "./GridPending";
import GridLoading from "./GridLoading";
import GridEmpty from "./GridEmpty";
import GridItems from "./GridItems";

function Grid(props) {
  const { children, items, loading, pending, noheight } = props;
  let pendingChild = <p>ボタンを押してください</p>;
  let loadingChild = <p>ローディング中</p>;
  let emptyChild = <p>該当アイテムがありません</p>;
  let itemsChild = <div></div>;

  children.forEach((child, idx) => {
    if (child.type === GridPending) {
      pendingChild = child;
    } else if (child.type === GridLoading) {
      loadingChild = child;
      return;
    } else if (child.type === GridEmpty) {
      emptyChild = child;
      return;
    } else if (child.type === GridItems) {
      itemsChild = child;
      return;
    }
    return;
  });

  return (
    <div
      className={`w-full ${
        noheight === true ? "" : "h-full"
      } overflow-auto bg-list-bg`}
    >
      <div className="w-full grid grid-cols-1 gap-4 pt-2 px-2">
        {pending
          ? pendingChild
          : loading
          ? loadingChild
          : items.length === 0
          ? emptyChild
          : itemsChild}
      </div>
    </div>
  );
}

export default Grid;
