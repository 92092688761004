import dayjs from "dayjs";
import {
  fake_examination_datas,
  fake_order_histories,
  fake_order_requests,
  fake_delivery_histories,
  fake_stock_remaining,
  fake_stock_histories,
  fake_sales_amount,
  fake_salon_deposits,
  fake_salons,
  fake_recommends,
  fake_DMHistories,
  fake_contractors,
  fake_employees,
  fake_contracts,
  fake_salesOrders,
  fake_examinationResults,
} from "./fake_data";

import {
  fake_customer_info,
  fake_category_with_service,
  fake_revervation_info,
  fake_counseling_sheet_list,
  fake_visit_history_list,
} from "./fake_data";

export const examinationDatas = {
  all: () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          data: fake_examination_datas,
        });
      }, 1000);
    });
  },
  get: (examinationDataId) => {
    return new Promise((resolve, reject) => {
      const targetExaminationData = fake_examination_datas.find(
        (examinationData) => {
          if (examinationData.id !== examinationDataId) {
            return false;
          }

          return true;
        }
      );
      setTimeout(() => {
        resolve({
          data: targetExaminationData,
        });
      }, 1000);
    });
  },
  delete: (examinationDataId) => {
    console.log(`DELETE: ExaminationData ${examinationDataId}`);
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          data: { status: "done" },
        });
      }, 1000);
    });
  },
};

export const kit = {
  order: {
    histories: {
      all: () => {
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve({
              data: fake_order_histories.sort((a, b) => {
                return (
                  dayjs(b.orderDate, "YYYY-MM-DD") -
                  dayjs(a.orderDate, "YYYY-MM-DD")
                );
              }),
            });
          }, 1000);
        });
      },
    },
    requests: {
      all: () => {
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve({
              data: fake_order_requests
                .map((order_request) => {
                  let new_order_request = order_request;
                  new_order_request.salon = fake_salons.find(
                    (salon) => salon.id === order_request.salonId
                  );
                  return new_order_request;
                })
                .sort((a, b) => {
                  return (
                    dayjs(b.orderDate, "YYYY-MM-DD") -
                    dayjs(a.orderDate, "YYYY-MM-DD")
                  );
                }),
            });
          }, 1000);
        });
      },
      get: (requestKitId) => {
        return new Promise((resolve, reject) => {
          const targetKitRequest = fake_order_requests.find(
            (order_request) => order_request.id === requestKitId
          );
          targetKitRequest.salon = fake_salons.find(
            (salon) => salon.id === targetKitRequest.salonId
          );
          setTimeout(() => {
            resolve({
              data: targetKitRequest,
            });
          }, 1000);
        });
      },
      notDeliver: () => {
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve({
              data: fake_order_requests
                .filter((order_request) => {
                  return order_request.deliveryStatus === "notdeliver";
                })
                .map((order_request) => {
                  let new_order_request = order_request;
                  new_order_request.salon = fake_salons.find(
                    (salon) => salon.id === order_request.salonId
                  );
                  return new_order_request;
                })
                .sort((a, b) => {
                  return (
                    dayjs(b.orderDate, "YYYY-MM-DD") -
                    dayjs(a.orderDate, "YYYY-MM-DD")
                  );
                }),
            });
          }, 1000);
        });
      },
    },
  },
  delivery: {
    histories: {
      all: () => {
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve({
              data: fake_delivery_histories
                .map((order_request) => {
                  let new_order_request = order_request;
                  new_order_request.salon = fake_salons.find(
                    (salon) => salon.id === order_request.salonId
                  );
                  return new_order_request;
                })
                .sort((a, b) => {
                  return (
                    dayjs(b.orderDate, "YYYY-MM-DD") -
                    dayjs(a.orderDate, "YYYY-MM-DD")
                  );
                }),
            });
          }, 1000);
        });
      },
    },
  },
  stock: {
    remaining: {
      get: () => {
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve({
              data: fake_stock_remaining,
            });
          }, 1000);
        });
      },
    },
    histories: {
      all: () => {
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve({
              data: fake_stock_histories.sort((a, b) => {
                return (
                  dayjs(b.orderDate, "YYYY-MM-DD") -
                  dayjs(a.orderDate, "YYYY-MM-DD")
                );
              }),
            });
          }, 1000);
        });
      },
      get: (kitStockHistoryId) => {
        return new Promise((resolve, reject) => {
          const targetKitStockHistory = fake_stock_histories.find(
            (stock_history) => stock_history.id === kitStockHistoryId
          );
          setTimeout(() => {
            resolve({
              data: targetKitStockHistory,
            });
          }, 1000);
        });
      },
    },
  },
};

export const sales = {
  search: (startDate, endDate, salonId) => {
    const targetSalonSalesSingle = fake_sales_amount.find(
      (salonSalesAmount) => {
        if (salonSalesAmount.salonId !== salonId) {
          return false;
        }

        const date = dayjs(salonSalesAmount.date, "YYYY-MM-DD");
        if (date < startDate) {
          return false;
        }

        if (date > endDate) {
          return false;
        }

        return true;
      }
    );
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          data: targetSalonSalesSingle,
        });
      }, 1000);
    });
  },
};

export const deposits = {
  list: (salonId, depositStatus) => {
    const targetSalonDeposits = fake_salon_deposits.filter((salonDeposit) => {
      if (salonDeposit.salonId !== salonId) {
        return false;
      }

      if (salonDeposit.depositStatus !== depositStatus) {
        return false;
      }

      return true;
    });
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          data: targetSalonDeposits,
        });
      }, 1000);
    });
  },
  getByDepositId: (salonId, depositId) => {
    const targetSalonDeposit = fake_salon_deposits.find((salonDeposit) => {
      if (salonDeposit.salonId !== salonId) {
        return false;
      }

      if (salonDeposit.id !== depositId) {
        return false;
      }

      return true;
    });
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          data: targetSalonDeposit,
        });
      }, 1000);
    });
  },
  getByInvoiceNumber: (salonId, invoiceNumber) => {
    const targetSalonDeposit = fake_salon_deposits.find((salonDeposit) => {
      if (salonDeposit.salonId !== salonId) {
        return false;
      }

      if (salonDeposit.invoiceNumber !== invoiceNumber) {
        return false;
      }

      return true;
    });
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          data: targetSalonDeposit,
        });
      }, 1000);
    });
  },
};

export const salons = {
  all: () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          data: fake_salons,
        });
      }, 1000);
    });
  },
  get: (salonId) => {
    const targetSalon = fake_salons.find((salon) => salon.id === salonId);
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          data: targetSalon,
        });
      }, 1000);
    });
  },
  search: (salonName) => {
    const targetSalons = fake_salons.filter((salon) =>
      salon.name.includes(salonName)
    );
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          data: targetSalons,
        });
      }, 1000);
    });
  },
};

export const recommends = {
  all: () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          data: fake_recommends,
        });
      }, 1000);
    });
  },
  get: (recommendId) => {
    const targetRecommend = fake_recommends.find(
      (recommend) => recommend.id === recommendId
    );
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          data: targetRecommend,
        });
      }, 1000);
    });
  },
  search: (recommendProductName) => {
    const targetRecommends = fake_recommends.filter((recommend) =>
      recommend.productName.includes(recommendProductName)
    );
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          data: targetRecommends,
        });
      }, 1000);
    });
  },
  delete: (recommendProductName) => {
    console.log(`DELETE: recommendProductName ${recommendProductName}`);
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          data: { status: "done" },
        });
      }, 1000);
    });
  },
};

export const DMHistories = {
  get: (DMHistoryId) => {
    const targetHistories = fake_DMHistories.find(
      (DM) => DM.id === DMHistoryId
    );
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          data: targetHistories,
        });
      }, 1000);
    });
  },
  searchByCategory: (category) => {
    const targetHistories = fake_DMHistories.filter((DM) =>
      DM.category.includes(category)
    );
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          data: targetHistories,
        });
      }, 1000);
    });
  },
};

export const contractors = {
  all: () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          data: fake_contractors,
        });
      }, 1000);
    });
  },
  get: (contractorId) => {
    const targetRecommend = fake_contractors.find(
      (contractor) => contractor.id === contractorId
    );
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          data: targetRecommend,
        });
      }, 1000);
    });
  },
  searchByContractorType: (contractorType) => {
    const targetContractors = fake_contractors.filter((contractor) =>
      contractor.contractorType.includes(contractorType)
    );
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          data: targetContractors,
        });
      }, 1000);
    });
  },
  delete: (contractorCompanyName) => {
    console.log(`DELETE: contractorCompanyName ${contractorCompanyName}`);
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          data: { status: "done" },
        });
      }, 1000);
    });
  },
};

export const employees = {
  all: () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          data: fake_employees,
        });
      }, 1000);
    });
  },
  get: (employeeId) => {
    const targetRecommend = fake_employees.find(
      (employee) => employee.id === employeeId
    );
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          data: targetRecommend,
        });
      }, 1000);
    });
  },
  searchByEmployeeType: (employeeType) => {
    const targetEmployees = fake_employees.filter((employee) =>
      employee.employeeType.includes(employeeType)
    );
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          data: targetEmployees,
        });
      }, 1000);
    });
  },
  delete: (employeeId) => {
    console.log(`DELETE: employeeId ${employeeId}`);
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          data: { status: "done" },
        });
      }, 1000);
    });
  },
};

export const contracts = {
  all: () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          data: fake_contracts,
        });
      }, 1000);
    });
  },
  get: (contractId) => {
    const targetRecommend = fake_contracts.find(
      (contract) => contract.id === contractId
    );
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          data: targetRecommend,
        });
      }, 1000);
    });
  },
  searchByContractType: (contractType) => {
    const targetContracts = fake_contracts.filter((contract) =>
      contract.contractType.includes(contractType)
    );
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          data: targetContracts,
        });
      }, 1000);
    });
  },
  delete: (contractCompanyName) => {
    console.log(`DELETE: contractCompanyName ${contractCompanyName}`);
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          data: { status: "done" },
        });
      }, 1000);
    });
  },
};

export const salesOrders = {
  all: () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          data: fake_salesOrders,
        });
      }, 1000);
    });
  },
  get: (salesOrderId) => {
    const targetSalesOrder = fake_salesOrders.find(
      (salesOrder) => salesOrder.id === salesOrderId
    );
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          data: targetSalesOrder,
        });
      }, 1000);
    });
  },
  searchBySalesOrderType: (salesOrderType) => {
    const targetSalesOrders = fake_salesOrders.filter((salesOrder) =>
      salesOrder.salesOrderType.includes(salesOrderType)
    );
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          data: targetSalesOrders,
        });
      }, 1000);
    });
  },
  search: (orderId) => {
    const targetSalesOrders = fake_salesOrders.filter((order) =>
      order.orderId.includes(orderId)
    );
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          data: targetSalesOrders,
        });
      }, 1000);
    });
  },
  delete: (salesOrderCompanyName) => {
    console.log(`DELETE: salesOrderCompanyName ${salesOrderCompanyName}`);
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          data: { status: "done" },
        });
      }, 1000);
    });
  },
};

export const examinationResults = {
  all: () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          data: fake_examinationResults,
        });
      }, 1000);
    });
  },
  get: (examinationResultId) => {
    const targetExaminationResult = fake_examinationResults.find(
      (examinationResult) => examinationResult.id === examinationResultId
    );
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          data: targetExaminationResult,
        });
      }, 1000);
    });
  },
  searchByExaminationResultType: (examinationResultType) => {
    const targetExaminationResults = fake_examinationResults.filter(
      (examinationResult) =>
        examinationResult.examinationResultId.includes(examinationResultType)
    );
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          data: targetExaminationResults,
        });
      }, 1000);
    });
  },
  search: (orderId) => {
    const targetExaminationResults = fake_examinationResults.filter((order) =>
      order.orderId.includes(orderId)
    );
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          data: targetExaminationResults,
        });
      }, 1000);
    });
  },
  delete: (examinationResultCompanyName) => {
    console.log(
      `DELETE: examinationResultCompanyName ${examinationResultCompanyName}`
    );
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          data: { status: "done" },
        });
      }, 1000);
    });
  },
};

export const checkScalpLabMember = (member_id) => {
  console.log("customerId: ", member_id);

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const random = Math.random() > 0.5;
      // if (random > 0.6) {
      //   resolve({
      //     data: {
      //       customerId: member_id,
      //       reservationId: "fake_reservationId",
      //     },
      //   });
      // } else if (random > 0.4) {
      //   resolve({
      //     data: {
      //       customerId: member_id,
      //       reservationId: "",
      //     },
      //   });
      // } else {
      //   resolve({
      //     data: {
      //       customerId: "",
      //       reservationId: "",
      //     },
      //   });
      // }
      resolve({
        data: {
          member_id: member_id,
          reservationId: "",
        },
      });
    }, 1000);
  });
};

export const getCustomerInfo = (customerId) => {
  console.log("customerId: ", customerId);

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({ data: fake_customer_info });
    }, 1000);
  });
};

export const updateCustomerInfo = (customerId, customerInfo) => {
  console.log("updateCustomerInfo customerId: ", customerId);
  console.log("updateCustomerInfo customerInfo: ", customerInfo);

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        data: {
          id: customerId,
          ...customerInfo,
        },
      });
    }, 1000);
  });
};

export const deleteCustomer = (customerId) => {
  console.log("deleteCustomerInfo: ", customerId);

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        data: {
          isDelete: true,
        },
      });
    }, 1000);
  });
};

export const getCustomerBasicInfo = (customerId) => {
  console.log("customerId: ", customerId);

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({ data: fake_customer_info });
    }, 1000);
  });
};

export const updateCustomerBasicInfo = (customerId, newCustomerBasicInfo) => {
  console.log("customerId: ", customerId);
  console.log("newCustomerBasicInfo: ", newCustomerBasicInfo);

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({ data: fake_customer_info });
    }, 1000);
  });
};

export const searchAllCustomer = (searchConditions) => {
  console.log("searchAllCustomer searchConditions: ", searchConditions);

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        data: [
          fake_customer_info,
          { ...fake_customer_info, id: 2 },
          { ...fake_customer_info, id: 3 },
          { ...fake_customer_info, id: 4 },
          { ...fake_customer_info, id: 5 },
          { ...fake_customer_info, id: 6 },
        ],
      });
    }, 1000);
  });
};

export const getReservationInfo = (reservationId) => {
  console.log("reservationId: ", reservationId);

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({ data: fake_revervation_info });
    }, 1000);
  });
};

export const getAllCategory = () => {
  console.log("getAllCategory");

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        data: fake_category_with_service.map((item) => ({
          id: item.id,
          title: item.title,
        })),
      });
    }, 1000);
  });
};

export const getListServiceCategory = (category_id) => {
  console.log("category_id: ", category_id);

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const categoryObj = fake_category_with_service.find(
        (item) => item.id === category_id
      );
      if (categoryObj) {
        resolve({ data: categoryObj.services });
      } else {
        reject("404");
      }
    }, 1000);
  });
};

export const getAllCounselingSheet = () => {
  console.log("getAllCounselingSheet");

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        data: fake_counseling_sheet_list,
      });
    }, 1000);
  });
};

export const addCounselingSheet = (counselingSheetContent) => {
  console.log("counselingSheetContent: ", counselingSheetContent);

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        data: {
          id: Math.round(Math.random() * 1000),
          content: counselingSheetContent,
        },
      });
    }, 1000);
  });
};

export const editCounselingSheet = (id, counselingSheetContent) => {
  console.log("editCounselingSheet id: ", id);
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        data: {
          id: id,
          content: counselingSheetContent,
        },
      });
    }, 1000);
  });
};

export const addNewCustomer = (customerInfo) => {
  console.log("customerInfo: ", customerInfo);

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        data: {
          id: Math.round(Math.random() * 1000),
          ...customerInfo,
        },
      });
    }, 1000);
  });
};

export const addNewReservation = (reservationInfo) => {
  console.log("reservationInfo: ", reservationInfo);

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        data: {
          id: Math.round(Math.random() * 1000),
          ...reservationInfo,
        },
      });
    }, 1000);
  });
};

export const getAllCustomerVisitHistory = (customerId) => {
  console.log("getAllCustomerVisitHistory: ", customerId);

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        data: fake_visit_history_list,
      });
    }, 1000);
  });
};

export const getDetailCustomerVisitHistory = (visitHistoryId) => {
  console.log("getDetailCustomerVisitHistory: ", visitHistoryId);

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        data: fake_visit_history_list[0],
      });
    }, 1000);
  });
};
