import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DefaultLayout from "app/layouts/DefaultLayout";
import Path from "app/route/Path";
import Api, { contractors, outsourcings } from "app/services/api";
import Button from "app/components/common/Button";
import SearchBox from "app/components/common/SearchBox";
import Contractor from "app/components/Contractor";
import { useParams } from "react-router-dom";
import BackBtn from "../../components/BackButton";

function ContractorListPage() {
  const { contractorType } = useParams();
  const navigate = useNavigate();

  // **
  const registerButtonClicked = () => {
    navigate(Path.contractor.create(0));
  };
  const articleregisterButtonClicked = () => {
    navigate(Path.itemImport);
  };
  // **

  const [searchText, setSearchText] = useState("");
  const [searchContractors, setSearchContractors] = useState([]);
  const [searchContractorsLoaded, setSearchContractorsLoaded] = useState(false);

  useEffect(() => {
    async function getAllContractors() {
      if (contractorType === "agency") {
        return contractors.all()
          .then((res) => {
            setSearchContractors(res.data);
            setSearchContractorsLoaded(true);
          })
          .catch((e) => {
            alert("Error");
            setSearchContractorsLoaded(true);
          });
      } else {
        return outsourcings.all()
          .then((res) => {
            setSearchContractors(res.data);
            setSearchContractorsLoaded(true);
          })
          .catch((e) => {
            alert("Error");
            setSearchContractorsLoaded(true);
          });
      }
    }

    getAllContractors();
  }, []);

  // Wrong contractor type from url , return anyway
  if (contractorType != "agency" && contractorType != "outsourcing") return;

  var pageTitle = "一覧";
  var contractorTypeText = "";
  if (contractorType == "agency") contractorTypeText = "代理店";
  if (contractorType == "outsourcing") contractorTypeText = "委託先";

  pageTitle = contractorTypeText + pageTitle;

  const searchClicked = async (evt) => {
    if (searchText === "") {
      return;
    }

    setSearchContractorsLoaded(false);
    const response = await Api.contractors.search(searchText);
    const items = response.data;
    setSearchContractors(items);
    setSearchContractorsLoaded(true);
  };

  const onDetailClicked = (contractorItem) => {
    navigate(Path.contractor.detail(contractorItem.id, contractorItem.contractorType));
  };

  return (
    <DefaultLayout
      headerProps={{
        headerClassName: "md:!block",
        pageTitle: pageTitle,
      }}
      showNotice={false}
    >
      <Contractor.Table.Table
        items={searchContractors}
        loading={!searchContractorsLoaded}
      >
        <Contractor.Table.Loading>
          <tbody>
            <tr>
              <td colSpan="100">ローディング中</td>
            </tr>
          </tbody>
        </Contractor.Table.Loading>
        <Contractor.Table.Empty>
          {contractorTypeText}がないです。
        </Contractor.Table.Empty>
        <Contractor.Table.Header>
          <tr>
            <th scope="col" className="px-6 py-4">
              会社名
            </th>
            <th scope="col" className="px-6 py-4">
              電話番号
            </th>
            <th scope="col" className="px-6 py-4">
              担当者
            </th>
            <th scope="col" className="px-6 py-4">
              選択
            </th>
          </tr>
        </Contractor.Table.Header>
        <Contractor.Table.Items>
          {searchContractors.map((item) => (
            <Contractor.Table.Item
              key={item.id}
              onDetailClick={onDetailClicked}
            >
              {item}
            </Contractor.Table.Item>
          ))}
        </Contractor.Table.Items>
      </Contractor.Table.Table>
      <BackBtn url={Path.contractor.home}/>
    </DefaultLayout>
  );
}

export default ContractorListPage;
