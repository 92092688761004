import React from "react";
import { useState, useEffect } from "react";
import DefaultLayout from "app/layouts/DefaultLayout";
import { useNavigate } from "react-router-dom";
import Api from "app/services/api";
import Path from "app/route/Path";
import Text from "app/components/common/Text";
import Kit from "app/components/Kit";
import BackBtn from "../../../components/BackButton";

function KitStockListPage() {
  const navigate = useNavigate();

  const [kitStockRemaining, setKitStockRemaining] = useState([]);
  const [kitStockHistories, setKitStockHistories] = useState([]);
  const [kitStockHistoriesLoaded, setKitStockHistoriesLoaded] = useState(false);

  useEffect(() => {
    // async function getKitStockRemaining() {
    //   const response = await Api.kit.stock.remaining.get();
    //   const item = response.data;
    //   setKitStockRemaining(item);
    // }
    async function getAllKitStockHistories() {
      const response = await Api.kit.stock.histories.all();
      const items = response.data;
      const remaining = response.total;
      setKitStockHistories(items);
      setKitStockRemaining(remaining);
      setKitStockHistoriesLoaded(true);
    }

    // getKitStockRemaining();
    getAllKitStockHistories();
  }, []);

  const onStockEditButtonClicked = (kitStockHistoryItem) => {
    navigate(Path.kit.stock.edit(kitStockHistoryItem.id, 0));
  };

  return (
    <DefaultLayout
      headerProps={{
        headerClassName: "md:!block",
        pageTitle: "キットの在庫確認",
      }}
    >
      <Text.Thick useParag={true}>
        現在のキット数
        <Text.Normal className="pl-2">{kitStockRemaining}</Text.Normal>
      </Text.Thick>
      <Kit.Stock.Table.Table
        items={kitStockHistories}
        loading={!kitStockHistoriesLoaded}
      >
        <Kit.Stock.Table.Loading>
          <tbody>
            <tr>
              <td colSpan="100">ローディング中</td>
            </tr>
          </tbody>
        </Kit.Stock.Table.Loading>
        <Kit.Stock.Table.Empty>
          キットの在庫履歴がないです。
        </Kit.Stock.Table.Empty>
        <Kit.Stock.Table.Header>
          <tr>
            <th scope="col" className="px-6 py-4">
              在庫登録日
            </th>
            <th scope="col" className="px-6 py-4">
              数量
            </th>
            <th scope="col" className="px-6 py-4">
              備考
            </th>
            <th scope="col" className="px-6 py-4">
              修正
            </th>
          </tr>
        </Kit.Stock.Table.Header>
        <Kit.Stock.Table.Items>
          {kitStockHistories.map((item) => (
            <Kit.Stock.Table.Item
              key={item.id}
              onStockEditButtonClick={onStockEditButtonClicked}
            >
              {item}
            </Kit.Stock.Table.Item>
          ))}
        </Kit.Stock.Table.Items>
      </Kit.Stock.Table.Table>
      <BackBtn url={Path.kit.home} />
    </DefaultLayout>
  );
}

export default KitStockListPage;
