import DateTimeLabel from "./DateTimeLabel";
import PriceLabel from "./PriceLabel";
import DurationLabel from "./DurationLabel";

const exportObjs = {
  DateTime: DateTimeLabel,
  Price: PriceLabel,
  Duration: DurationLabel,
};

export default exportObjs;
