import React from "react";
import { useNavigate } from "react-router-dom";
import MainMenuItem from "app/components/MainMenuItem";
import Path from "app/route/Path";

function OutsourcingListMenuItem(props) {
  const navigate = useNavigate();

  const onMenuItemClicked = () => {
    navigate(Path.contractor.list("outsourcing"));
  };

  return (
    <MainMenuItem
      itemTitleString="委託先情報を見る"
      onClick={onMenuItemClicked}
      size="100%"
    />
  );
}

export default OutsourcingListMenuItem;
